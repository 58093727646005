/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM938P
 * 화면 설명: 보험계약기본사항 > 계약정보 > 유지/유의정보 > 조건부계약 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.17
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="조건부계약" :topButton="true"><!-- type-popup -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="pa12024">
        <p class="fs19rem fwb">
          <!-- 상품명 (계약번호) -->
          {{ prdtNm }} ({{ markUpContNo }})
        </p>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="mb20">
        <div class="fexTy4 w100 pdg-right-5">
          <label>전체보기</label>
          <mo-switch v-model="isToggle" class="ns-switch ml10" small labeld />
        </div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area expand_topline">
        <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
          <mo-list :list-data="cndtlcontVO">
            <template #list-item="{item}">
              <mo-list-item  expandable prevent-expand>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <!-- 보험명 -->
                    <span class="name txtSkip fs19rem">{{ item.insrNm }}</span>
                  </div>
                  <div class="list-item__contents__info pr0">
                    <div class="info_box mt10">
                      <div class="full">
                        <span class="label-title mr10">구분</span>
                        <span>{{ item.insrScCdNm }}</span>
                        <em></em>
                        <span class="label-title mr10">계약유형</span>
                        <span>{{ item.rltnTypNm }}</span>
                      </div>
                      <div class="full">
                        <span class="label-title mr10">계약일</span>
                        <span>{{ item.contYmd }}</span>
                        <em></em>
                        <!-- 개시일(=책임개시일) -->
                        <span class="label-title mr10">개시일</span>
                        <span>{{ item.rspnBgnYmd }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row fex-rw">
                    <span class="dsInline fs14rem min120">고객관계유형</span><span class="ml20 crTy-bk1">{{ item.custRltnTypCdNm }}</span>
                  </div>
                  <div class="contents-row fex-rw">
                    <span class="dsInline fs14rem min120">유형값</span><span class="ml20 crTy-bk1">{{ item.cndtlContTypVal }}</span>
                  </div>
                  <div class="contents-row fex-rw">
                    <!-- 유효시작일/종료일(= 적용일자, 유효일자) -->
                    <span class="dsInline fs14rem min120">유효시작일/종료일</span><span class="ml20 crTy-bk1">{{ item.aplcYmd }}/{{ item.avlYmd }}</span>
                  </div>
                  <div class="contents-row fex-rw">
                    <span class="dsInline fs14rem min120 word_keep-all lh--12 mt4">부위부담보 (할증보험료)</span><span class="ml20 crTy-bk1 word_keep-all lh--12 mt4">{{ item.nosecRgnCd }} {{ item.nosecRgnNm }}</span>
                  </div>
                </div>
              </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
         <!-- NoData 영역 start -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_005" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 end -->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM938P', 
  screenId: 'MSPPM938P', 
  components: {},//componets,

  props:{
    pPage: String,          // 부모 페이지명
    pContNo: String,        // 계약번호 
    pMarkUpContNo: String,  // 마스킹된 계약번호
    pPrdtNm: String,        // 상품명
    pContrNm: String,       // 계약자명 
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {
      lv_InputParam: {},              // 서비스호출 파라미터 

      lv_ReturnVal: {},               // 리턴값
      isReturn: false,                // 리턴값 세팅여부

      /**
       * props 변수 
       */
      contNo: '',                     // 계약번호
      markUpContNo: '',               // 마스킹된 계약번호
      prdtNm: '',                     // 상품명
      contrNm: '',                    // 계약자명 
      contRrn: '',                    // 계약자 주민번호 

      /**
       * 조회 변수
       */ 
      cndtlcontVO: [],                // 조건부계약 데이터

      isToggle: false,
      isNoData: false,  

    }
  },//data

  created () {

    this.contNo       = this.pContNo
    this.markUpContNo = this.pMarkUpContNo
    this.prdtNm       = this.pPrdtNm
    this.contrNm      = this.pContrNm
    this.contRrn      = this.pContRrn

    // 서비스 호출 
    this.fn_GetService()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {
    isToggle() {
      this.fn_GetService();
    }
  },//watch

  computed: {},//computed

  methods: {
    /**
     * @description 조건부계약 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {
      const auth = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = {
        contNo: this.contNo,
        inqrScVal: this.isToggle ? '' : '2' // 조회구분값 전체: '', 정상: '2'(default)
      }
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPC0086'),
        fahrTrnId: 'S1',
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0086VO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0086VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
            
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {
        this.isNoData = true
        if (!response.msgComm || response.msgComm.msgCd !== 'EFWS003') {
          let msgcomm = response.msgComm
          let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
          this.getStore('confirm').dispatch('ADD', message)
        }

        return
      }

      this.cndtlcontVO = [];
      let responseList = response.body.mccndtlInsrMatrVO
      if (responseList.length) this.isNoData = false

      responseList.forEach((item) => {

        // 데이터 가공
        item.contYmd    = this.$commonUtil.dateDashFormat(item.contYmd)        
        item.rspnBgnYmd = this.$commonUtil.dateDashFormat(item.rspnBgnYmd)     
        item.aplcYmd    = this.$commonUtil.dateDashFormat(item.aplcYmd)        
        item.avlYmd     = this.$commonUtil.dateDashFormat(item.avlYmd)        

        // 데이터 저장 
        this.cndtlcontVO.push(item)
        
      })

    }
 },// methods

}//export default


</script>