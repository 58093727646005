/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM951D
 * 화면 설명: 보험계약기본사항 > 미래예시 > 해약환급금
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.27
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container v-if="isItcoNt && !isNoData" direction="column" alignV="start" class="msp-pm-wrap">
      <!-- 검색결과 영역 -->
      <ur-box-container  v-if="isCondition" alignV="start"  direction="row" class="search-result-box bd-b-Ty1 expand_topline fex-rw flex_nowrap fexTy5"> 
        <span class="mr12 fwb mb4 flex-0">예시기준</span>
        <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab flex_o overflow-auto">
            <mo-tab-label @click="fn_OpenMSPPM952P" > <span class="txt" >{{ irtTxt }}</span> </mo-tab-label>
            <mo-tab-label v-if="isExistedAddmCon" @click="fn_OpenMSPPM952P" > <span class="txt" >추가납입설계</span> </mo-tab-label>
            <mo-tab-label v-if="isExistedMidCon"  @click="fn_OpenMSPPM952P" > <span class="txt" >중도인출설계</span> </mo-tab-label>
        </mo-tab-box>
        <mo-icon icon="msp-arrow-pre" class="deg--180 mb4 ml10 mr24 flex-0" @click="fn_OpenMSPPM952P"></mo-icon>
      </ur-box-container>
      <!-- 데이터개수 -->
      
      <!-- radio 버튼 영역 -->
      <ur-box-container class="area-bb flex-sc">
        <div class="txt-sum">총 <strong>{{ totalCount }}</strong> 건</div>
        <div class="flex-col-se">
          <mo-button v-if="chkCond.icamtFg === 'Y'" class="ns-btn-round blue-line sm" @click="fn_OpenMSPPM939P">보장금액증액</mo-button>
          <div class="wrap-btn">
            <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
              <mo-radio value="1" small>목록</mo-radio>
              <mo-radio value="2" small>표</mo-radio>
            </mo-radio-wrapper>
            <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open"> 크게보기</mo-button>
          </div>
        </div>
      </ur-box-container>
      
      <!-- content start -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area pb-0">

        <!-- 표 모드 -->
        <ur-box-container v-if="radioButton==='2'" class="sticky-pd">
          <div class="sticky-area">
            <div class="table-area sticky-t01" ref="tableArea" @scroll="fn_checkScroll(1)">
              <table class="table row-type ws_N">
                <thead>
                  <tr>
                    <th>경과시간</th>
                    <th>연령</th>
                    <th>기납입보험료</th>
                    <th>총해약환급금</th>
                    <th>환급율(%)</th>
                    <th v-if="chkCond.prsvAcumCd !== '1' && isDthUnIlst === 'N'">이율(%)</th>
                    <th>주보험</th>
                    <th>특약</th>
                    <th>중도인출</th> 
                    <th>분할보험금</th>
                    <th>가산보험금</th>
                    <th>사망보험금</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in srdvlVO" :key="i">
                    <td > {{item.psgYrTt}} </td>
                    <td > {{item.age}} </td>
                    <td class="aR"> {{item.alpadPrm}} </td>
                    <td class="aR"> {{item.totSrdvl}} </td>
                    <td class="aR"> {{item.rfdr}}% </td>
                    <td class="aR" v-if="chkCond.prsvAcumCd !== '1' && isDthUnIlst === 'N'"> {{item.aplcIrt}}% </td>
                    <td class="aR"> {{item.mninsSrdvl}} </td>
                    <td class="aR"> {{item.trtySrdvl}} </td>
                    <td class="aR"> {{item.mdrotAmt}} </td>
                    <td class="aR"> {{item.prtnInsm}} </td>
                    <td class="aR"> {{item.addInsm}} </td>
                    <td class="aR"> {{item.dthInsm}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
            <button class="icon-down2 pos-fixed" @click="fn_scrollToDown(1)" ref="iconDown" v-show="isShowChevronDown"><i></i></button>
          </div>
        </ur-box-container>

        <!-- 목록 모드 -->
        <ur-box-container v-if="radioButton==='1'" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
          <mo-list :list-data="srdvlVO">
            <template #list-item="{ item }">
              <mo-list-item  expandable prevent-expand>
                <div class="list-item__contents">
                  <div class="list-item__contents__info pr0">
                    <div class="info_box mt10">
                      <div class="full">
                        <!-- 경과년도 -->
                        <span class="label-title mr10">경과년도</span>
                        <span>{{ item.psgYrTt }}</span>
                        <em class="align-self-center"></em>
                        <!-- 연령 -->
                        <span class="label-title mr10">연령</span>
                        <span>{{ item.age }}</span>
                      </div>
                      <div class="full">
                        <!-- 기납입보험료 -->
                        <span class="label-title mr10">보험료</span>
                        <span>{{ item.alpadPrm }}</span>
                      </div>
                      <div class="full">
                        <!-- 총해지환급금 -->
                        <span class="label-title mr10">총환급금</span>
                        <span>{{ item.totSrdvl }}</span>
                        <em></em>
                        <span class="label-title mr10">환급률</span>
                        <span>{{ item.rfdr }}%</span>
                      </div>
                    </div>
                  </div>
                </div>

              <!-- 아코디언 컨텐츠 -->
              <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row fexTy3" v-if="chkCond.prsvAcumCd !== '1' && isDthUnIlst === 'N'">
                    <span class="fs14rem crTy-bk7">이율</span>
                    <span class="ml20 crTy-bk1">{{ item.aplcIrt }}%</span>
                  </div>
                  <div class="contents-row fexTy3">
                    <span class="fs14rem crTy-bk7">주보험</span>
                    <span class="ml20 crTy-bk1">{{ item.mninsSrdvl }}</span>
                  </div>
                  <div class="contents-row fexTy3">
                    <span class="fs14rem crTy-bk7">특약</span>
                    <span class="ml20 crTy-bk1">{{ item.trtySrdvl }}</span>
                  </div>
                  <div class="contents-row fexTy3">
                    <span class="fs14rem crTy-bk7">중도인출</span>
                    <span class="ml20 crTy-bk1">{{ item.mdrotAmt }}</span>
                  </div>
                  <div class="contents-row fexTy3">
                    <span class="fs14rem crTy-bk7">분할보험료</span>
                    <span class="ml20 crTy-bk1">{{ item.prtnInsm }}</span>
                  </div>
                  <div v-if="(chkCond.prsvAcumCd === '1' && isDthUnIlst === 'N') || (chkCond.prsvAcumCd !== '1' && isDthUnIlst === 'N')" class="contents-row fexTy3">
                    <span class="fs14rem crTy-bk7">가산보험금</span>
                    <span class="ml20 crTy-bk1">{{ item.addInsm }}</span>
                  </div>
                  <div class="contents-row fexTy3">
                    <span class="fs14rem crTy-bk7">사망보험금</span>
                    <span class="ml20 crTy-bk1">{{ item.dthInsm }}</span>
                  </div>
                </div>
              </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
       <!-- content end -->
    </ur-box-container>

    <!-- 데이터 없음 -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>

    <!-- BottomSheet start -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet maxh-200 closebtn" style="z-index:10; position: fixed;">
      <template v-slot:title>해약환급금 유의사항</template>
      <div class="ns-btn-close" @click="fn_CloseBottomSheet"></div>
      <!-- content 영역 -->
      <ur-box-container direction="column" align-v="start" class="ns-info-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <ur-box-container alignV="start" componentid="" direction="column" >
            <ul class="terms-list-area--gray ty--2">
              <li class="crTy-blue3 blue--dot">
                <span>
                  본 화면은 고객 문의 시 참고용으로 개발되었으므로, 본 자료를 인쇄하거나 지급의 근거로 사용할 수 없습니다.
                </span>
              </li>
              <li class="crTy-bk1">
                <span>
                  갱신특약이 포함된 계약은 미갱신을 가정하였으며, 실효계약은 연체이자를 포함하지 않은 당일 입금기준으로 예시하였습니다.
                </span>
              </li>
              <li class="crTy-bk1 ls--15">
                <span>
                  납입보험료는 실제 고객이 납입할 보험료를 기준으로 산정하며, 납입면제로 인한 회사의 대납분은 포함하지 않습니다. 단, 해약환급금은 회사의 대납분을 포함하여 환급률이 정상건보다 크게 나올 수 있습니다.  
          (환급률 = (총 해약환급금 + 분할보험금 + 중도인출금) / 납입보험료)
                </span>
              </li>
              <li v-if="chkCond.dvdProdFg === 'Y'" class="crTy-bk1">
                <span>
                  상기 예시된 해지환급금은 배당 및 세금을 반영하지 않은 금액으로, 실제수령액은 예시금액과 다를 수 있습니다.
                </span>
              </li>
              <li class="crTy-bk1">
                <span>
                  연금전환 예시 확인 시 연금전환의 가능시기 및 조건은 상품별로 상이하여 화면상 반영되어 있지 않으므로, 각 상품별 예규를 확인하시기 바랍니다. (연금상품의 연금액 예시는 미래연금예시 화면을 이용하여 주십시오.)
                </span>
              </li>
              <li v-if="isDthUnIlst === 'Y'" class="crTy-bk1">
                <span>
                  리빙케어 발생 후 사망보험금은 예시하지 않으니 보장내역을 참조하여 주시기 바랍니다.
                </span>
              </li>
            </ul>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
      <template v-slot:action>
        <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_CloseBottomSheet" class="ns-btn-round blue ">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 크게보기 모드 -->
    <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode" >
      <mo-top-bar>
        <mo-button class="btn_popup_back"></mo-button>
        <div slot="nav" class="icon-wrapper" @click="fn_close">
          <mo-icon color="white" borderless>drawer</mo-icon>
        </div>
        <div class="ui-gnb-title__main">해약환급금</div>
      </mo-top-bar>
      <!-- content 영역 -->
      <div class="content-area">
        <ur-box-container alignV="start" direction="column" class="ns-list-area">
          <ur-box-container>
            <div class="sticky-area">
              <div class="table-area sticky-t01" ref="tableAreaPop" @scroll="fn_checkScroll(2)">
                <table class="table row-type ws_N">
                  <thead>
                    <tr>
                      <th>경과시간</th>
                      <th>연령</th>
                      <th>기납입보험료</th>
                      <th>총해약환급금</th>
                      <th>환급율(%)</th>
                      <th v-if="chkCond.prsvAcumCd !== '1' && isDthUnIlst === 'N'">이율(%)</th>
                      <th>주보험</th>
                      <th>특약</th>
                      <th>중도인출</th>
                      <th>분할보험금</th>
                      <th>가산보험금</th>
                      <th>사망보험금</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in srdvlVO" :key="i">
                      <td > {{item.psgYrTt}} </td>
                      <td > {{item.age}} </td>
                      <td class="aR"> {{item.alpadPrm}} </td>
                      <td class="aR"> {{item.totSrdvl}} </td>
                      <td class="aR"> {{item.rfdr}}% </td>
                      <td class="aR" v-if="chkCond.prsvAcumCd !== '1' && isDthUnIlst === 'N'"> {{item.aplcIrt}}% </td>
                      <td class="aR"> {{item.mninsSrdvl}} </td>
                      <td class="aR"> {{item.trtySrdvl}} </td>
                      <td class="aR"> {{item.mdrotAmt}} </td>
                      <td class="aR"> {{item.prtnInsm}} </td>
                      <td class="aR"> {{item.addInsm}} </td>
                      <td class="aR"> {{item.dthInsm}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRightPop" v-if="isShowChevron2"><i></i></button>
              <button class="icon-down" @click="fn_scrollToDown(2)" ref="iconDownPop" v-if="isShowChevronDown2"><i></i></button>
            </div>
          </ur-box-container>
        </ur-box-container>
      </div>
    </mo-bottom-sheet>
    <!-- BottomSheet end -->

    <div class="waterMark">{{waterMark}}</div>

  </ur-page-container>
</template>

<script>

import pmConstants from '@/config/constants/pmConstants' 
import MSPPM952P   from '@/ui/pm/MSPPM952P.vue'           // 해약환급금 상세조회 
import MSPPM939P   from '@/ui/pm/MSPPM939P.vue'           // 보장금액증액

export default {

  name: 'MSPPM951D', 
  screenId: 'MSPPM951D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '해약환급금',

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},          // 계약메인데이터(MSPPM931M) 
      contNo: '',                 // 계약번호 

      lv_InputParam: {},         // 서비스호출 파라미터
 
      /**
       * 화면 변수
       */ 
      isFirst: true,             // 최초접근여부
      isNoData: false,          
      isItcoNt: false,           // 사내망 여부 
      isCondition: false,        // 해약환급금 상세조회 노출 여부 
      isDthUnIlst: 'N',          // 사망보험 미예시여부 : 유의사항 특정문구 노출 여부 
      waterMark: '',             
      radioButton: '2',          // 라디오 버튼 선택 값
      isShowChevron: false,      // affordance 가로 버튼 노출여부
      isShowChevronDown: false,  // affordance 세로 버튼 노출여부
      isShowChevron2: false,     // 크게보기 affordance 버튼 노출여부
      isShowChevronDown2: false, // 크게보기 affordance 버튼 노출여부
      /**
       * 조회 변수
       */ 
      srdvlVO: [],               // 해약환급금 데이터 
      next:{                    
        nextCnt: 20,             // next건수 
        nextKey: '',             // next키값
        nextYn: ''               // next처리여부 
      },
      totalCount: 0,             // 해약환급금 총 개수 
      firstChevron: true,

      /**
       * 상세조회 조건 데이터 (기준이율, 예시이율, 추가납입설계, 중도인출설계)
       */
      chkCond: {},
      isExistedAddmCon: false,   // 추가납입설계 상세조회여부
      isExistedMidCon: false,    // 중도인출설계 상세조회여부
      irtTxt: '',                // 이율 텍스트 값 

      irtTypA: pmConstants.SRDVL_IRT_TYP_A_CD[0].value,   // 이율(Type A) 선택값
      irtTypB: pmConstants.SRDVL_IRT_TYP_B_CD[0].value,   // 이율(Type B) 선택값
      directIrt: '',                                      // 직접입력 이율 입력값

      /***** 화면정의서에 존재하지 않고, AS-IS에는 존재하여 일단 추가했음. *****/
      // prtnInsm: pmConstants.SRDVL_IRT_PRTN_INSM_CD[0].value,     // 분할보험금 선택값 
      /*********************************************************************/

      // 추가납입설계 조건 데이터 
      addmPadPlanCond: {
        premiumAm: '',          // 보험료 
        prcPrdemiumAm: '',      // 가공된 보험료 (천단위 콤마)
        strYm: ['',''],         // 시작년월
        endYm: ['',''],         // 종료년월 
        deleted: false 
      },
      // 중도인출설계 조건 데이터 
      midDrotPlanCond: {
        mdrotAmt: '',           // 중도인출금
        prcMdrotAmt: '',        // 가공된 중도인출금 (천단위 콤마)
        ogtYm: ['',''],         // 발생년월
        deleted: false
      },

      addmPadPlanForm: [],      // 추가납입설계 데이터 리스트
      midDrotPlanForm: [],      // 중도인출설계 데이터 리스트 

      /**
       * 제어 변수 
       */
      isSearched: false,        // 조회이력 저장 변수 
      isInit: true,
      

      /** 
       * 팝업 연동
       */
      popup952: {},             // 해약환급금 상세조회 
      popup939: {},

      loadingFlag: false
      
    }
  },//data

  created () {      
    // 하단 GNB 영역 안나오게
    this.$BottomManager.fn_SetBottomVisible(false)
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {
    
  },//watch

  computed: {},//computed

  methods: {    
    /**
    * @description 사내망 여부를 확인한다.
    * @notice      외부망에서도 해당 서비스 조회할 수 있도록 변경하고, 워터마크를 추가하였다.
    */
    fn_CheckNetework(){
      let result = false
      // 사내망일 경우 
      // if (this.$MenuManager.fn_GetInternalNetwork()) {
      //   result = true
      //   this.isItcoNt = true
      // }
      // // 사내망 아닐 경우 
      // else{
      //   result = false 
      //   this.isItcoNt   = false
      //   this.serviceMsg = this.$t('pm').EPMC020 // 사내망인 경우에만 조회가능합니다.
      //   this.getStore('confirm').dispatch('SETCOLOR', 'purple')
      //   this.getStore('confirm').dispatch('ADD', this.serviceMsg)
      // }

      // 사외망 연결 가능하도록 변경 
      this.isItcoNt = true 
      return true
    },
    
   /**
    * @description 초기 조회조건으로 초기화 한다.
    */
    fn_ClearCond(){
      
      this.isSearched = false 

      this.chkCond          = {}
      this.isExistedAddmCon = false
      this.isExistedMidCon  = false
      this.irtTypA          = pmConstants.SRDVL_IRT_TYP_A_CD[0].value
      this.irtTypB          = pmConstants.SRDVL_IRT_TYP_B_CD[0].value
      this.directIrt        = ''

      this.addmPadPlanCond = {premiumAm: '', prcPrdemiumAm: '', strYm: ['',''], endYm: ['',''], deleted: false}
      this.midDrotPlanCond = {mdrotAmt: '', prcMdrotAmt: '', ogtYm: ['',''], deleted: false},

      this.addmPadPlanForm.splice(0)
      this.midDrotPlanForm.splice(0)   

    },
    
   /**
    * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
    *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
    */
    fn_BackToPrevious(){

      if(this.isSearched){
        this.fn_ClearCond()
        this.fn_Submit()
      }else{
        this.$router.go(-1)
      }

    },
          
   /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     */
    fn_Submit(isInit){
      // this.radioButton = '1'
      if(!this.fn_CheckNetework()) return 


      // 조회 시, affordance 버튼 미출력
      this.isShowChevron = false   
      this.isShowChevronDown = false
      
      // 초기화 
      this.waterMark   = "고객문의 단순 참고화면  " + this.getStore('pmStore').getters.getState.contInfo.cnsltNo
      this.isNoData    = false         
      this.isCondition = false        
      this.isDthUnIlst = 'N'       
      this.totalCount  = 0
      this.next        = {nextCnt: 20, nextKey: '', nextYn: '' }
      this.serviceMsg  = ''

      this.isInit = isInit

      if(this.isInit) this.fn_ClearCond()

      this.srdvlVO.splice(0)

      // 데이터 세팅
      this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo // MSPPM931M 보험계약기본사항 데이터 
      this.contNo        = this.contBasMatrVO.contNo                          // 계약번호 

      this.lv_InputParam = {}

      // 추가납입설계 상세조건 설정 
      let tFpoiq070 = []

      if (this.chkCond.addPadPsbFg === 'Y') { // 추가납가능여부
        
        if(this.addmPadPlanCond.premiumAm !== ''){
          this.addmPadPlanForm.forEach((item) => {

            let tempObj = {}
            if(item.deleted === false && item.premiumAm !== ''){

              // 데이터 가공
              let tempStrYm = item.strYm[0] + item.strYm[1] 
              let tempEndYm = item.endYm[0] + item.endYm[1] 

              // 1월 ~ 9월까지는 앞에 '0' 붙여주기 
              if(Number(item.strYm[1]) < 10) tempStrYm = item.strYm[0] + '0' + item.strYm[1] 
              if(Number(item.endYm[1]) < 10) tempEndYm = item.endYm[0] + '0' + item.endYm[1]

              tempObj.premiumAm = item.premiumAm  // 보험료
              tempObj.strYm     = tempStrYm       // 시작연월
              tempObj.endYm     = tempEndYm       // 종료연월

              // 데이터 저장
              tFpoiq070.push(tempObj)

              // 초기화
              tempObj = {}

            }
          })

        }
        
        if(tFpoiq070.length > 0){

          // 초기화
          if(!this.lv_InputParam.chkCond) this.lv_InputParam.chkCond = {}

          this.lv_InputParam.chkCond.tFpoiq070 = []
          // 데이터 저장
          this.lv_InputParam.chkCond.tFpoiq070 = tFpoiq070
        }
      }

      // 중도인출설계 상세조건 설정
      let tFpoiq080 = []

      if(this.chkCond.mdrotProdFg === 'Y'){ // 중도인출가능여부
        
        if(this.midDrotPlanCond.mdrotAmt !== ''){
          this.midDrotPlanForm.forEach((item) => {

            let tempObj = {}
            if(item.deleted === false && item.mdrotAmt !== ''){

              // 데이터 가공
              let tempOgtYm = item.ogtYm[0] + item.ogtYm[1]

              // 1월 ~ 9월까지는 앞에 '0' 붙여주기 
              if(Number(item.ogtYm[1]) < 10) tempOgtYm = item.ogtYm[0] + '0' + item.ogtYm[1]

              tempObj.mdrotAmt = item.mdrotAmt  // 보험료
              tempObj.ogtYm    = tempOgtYm      // 발생연월

              // 데이터 저장
              tFpoiq080.push(tempObj)

              // 초기화
              tempObj = {}

            }
          })
          this.isExistedMidCon = true 
        }else{
          this.isExistedMidCon = false
        }
        
        if(tFpoiq080.length > 0){

          // 초기화
          if(!this.lv_InputParam.chkCond) this.lv_InputParam.chkCond = {}

          this.lv_InputParam.chkCond.tFpoiq080 = []
          // 데이터 저장
          this.lv_InputParam.chkCond.tFpoiq080 = tFpoiq080

        }

      }

      // 이율 상세조건 설정 
      // 준비금적립구분코드 : 타입 A (기준이율, 최저보증이율)
      if(this.chkCond.prsvAcumCd === '2' || this.chkCond.prsvAcumCd === '3'){ 

        // 초기화
        if (!this.lv_InputParam.chkCond) this.lv_InputParam.chkCond = {}
        // 데이터 저장
        this.lv_InputParam.chkCond.ilstAplcIrtCd = this.irtTypA // 이율종류 

      }
      
      // 준비금적립구분코드 : 타입 B (예시이율, 0%, 직접입력)
      else if(this.chkCond.prsvAcumCd === '4' || this.chkCond.prsvAcumCd === '5'){ 

        // 초기화
        if (!this.lv_InputParam.chkCond) this.lv_InputParam.chkCond = {}
        // 데이터 저장
        this.lv_InputParam.chkCond.ilstAplcIrtCd = this.irtTypB // 이율종류 
        // 직접입력일 경우
        if(this.lv_InputParam.chkCond.ilstAplcIrtCd === '7') this.lv_InputParam.chkCond.ilstAplcIVl = this.directIrt 

      }

     this.lv_InputParam.contNo = this.contNo   

     // 서비스 호출 
     this.fn_GetService()

    },
   
   /**
     * @description 해약환급금 목록 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      if(this.next.nextYn === 'N') return 

      // 데이터 로딩 때문에 next 값 세팅 필요. 
      this.lv_InputParam.next   = this.next      // next데이터 정보 

      const auth    = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPO0057'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0057VO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0057VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)
      
    },
    
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      let confirm = this.getStore('confirm')

      setTimeout(() => {
        this.loadingFlag = false
      },1000)

      if(response){
        if(response.body){

          // 인터페이스 오류시 confirm 창 띄우고 return
          if (response.body.tmessagesObj){
            if (response.body.tmessagesObj[0].type === 'E') {
              if (response.body.tmessagesObj[0].number === 208) {

                this.isNoData = true
                // 유니버설상품으로 예시불가합니다.
                confirm.dispatch('ADD', this.$t('pm').EPMC061)

                return
              } else {

                this.isNoData = true
                confirm.dispatch('ADD', response.body.tmessagesObj[0].message)
                
                return
              }
            }
          }

          let responseList = response.body.tprojec

          if(responseList){

            this.isItcoNt = true 

            responseList.forEach((item) => {

              // 데이터 가공
              item.psgYrTt    = (isNaN(item.psgYrTt)) ? item.psgYrTt : this.$commonUtil.dateDashFormat(item.psgYrTt) 
              item.age        = Number(item.age)                                                                     
              item.alpadPrm   = this.$commonUtil.numberWithCommas(item.alpadPrm)                                     
              item.aplcIrt    = parseFloat((item.aplcIrt * 100).toFixed(5))                                      
              item.totSrdvl   = this.$commonUtil.numberWithCommas(item.totSrdvl)                                  
              item.mninsSrdvl = this.$commonUtil.numberWithCommas(item.mninsSrdvl)                                
              item.trtySrdvl  = this.$commonUtil.numberWithCommas(item.trtySrdvl)                              
              item.rfdr       = item.rfdr.substr(0, item.rfdr.indexOf('.') + 3)                                    
              item.mdrotAmt   = this.$commonUtil.numberWithCommas(item.mdrotAmt)                                
              item.prtnInsm   = this.$commonUtil.numberWithCommas(item.prtnInsm)                                    
              item.addInsm    = this.$commonUtil.numberWithCommas(item.addInsm)                                    
              item.dthInsm    = this.$commonUtil.numberWithCommas(item.dthInsm)      

              // 해약환급금 데이터 저장 
              this.srdvlVO.push(item)

            })

            // 데이터 저장 
            this.chkCond     = response.body.chkCond
            this.next        = response.body.next
            this.isDthUnIlst = response.body.dthiUnilstFg
            this.totalCount  = this.srdvlVO.length

            // 해약환급금 상세조건 노출여부 
            if(this.chkCond.prsvAcumCd !== '1' || this.chkCond.mdrotProdFg != 'N' || this.chkCond.addPadPsbFg !== 'N' || this.chkCond.prtnIlstScCd !== ' '){
              this.isCondition = true 
            }else{
              this.isCondition = false
            }

            // 검색결과(이율) 문구설정
            this.fn_SetIrtTxt()
            
            // 분할보험금 처리
            if (this.chkCond.prtnIlstScCd === '2') {
              console.log('AS-IS에는 존재하나 확인되는 데이터 없음. 차후에 사용 시 확인 필요')
              confirm.dispatch('ADD', this.$t('pm').EPMC019)
            }

          }else{
            this.isItcoNt    = false
            confirm.dispatch('ADD', response.body.tmessagesObj[0].message)
          }

          // 해지환급금 유의사항 열기 (최초접근일 때만 노출)
          if(this.isFirst) {
            this.$refs.nsbottomsheet.open()
            this.isFirst = false 
          }

        }
      }

      if(this.srdvlVO.length === 0){
        this.isNoData = true 
      }else{
        this.isNoData = false
      }

      if(this.firstChevron) {
        this.$nextTick(() => {
          if(this.srdvlVO.length > 0){
              this.isShowChevronDown = true
              this.isShowChevron = true
          }else{
              this.isShowChevronDown = false
              this.isShowChevron = false
          }
          this.firstChevron = false
        })
      }
    },
    /**
     * @description 검색결과(이율) 텍스트를 설정한다.
     * @param       {String} 
     */
    fn_SetIrtTxt(){

      if(this.isInit){
          if(this.chkCond.prsvAcumCd === '2' || this.chkCond.prsvAcumCd === '3'){ 
          this.irtTxt = '기준이율'
        }
        else if(this.chkCond.prsvAcumCd === '4' || this.chkCond.prsvAcumCd === '5'){ 
          this.irtTxt = '예시이율'
        }
      }else{
        switch(this.lv_InputParam.chkCond.ilstAplcIrtCd){
          case '6':
            this.irtTxt = '기준이율'
            break
          case '5':
            this.irtTxt = '최저보증이율'
            break
          case 'A':
            this.irtTxt = '예시이율'
            break
          case '1':
            this.irtTxt = '0%'
            break
          case '7':
            this.irtTxt = this.directIrt + '%'
            break
          default:
            this.irtTxt = '이율'
            break
        }
      }

    },

   /**
    * @description BottomSheet(=해지환급금 유의사항)를 닫는다.
    */
    fn_CloseBottomSheet(){
      this.$refs.nsbottomsheet.close()
    },
    
    /**
     * @description 데이터를 로딩한다.
     */
    fn_LoadingData(){
      if (this.srdvlVO.length !== 0 && !this.loadingFlag) {
      	console.log("@@@스크롤 조회@@@")
        this.loadingFlag = true
        this.fn_GetService()
      }
    },


    /**
     * @description 해약환급금 상세조회 팝업을 제어한다.
     */
    fn_OpenMSPPM952P(){

      this.popup952 = this.$bottomModal.open(MSPPM952P, {

         properties:{
          pPage: this.$options.screenId,
          pChkCond: this.chkCond,
          pIrtTypA: this.irtTypA,
          pIrtTypB: this.irtTypB,
          pDirectIrt: this.directIrt,
          pPrtnInsm: this.prtnInsm,
          pAddmPadPlanCond: this.addmPadPlanCond,
          pAddmPadPlanForm: this.addmPadPlanForm,
          pMidDrotPlanCond: this.midDrotPlanCond,
          pMidDrotPlanForm: this.midDrotPlanForm
        },

        listeners:{
          searchPopup: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup952)

            this.isSearched      = true
            
            this.irtTypA         = pData.rtnData.irtTypA         
            this.irtTypB         = pData.rtnData.irtTypB         
            this.directIrt       = pData.rtnData.directIrt       
            this.addmPadPlanCond = pData.rtnData.addmPadPlanCond
            this.addmPadPlanForm = pData.rtnData.addmPadPlanForm  
            this.midDrotPlanCond = pData.rtnData.midDrotPlanCond
            this.midDrotPlanForm = pData.rtnData.midDrotPlanForm  

            if(this.addmPadPlanCond.premiumAm != '') {
              this.isExistedAddmCon = true 
            }else{
              this.isExistedAddmCon = false
            }

            if(this.midDrotPlanCond.mdrotAmt != '') {
              this.isExistedMidCon = true 
            }else{
              this.isExistedMidCon = false
            }

            this.fn_Submit()
            
          },
          closePopup: () => {}

        }
      })

    },

    /**
     * @description 보장금액증액 팝업을 제어한다.
     */
    fn_OpenMSPPM939P(){

      this.popup939 = this.$bottomModal.open(MSPPM939P, {
        
        properties:{
          pPage: this.$options.screenId,
          pContNo: this.contNo,
          pMarkUpContNo: this.contBasMatrVO.markUpContNo,
          pPrdtNm: this.contBasMatrVO.prdtNm,
          pContrNm: this.contBasMatrVO.contrNm,
        },

        listeners:{
          closePopup: () => {}
        }
      })

    },

    /**
     * @description 테이블에 있는 affordance버튼을 제어한다.
     */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {        // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
        let scrollHeight = this.$refs.tableAreaPop.scrollHeight - 10
        let scrollTop = this.$refs.tableAreaPop.scrollTop
        let offsetHeight = this.$refs.tableAreaPop.offsetHeight

        if(offsetHeight + scrollTop  > scrollHeight){
          this.fn_LoadingData()
        }
      }
    },

    /**
     * @description 테이블에 있는 affordance버튼을 제어한다.
     */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea
      } else {
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        tableArea = this.$refs.tableAreaPop
      }
      const scrollAmount = 200
      const scrollDuration = 1000
      let startingPosition = tableArea.scrollLeft
      let targetPosition = startingPosition + scrollAmount
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal')
     },

    /**
     * @description 테이블에 있는 affordance버튼을 제어한다.
     */
    fn_scrollToDown(value) {
      if(value === 1) {
        this.isShowChevron = false
        this.isShowChevronDown = false 
        this.$emit('scrollDown')
      } else {
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false 
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop
        const targetPosition = startingPosition + scrollAmount
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical')
      }
    },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },
    
    /**
     * @description 크게보기 모드 열기.
     */ 
    fn_open() { 
      this.$refs.nsbottomsheet2.open()

      this.$nextTick(() => {
        if(this.srdvlVO.length > 0){
          if(this.$refs.tableAreaPop.clientHeight < this.$refs.tableAreaPop.scrollHeight){
            this.isShowChevronDown2 = true
            this.isShowChevron2 = true
          }
          // Y축 스크롤 없으면
          else if(this.$refs.tableAreaPop.clientHeight >= this.$refs.tableAreaPop.scrollHeight){
            this.isShowChevronDown2 = true
            this.isShowChevron2 = true
            if(this.next.nextYn !== 'N') {
              this.fn_LoadingData()
            }
          }
        }else{
            this.isShowChevronDown2 = false
            this.isShowChevron2 = false
        }
      })
    },

    /**
     * @description 크게보기 모드 닫기.
     */    
    fn_close() { this.$refs.nsbottomsheet2.close() },

    
    /**
     * @description 스크롤 이벤트.
     */
    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now()
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime
        const progress = Math.min(elapsedTime / duration, 1)
        const easedProgress = this.easeInOutCubic(progress)
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate)
          
        }
      }
      animate(startTime)
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1
    },
    /**
     * @description tab 이동 시, affordance 버튼 출력 이벤트.
     */
    fn_ShowChervon() {
      this.firstChevron =true
    }
 },// methods
}//export default


</script>