/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM965D
 * 화면 설명: 보험계약기본사항 > 처리이력 >  지급내역    
 *            1) 1차개발 그대로 옮김(퍼블은 2차개발로 변경)
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.09
 * 작 성 자: 서영화
 */
<template>
   <ur-page-container title="지급내역" :show-title="false">
     <ur-box-container direction="column" alignV="start" class="msp">

       <!-- 상단고정 Tab UI -->
       <!-- <div class="ns-sliding-topbar"> -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-area pb0 bgcolor-3">
        <template v-if="!lv_Pym.init" >
          <mo-list  :list-data="lv_Pym.bpcontClbyPymBrkdnListVO" class=" expand_topline">
             <template #list-item="{item}">
              <mo-list-item ref="expItem" class="ns-datail-accodian ty--2 mt0">
                <template class="pal0">
                  <div class="result-contents-box full">
                      <div class="contents-row timeline">
                        <!-- 처리일자 -->
                        <strong class="timeline-data">{{ $commonUtil.dateDashFormat(item.trtYmd) }}</strong>
                          <ul class="mt10">
                            <li>
                              <div class="title-cell"><strong>업무구분</strong></div> 
                              <div class="value-cell"><span>{{ item.pymTrtCdNm }}</span></div>
                            </li> 
                            <li>
                              <div class="title-cell"><strong>보험금(환급금)</strong></div> 
                              <div class="value-cell"><span>{{ $commonUtil.numberWithCommas(item.insmRefnd.amount) }}</span></div>
                            </li> 
                            <li>
                              <div class="title-cell"><strong>지급금액</strong></div> 
                              <div class="value-cell"><span>{{ $commonUtil.numberWithCommas(item.totPymAmt.amount) }}</span></div>
                            </li>
                            <li>
                              <div class="title-cell"><strong>지급해당일</strong></div> 
                              <div class="value-cell"><span>{{ $commonUtil.dateDashFormat(item.pymDlYmd) }}</span></div>
                            </li>
                            <li>
                              <div class="title-cell"><strong>취소구분</strong></div> 
                              <div class="value-cell"><span>{{ item.nrmCancScCdNm }}</span></div>
                            </li>
                          </ul>
                      </div>
                    </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </template>
        <!-- 데이터 없음 -->
        <template v-else>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data"><!-- expand_bottomline -->
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
        </template>
      </ur-box-container>
       <!-- </div> -->
       <!-- 상단고정 Tab UI //-->
     </ur-box-container>
   </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM965D', 
  screenId: 'MSPPM965D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '지급내역',

      contBasMatrVO: {},    // 계약메인데이터(부모데이터) 
      contNo: '',           // 계약번호 
      
      lv_Pym: { // 지급내역
        bind: false,
        init: false,
        bpcontClbyPymBrkdnListVO: []
      },

      crncyCd: ' ', // 외화보험 통화코드 초기값
      isShowUsd: false
    }
  },//data

  created () {},//created

  mounted () {
    return
  },//mounted

  beforeDestroy () {},//beforeDestroy

  watch: {},//watch

  computed: {},//computed

  methods: {
    /**
     * Function명 : fn_PymData
     * 설명       : 지급내역 데이터 조회
     * @return {Promise} - fn_OnCLickAccordion에서 finally를 적용하기 위해
     */
    fn_PymData () {
      if (this.lv_Pym.bind) return Promise.resolve()

      // 초기화 
      this.lv_Pym.bpcontClbyPymBrkdnListVO.splice(0)
      this.lv_Pym = { bind: false, init: false, bpcontClbyPymBrkdnListVO: [] }
      this.crncyCd = ' ' // 외화보험 통화코드 초기값
      this.isShowUsd = false
      // 데이터 세팅 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo         = this.contBasMatrVO.contNo

      // 지급내역
      let inputData = {}
      inputData.cancYn = 'N'
      inputData.contNo = this.contNo // 계약번호
      let trnstId = this.$pmUtil.getTrnstId()
      let auth = 'S'
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FCZ0238'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0001fc.EAF1FCZ0238ResVO',
        reqVONm: 'sli.in.ea2.vo.a0001fc.EAF1FCZ0238ReqVO'
      }
      return this.post(this, inputData, trnstId, auth, commHeader)
        .then(this.fn_PymDataHandler)
    },
    /**
     * Function명 : fn_PymDataHandler
     * 설명       : 지급내역 데이터를 처리하여 화면에 반영
     * @param {Object} response - 지급내역 데이터 객체
     * @return {none} - 반환없음
     */
    fn_PymDataHandler (response) {
      this.lv_Pym.bind = true
      if (response.body) {
        if (response.body.bpcontClbyPymBrkdnListVO !== null) {
          let responseList = response.body.bpcontClbyPymBrkdnListVO
          if (responseList.length > 0) {
            responseList.forEach((item) => {
              this.lv_Pym.bpcontClbyPymBrkdnListVO.push(item)
            })
            this.lv_Pym.bpcontClbyPymBrkdnListVO.sort(function (a, b) {
              if (a.trtYmd === b.trtYmd) {
                return (a.pymDlYmd < b.pymDlYmd) ? 1 : -1
              }
              return (a.trtYmd < b.trtYmd) ? 1 : -1
            })
          } else { this.lv_Pym.init = true }
        } else { this.lv_Pym.init = true }
      } else { this.lv_Pym.init = true }
    },

 },// methods

}//export default


</script>