/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM968D
 * 화면 설명: 보험계약기본사항 > 미래예시 > 갱신보험료
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.16
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="msp-detail-lst pb0 scroll-inner-top">
          <ur-box-container direction="column" align-v="start" class="ns-info-area">
            <ul class="terms-list-area--gray mt30">
              <li>
                미래예시 갱신보험료는 갱신연령 기준으로만 계산된 보험료로 실제 갱신 시 보험료와 다를 수 있습니다.
              </li>
            </ul>
          </ur-box-container>

          <!-- 가입자유형선택 -->
          <ur-box-container alignV="start" componentid="" direction="column">
            <span class="fs18rem fwm mb5 mt30">가입자유형선택</span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt8 mb30">
              <mo-segment-wrapper solid primary v-model="entrTyp.value" class="chip-type-wrap chip-ty1">
                <template v-for="(item,index) in entrTyps">
                  <mo-segment-button :value="item.value" @click="fn_SetEntrTypData(item.value)" :key="index">{{ item.text }}</mo-segment-button>
                </template>
              </mo-segment-wrapper>
            </ur-box-container>          
          </ur-box-container>

          <!-- 가입유형 선택 시, 노출('전체' 제외) -->
          <ur-box-container v-show="entrTyp.value !== ' '" alignV="start" componentid="" direction="row" class="align-item-center mb8" >
            <!-- 선택된 가입유형 -->
            <span class="crTy-bk7">{{ entrTyp.text }}</span>
            <!-- 이름 -->
            <span class="fs16rem ml10">{{ partnerNmTt }}</span>
          </ur-box-container>
        </div>

        <!-- radio 버튼 영역 -->
        <ur-box-container class="area-bb flex-sc">
          <div></div>
          <div class="wrap-btn">
            <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
              <mo-radio value="1" small>목록</mo-radio>
              <mo-radio value="2" small>표</mo-radio>
            </mo-radio-wrapper>
            <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open">크게보기</mo-button>
          </div>
        </ur-box-container>
        
        <!-- 표 모드 -->
        <ur-box-container v-if="radioButton==='2'" class="sticky-pd">
          <div class="sticky-area">
            <div class="table-area sticky-t01" ref="tableArea" @scroll="fn_checkScroll(1)">
              <table class="table row-type ws_N">
                <thead>
                  <tr>
                    <th>갱신예정일</th>
                    <th>납입보험료<br /> (갱신+비갱신)</th>
                    <th>갱신보험료</th>
                    <!-- 선택부가보험, 피보험자, 갱신주기, 갱신만기 -->
                    <th class="ellipsis-p130" v-for="(item, i) in renwPrmDtlItemVO" :key="i">
                      <p> {{ item.longText }}</p>
                      <p> {{ item.partnerNmTt }}/{{item.rnwCylVl}}년주기 </p>
                      <p> 만기 {{ item.incinfrdendDt }} </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- 갱신예정일, 납입보험료, 갱신보험료, 보험별갱신보험료 -->
                  <tr v-for="(item, i) in renwPrmVO" :key="i">
                    <td> {{item.intrdcovDt}} </td>
                    <td class="aR"> {{item.totPrmAftRnwl}} </td>
                    <td class="aR"> {{item.ctPrmAftRnwl}} </td>
                    <td class="aR" v-for="(item2, k) in renwPrmDtlItemVO" :key="k"> 
                      {{item['prmAftRnwl'+k]}} 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
            <button class="icon-down2 pos-fixed"  @click="fn_scrollToDown(1)"  ref="iconDown"  v-show="isShowChevronDown"><i></i></button>
          </div>
        </ur-box-container>
        
        <!-- 목록 모드 -->
        <div v-if="radioButton==='1'" class="grid-area pm_ty--1">
          <el-table :data="renwPrmVO">
            <!-- <template slot-scope="scope"> -->
              <el-table-column prop="intrdcovDt" label="갱신예정일" >
              </el-table-column>
              <el-table-column class="word_keep-all" prop="totPrmAftRnwl" label="납입보험료 (갱신+비갱신)">
              </el-table-column>
              <el-table-column  prop="ctPrmAftRnwl" label="갱신보험료">
                <template slot-scope="scope"> 
                  <button @click="fn_OpenRenwPrmDtl(scope.row)"
                          style="text-decoration: underline !important; color: #519ef6; display: inline; padding: 0 !important; background-color: white;">{{ scope.row.ctPrmAftRnwl }}</button>
                </template>
              </el-table-column>
            <!-- </template> -->
          </el-table>
        </div>
      </ur-box-container> 
    </ur-box-container>

    <!-- 갱신보험료상세 -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        갱신보험료상세
        <span class="ns-tool-tip ml10">
          <mo-icon id="location_MSPPM968D" icon="msp-tool-tip" class="mt4"></mo-icon>
          <mo-tooltip target-id="location_MSPPM968D" class="tool-tip-box">
            <p>
              실손의료비특약의 경우 보험금 지급유무에 따라 보험료가 변동됨 (단, 무사고할인제도 적용 보험에 한하여 다음 갱신예정일은 실손무사고 할인기준 적용)
            </p>
            <p>
              납입이 재개되는 특약은 납입재개 시점의 특약 보험료를 붉은색 으로 표기
            </p>
            <p>            
              재가입 특약은 조회시점의 재가입 만기일까지만 예시 (대상특약명 파란색 표기)
            </p>
          </mo-tooltip>                  
        </span>
        
        </template>
      
      <div class="ns-btn-close" @click="fn_CloseRenwPrmDtl"></div>
      <!-- content 영역 -->
      <div class="content-area">
        <ur-box-container alignV="start" componentid="" direction="row" class="align-item-center mb10">
          <span class="fs14rem crTy-bk7">갱신예정일</span>
          <span class="fs16rem ml10">{{ spfRenwPrm.intrdcovDt }}</span>
        </ur-box-container>
        <div class="table-area">
          <table class="table row-type">
          <colgroup>
              <col width="70%" />
              <col width="30%" />
            </colgroup>
            <thead>
              <tr>
                <th class="pt8 pb8">
                  <span class="">선택부가보험</span>
                  <div>
                    <span>피보험자</span><em class="em_normal ml8 mr8"></em>
                    <span>갱신주기</span><em class="em_normal ml8 mr8"></em>
                    <span>갱신만기</span>
                  </div>
                </th>
                <th>갱신보험료</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in spfRenwPrmDtl">
                <tr v-if="item.prmAftRnwl" :key="index" >
                  <td class="pt8 pb8">
                    <!-- 상품명 -->
                    <span :class="item.zzatREntFg ? 'crTy-blue3': '' ">{{ item.longText }}</span>
                    <div>
                      <!-- 가입자명, 갱신주기, 갱신만기 -->
                      <span>{{ item.partnerNmTt }}</span>
                      <em class="em_normal ml8 mr8"></em>
                      <span>{{ item.rnwCylVl }} 년</span>
                      <em class="em_normal ml8 mr8"></em>
                      <span>{{ item.incinfrdendDt }}</span>
                    </div>
                  </td>
                  <!-- 갱신보험료 -->
                  <td :class="item.nxtrnRpadFg ? 'crTy-orange2' : '' ">{{ item.prmAftRnwl }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button @click="fn_CloseRenwPrmDtl" componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 크게보기 모드 -->
    <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
      <mo-top-bar>
        <mo-button class="btn_popup_back"></mo-button>
        <div slot="nav" class="icon-wrapper" @click="fn_close">
          <mo-icon color="white" borderless>drawer</mo-icon>
        </div>
        <div class="ui-gnb-title__main">갱신보험료</div>
      </mo-top-bar>

      <div class="content-area">
        <ur-box-container alignV="start" direction="column" class="ns-list-area">
          <ur-box-container>
            <div class="sticky-area">
              <div class="table-area sticky-t01" ref="tableAreaPop" @scroll="fn_checkScroll(2)">
                <table class="table row-type ws_N">
                  <thead>
                    <tr>
                      <th>갱신예정일</th>
                      <th>납입보험료<br /> (갱신+비갱신)</th>
                      <th>갱신보험료</th>
                      <!-- 선택부가보험, 피보험자, 갱신주기, 갱신만기 -->
                      <th class="ellipsis-p130" v-for="(item, i) in renwPrmDtlItemVO" :key="i">
                        <p> {{ item.longText }}</p>
                        <p> {{ item.partnerNmTt }}/{{item.rnwCylVl}}년주기 </p>
                        <p> 만기 {{ item.incinfrdendDt }} </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- 갱신예정일, 납입보험료, 갱신보험료, 보험별갱신보험료 -->
                    <tr v-for="(item, i) in renwPrmVO" :key="i">
                      <td> {{item.intrdcovDt}} </td>
                      <td class="aR"> {{item.totPrmAftRnwl}} </td>
                      <td class="aR"> {{item.ctPrmAftRnwl}} </td>
                      <td class="aR" v-for="(item2, k) in renwPrmDtlItemVO" :key="k"> 
                        {{item['prmAftRnwl'+k]}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRight" v-show="isShowChevron2"><i></i></button>
              <button class="icon-down"  @click="fn_scrollToDown(2)"  ref="iconDown"  v-show="isShowChevronDown2"><i></i></button>
            </div>
          </ur-box-container>
        </ur-box-container>
      </div>
    </mo-bottom-sheet>
  </ur-page-container>
</template>

<script>
import pmConstants from '~/src/config/constants/pmConstants';

export default {

  name: 'MSPPM968D', 
  screenId: 'MSPPM968D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '갱신보험료',

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},    // 계약메인데이터(부모데이터) 
      contNo: '',           // 계약번호 

      lv_InputParam: {},    // 서비스호출 파라미터

      /**
       * 화면 변수
       */ 
      isNoData: false,   
      radioButton: '2',           // radio 버튼 값
      isShowChevron: false,       //affordance 가로 버튼 노출여부
      isShowChevronDown: false,   //affordance 세로 버튼 노출여부
      isShowChevron2: false,      //크게보기 affordance 버튼 노출여부
      isShowChevronDown2: false,  //크게보기 affordance 버튼 노출여부
      firstChevron: true,         //tab 첫 진입할때만 affordance 노출
      noMoreData: false,

      /**
       * 조회 변수a
       */ 
      // 갱신보험료 데이터(초기화 값이 필요) 
      renwPrmVO: [{intrdcovDt: '', totPrmAftRnwl: '', ctPrmAftRnwl: '', idx:''}], 
      spfRenwPrm:{},                                 // 선택된 갱신보험료 

      renwPrmDtlVO: [],                              // 갱신보험료 상세 데이터 
      spfRenwPrmDtl:[],                              // 선택된 갱신보험료 상세 

      renwPrmDtlItemVO: [],                          // 갱신보험료 상세 항목 데이터 

      entrTyps: pmConstants.RENW_PRM_ENTR_TYP_CD,    // 가입자유형 CODE-LIST 데이터 
      entrTyp: pmConstants.RENW_PRM_ENTR_TYP_CD[0],  // 선택된 가입자유형 데이터 

      partnerNmTt: ''                                // 가입자유형에 따른 파트너 이름 

    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {

   /**
    * @description 선택된 가입자유형 데이터를 세팅한다.
    * @param       {String}} targetVal 선택된 가입자유형 데이터의 value
    */
    fn_SetEntrTypData(targetVal){

      let target   = this.entrTyps.filter((item) => item.value === targetVal)
      this.entrTyp = target[0]
      
      // 조회 시, affordance 버튼 미출력
      this.isShowChevron = false   
      this.isShowChevronDown = false
      // 데이터 조회
      this.fn_Submit()

    },
     /******************************************************************************
      * Function명 : fn_OpenRenwPrmDtl
      * 설명       : 갱신보험료상세 바텀시트 열기 
      * @param {Number} data 선택된 유형 데이터의 object
    ******************************************************************************/

    /**
     * @description 선택된 갱신보험료상세 BottomSheet가 열린다.
     * @param       {Number} data 선택된 갱신보험료상세 index
     */
    fn_OpenRenwPrmDtl(data){

      // let target = this.renwPrmVO.filter((item) => item.idx === data.idx)
      this.spfRenwPrm = data

      this.spfRenwPrmDtl.splice(0)

      // 선택된 값의 상세데이터 추출 
      this.renwPrmDtlVO.forEach((item) => {
        if(data.idx === item.idx)
          this.spfRenwPrmDtl.push(item)
      })

      this.$refs.nsbottomsheet.open()

    },

    /**
     * @description 선택된 갱신보험료상세 BottomSheet가 닫힌다.
     */
    fn_CloseRenwPrmDtl(){
      this.$refs.nsbottomsheet.close()
    },

   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    * @param       {Boolean} param 갱신보험료 추가조회여부
    */
    fn_Submit(param){

      //  데이터 세팅 및 초기화 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo         = this.contBasMatrVO.contNo
      this.partnerNmTt    = ''

      this.lv_InputParam = {}

      this.lv_InputParam.applnrTt     = this.contNo
      this.lv_InputParam.inqrScCd     = '01'
      this.lv_InputParam.contvInsrdCd = this.entrTyp.value.trim()

      if(param){
        this.lv_InputParam.inqrScCd = '02'
        this.lv_InputParam.effctvDt = this.$pmUtil.getCurrentDate()
      }else{
        // 초기화 
        this.isNoData   = false 
        this.renwPrmVO.splice(0)
        this.renwPrmDtlVO.splice(0)
      }

      // 서비스 호출 
      this.fn_GetService(param)

    },
      
    /**
     * @description 갱신보험료 조회 서비스 호출한다.
     * @param       {Boolean} param 갱신보험료 추가조회 여부 
     * @callback    fn_HandleCallback
     */
    fn_GetService(param) {

      const auth    = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPR0002'),
        fahrTrnId: (param) ? 'S2' : 'S1',
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPR0002VO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPR0002VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
       
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

       let confirm = this.getStore('confirm')

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        confirm.dispatch('ADD', message)

        // 데이터 없음
        this.isNoData    = true 
        return
      }

      let svcId                     = response.trnstComm.commHeaderVO.fahrTrnId  // 서비스ID
      let srchFrRnContSumInsrAmtObj = response.body.srchFrRnContSumInsrAmtObj    // 갱신보험료 데이터 
      let srchFrRnContInsrAmtObj    = response.body.srchFrRnContInsrAmtObj       // 갱신보험료 상세 데이터 
      let srchFrRnContDtlObj        = response.body.srchFrRnContDtlObj           // 갱신보험료 상세 항목 데이터 

      // 가입자유형에 따른 파트너 이름 설정
      if(srchFrRnContDtlObj){
        if(!this.$bizUtil.isEmpty(srchFrRnContDtlObj[0].partnerNmTt))
          this.partnerNmTt = srchFrRnContDtlObj[0].partnerNmTt
      }

      if (svcId === 'S1') {

        if (!srchFrRnContDtlObj) {
          this.isNoData   = true
          // 갱신보험 정보가 없습니다.
          confirm.dispatch('ADD', this.$t('pm').EPMC062)
          return
        }

        // 갱신보험료 상세 항목 데이터 저장 
        this.renwPrmDtlItemVO = srchFrRnContDtlObj

        // 갱신보험료 데이터 가공
        srchFrRnContSumInsrAmtObj.forEach((item, index) => {

          let tempObj = {}
          tempObj.intrdcovDt    = '현재'  // 갱신예정일
          tempObj.totPrmAftRnwl = this.$bizUtil.numberWithCommasCurr(item.totPrmAftRnwl, item.currencyId, 'Y', 'Y', 3)   // 납입보험료(갱신+비갱신)
          tempObj.ctPrmAftRnwl  = this.$bizUtil.numberWithCommasCurr(item.ctPrmAftRnwl, item.currencyId, 'Y', 'Y', 3)    // 갱신보험료
          tempObj.idx           = 'S1_' + index

          // 데이터 저장 
          this.renwPrmVO.push(tempObj)

 
          // 갱신보험료 상세 데이터 가공
          srchFrRnContDtlObj.forEach((item2, index2) => {

            let target = srchFrRnContInsrAmtObj[index2]

            tempObj = {}
            tempObj.zzatREntFg    = srchFrRnContDtlObj[index2].zzatREntFg === 'X'                                         // 자동재가입여부
            tempObj.longText      = srchFrRnContDtlObj[index2].longText                                                   // 상품이름
            tempObj.partnerNmTt   = srchFrRnContDtlObj[index2].partnerNmTt                                                // 비즈니스 파트너 이름
            tempObj.rnwCylVl      = srchFrRnContDtlObj[index2].rnwCylVl                                                   // 갱신주기
            tempObj.incinfrdendDt = this.$commonUtil.dateDashFormat(srchFrRnContDtlObj[index2].incinfrdendDt)             // 최종 요청된 조정일
            tempObj.prmAftRnwl    = this.$bizUtil.numberWithCommasCurr(target.prmAftRnwl, target.currencyId, 'Y', 'Y', 3) // 갱신보험료(상세데이터)
            tempObj.nxtrnRpadFg   = target.nxtrnRpadFg === 'X'                                                            // 차회납입재개여부
            tempObj.idx           = 'S1_' + index
            
            // 데이터 저장 
            this.renwPrmDtlVO.push(tempObj)

          })

        })
        this.isNoData = false 

        // 갱신보험료 추가조회
        this.fn_Submit(true)

      }else{
        this.noMoreData = true
        
        // 갱신보험료 데이터 가공
        srchFrRnContSumInsrAmtObj.forEach((item, index) => {

          let size    = srchFrRnContSumInsrAmtObj ? srchFrRnContSumInsrAmtObj.length : 0
          let tempObj = {}
          tempObj.intrdcovDt    = this.$commonUtil.dateDashFormat(item.intrdcovDt)                                     // 갱신예정일
          tempObj.totPrmAftRnwl = this.$bizUtil.numberWithCommasCurr(item.totPrmAftRnwl, item.currencyId, 'Y', 'Y', 3) // 납입보험료(갱신+비갱신)
          tempObj.ctPrmAftRnwl  = this.$bizUtil.numberWithCommasCurr(item.ctPrmAftRnwl, item.currencyId, 'Y', 'Y', 3)  // 갱신보험료
          tempObj.idx           = 'S2_' + index

          // 데이터 저장 
          this.renwPrmVO.push(tempObj)

          // 갱신보험료 상세 데이터 가공
          srchFrRnContDtlObj.forEach((item2, index2) => {

            let target = srchFrRnContInsrAmtObj[index2 * size + index]

            tempObj = {}
            tempObj.zzatREntFg    = srchFrRnContDtlObj[index2].zzatREntFg === 'X'                                                                            // 자동재가입여부
            tempObj.longText      = srchFrRnContDtlObj[index2].longText                                                                                      // 상품이름
            tempObj.partnerNmTt   = srchFrRnContDtlObj[index2].partnerNmTt                                                                                   // 비즈니스 파트너 이름
            tempObj.rnwCylVl      = srchFrRnContDtlObj[index2].rnwCylVl                                                                                      // 갱신주기
            tempObj.incinfrdendDt = this.$commonUtil.dateDashFormat(srchFrRnContDtlObj[index2].incinfrdendDt)                                                // 최종 요청된 조정일
            tempObj.prmAftRnwl    = (target.prmAftRnwl === '0') ? '' : this.$bizUtil.numberWithCommasCurr(target.prmAftRnwl, target.currencyId, 'Y', 'Y', 3) // 갱신보험료(상세데이터)
            tempObj.nxtrnRpadFg   = target.nxtrnRpadFg === 'X'                                                                                               // 차회납입재개여부
            tempObj.idx           = 'S2_' + index
            
            // 데이터 저장 
            this.renwPrmDtlVO.push(tempObj)
          })
          
        })
      }

      // 데이터 조회 종료 후
      if(this.noMoreData) {
        
        // affordance 버튼 제어(Y축 스크롤 생길 경우만 down 버튼 노출)
        if(this.firstChevron) {
          this.$nextTick(() => {
            if(this.renwPrmVO.length > 0){
              this.isShowChevronDown = true
              this.isShowChevron = true
            } else{
              this.isShowChevronDown = false
              this.isShowChevron = false
            }
            this.firstChevron = false
          })
        }

        // 표 모드를 위한 갱신보험료 데이터
        for(let i = 0; i <this.renwPrmVO.length; i++) {
          let key = this.renwPrmVO[i].idx
          let idx = 0
          for(let j = 0; j <this.renwPrmDtlVO.length; j++) { //갱신예정일에 따른 데이터 분류
            if(key === this.renwPrmDtlVO[j].idx){ //index값 비교
              this.renwPrmVO[i]['prmAftRnwl' + idx] = this.renwPrmDtlVO[j].prmAftRnwl
              idx++
            }
          }
        }

        // 표 label에 사용할 만기 데이터 가공
        for(let i = 0; i < this.renwPrmDtlItemVO.length; i++) {
          this.renwPrmDtlItemVO[i].incinfrdendDt = this.$commonUtil.dateDashFormat(this.renwPrmDtlItemVO[i].incinfrdendDt)
        }

        this.noMoreData = false
      }

    },

   /**
    * @description 크게보기 오픈.
    */
    fn_open() { 
      this.$refs.nsbottomsheet2.open()

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.renwPrmVO.length > 0){
          this.isShowChevronDown2 = true
          this.isShowChevron2 = true
        }else{
          this.isShowChevronDown2 = false
          this.isShowChevron2 = false
        }
      })
    },

   /**
    * @description 크게보기 닫기.
    */
    fn_close() { this.$refs.nsbottomsheet2.close() },

   /**
    * @description 스크롤 이벤트.
    */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {        // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
      }
    },

   /**
    * @description Right affordance 버튼 클릭 이벤트.
    */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {                 // 표모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea
      } else {                          // 크게보기
        this.isShowChevronDown2 = false
        this.isShowChevron2 = false 
        tableArea = this.$refs.tableAreaPop
      }
      const scrollAmount = 200
      const scrollDuration = 1000
      let startingPosition = tableArea.scrollLeft
      let targetPosition = startingPosition + scrollAmount
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal')
     },

   /**
    * @description Down affordance 버튼 클릭 이벤트.
    */
    fn_scrollToDown(value) {
      if(value === 1) {                 // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        this.$emit('scrollDown')
      } else {                          // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop
        const targetPosition = startingPosition + scrollAmount
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical')
      }
     },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },

    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now()
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime
        const progress = Math.min(elapsedTime / duration, 1)
        const easedProgress = this.easeInOutCubic(progress)
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate)
          
        }
      }
      animate(startTime)
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1
    },
   /**
     * @description tab 이동 시, affordance 버튼 출력 이벤트.
     */
    fn_ShowChervon() {
      this.firstChevron =true
    }
 },// methods

}//export default


</script>
<style>
.el-table__cell .cell {
  word-break: keep-all !important;
}
</style>