/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM967D
 * 화면 설명: 보험계약기본사항 > 처리이력 >  보험계약대출    
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.21
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container title="보험계약대출" :show-title="false">
    <ur-box-container direction="column" alignV="start" class="msp">

      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-area pb0 bgcolor-3">

        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data"><!-- expand_bottomline -->
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>서비스 준비중입니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>

      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM967D', 
  screenId: 'MSPPM967D', 
  components: {    
  },//componets,

  props:{
  },//props

  data () {
    return {}
  },//data

  created () {},//created

  mounted () {
    return
  },//mounted

  beforeDestroy () {},//beforeDestroy

  watch: {},//watch

  computed: {},//computed

  methods: {
 },// methods

}//export default


</script>