/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM963D
 * 화면 설명: 보험계약기본사항 > 처리이력 >  입금내역
 *           1) 1차개발 그대로 옮김(퍼블은 2차개발로 변경)    
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.21
 * 작 성 자: 서영화
 */
<template>
   <ur-page-container title="입금내역" :show-title="false" :topButton="false">
     <ur-box-container direction="column" alignV="start"  class="msp" >
       <!-- 상단고정 Tab UI -->
       <!-- <div class="ns-sliding-topbar"> -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-area pb0 bgcolor-3 expand_topline">
        <template v-if="!lv_Rctn.init"> 
          <div class="pal2024" >
            <div>
                <span class="dsInline fs17rem fwb w150px">최종납입일자</span>
                <span class="fs17rem fwb">{{ $commonUtil.dateDashFormat(contInfoVO.lstPadYmd) }}</span>
            </div>
            <div class="mt10">
                <span class="dsInline fs17rem fwb w150px">자유납입 신청금액</span>
                <span class="fs17rem fwb">{{ lv_Rctn.frePadAplAmt !== '-' ? $bizUtil.numberWithCommasCurr(lv_Rctn.frePadAplAmt, crncyCd, 'Y', 'Y', 3) : lv_Rctn.frePadAplAmt }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- 데이터 없음 -->
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data"> 
            <!-- expand_bottomline -->
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info">
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
        </template>

        <!-- 입금내역 start -->
          <mo-list  :list-data="lv_Rctn.pmcontClbyRctmBrkdnListVO" class="expand_topline">
            <template #list-item="{item}">
            <mo-list-item ref="expItem" class="ns-datail-accodian ty--2 mt0">
              <template class="pal0">
                <div class="result-contents-box full">
                    <div class="contents-row timeline">
                      <!-- 입금일자 -->
                      <strong class="timeline-data">{{ $commonUtil.dateDashFormat(item.rctmYmd) }}</strong>
                      <ul class="mt10">
                        <li>
                          <div class="title-cell w100"><strong>입금월분</strong></div> 
                          <div class="value-cell"><span>{{ $commonUtil.dateDashFormat(item.rctmYm) }}/{{ item.rctmNts }}회</span></div>
                        </li> 
                        <li>
                          <div class="title-cell w100"><strong>납입구분</strong></div> 
                          <div class="value-cell"><span>{{ item.padScCdNm }}/{{ item.padCylCdNm }}</span></div>
                        </li> 
                        <li v-show="isShowUsd">
                          <div class="title-cell w100"><strong>납입보험료($)</strong></div> 
                          <div class="value-cell"><span>{{ $bizUtil.numberWithCommasCurr(item.realRctmAmtOrg.amount, crncyCd, 'Y', 'Y', 3) }}</span></div>
                        </li> 
                        <li>
                          <div class="title-cell w100"><strong>납입보험료</strong></div> 
                          <div class="value-cell"><span>{{ $bizUtil.numberWithCommasCurr(item.smtotPrm.amount, crncyCd, 'N', 'N') }}</span></div>
                        </li> 
                        <li>
                          <div class="title-cell w100"><strong>실입금액</strong></div> 
                          <div class="value-cell"><span>{{ $commonUtil.numberWithCommas(item.realRctmAmt.amount) }}</span></div>
                        </li> 
                        <li>
                          <div class="title-cell w100"><strong>수금유형</strong></div> 
                          <div class="value-cell"><span>{{ item.rctmTypCdNm }}/{{ item.discTypCdNm }}</span></div>
                        </li> 
                      </ul>
                    </div>
                  </div>
              </template>
            </mo-list-item>
          </template>
        </mo-list>
        <!-- 입금내역 end -->
      </ur-box-container>
       <!-- </div> -->
       <!-- 상단고정 Tab UI //-->
      
      
        
     </ur-box-container>
   </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM963D', 
  screenId: 'MSPPM963D', 
  components: {},//componets,

  props:{
    pMSPPM932DData:{
      type: Object,
      default: {}
    },
  },//props

  data () {
    return {
      title: '입금내역',

      contBasMatrVO: {},    // 계약메인데이터(부모데이터) 
      contInfoVO: {},       // 계약정보 > 계약정보 데이터 
      contNo: '',           // 계약번호 

      lv_Rctn: { // 입금내역
        bind: false,
        init: false,
        frePadAplAmt: '',
        pmcontClbyRctmBrkdnListVO: []
      },

      crncyCd: ' ', // 외화보험 통화코드 초기값
      isShowUsd: false

    }
  },//data

  created () {},//created

  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  watch: {
    pMSPPM932DData(){
      const lv_Vm = this
      lv_Vm.contInfoVO = lv_Vm.pMSPPM932DData
    }
  },//watch

  computed: {},//computed

  methods: {
    /**
     * Function명 : fn_RctnData
     * 설명       : 입금내역 데이터 조회
     * @return {Promise} - fn_OnCLickAccordion에서 finally를 적용하기 위해
     */
    fn_RctnData () {
      if (this.lv_Rctn.bind) return Promise.resolve()

      // 초기화 
      this.lv_Rctn.pmcontClbyRctmBrkdnListVO.splice(0)
      this.lv_Rctn ={ bind: false, init: false, frePadAplAmt: '', pmcontClbyRctmBrkdnListVO: [] }
      this.crncyCd = ' ' // 외화보험 통화코드 초기값
      this.isShowUsd = false
      // 데이터 세팅 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contInfoVO     = this.pMSPPM932DData                                  // MSPPM932D 계약정보 데이터
      this.contNo         = this.contBasMatrVO.contNo

      // 입금내역
      let inputData = {}
      inputData.inqrScCd = '01' // 조회구분코드 01:온라인
      inputData.contNo = this.contNo // 계약번호
      inputData.prscpContYn = 'N' // 시효계약여부

      let trnstId = this.$pmUtil.getTrnstId()
      let auth = 'S'
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPI0024'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: 'sli.co',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPI0024VO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPI0024VO'
      }
      return this.post(this, inputData, trnstId, auth, commHeader)
        .then(this.fn_RctnDataHandler)
    },
    /**
     * Function명 : fn_RctnDataHandler
     * 설명       : 입금내역 데이터를 처리하여 화면에 반영
     * @param {Object} response - 입금내역 데이터 객체
     * @return {none} - 반환없음
     */
    fn_RctnDataHandler (response) {
      if (!response.body) {
        this.emptyVinitRctniew = true
        return
      }
      this.lv_Rctn.bind = true
      if (response.body.pminsrUkepMatrVO !== null) {
        let responseList = response.body.pminsrUkepMatrVO
        let pminsrUkepMatrVO = responseList[0]
        if (pminsrUkepMatrVO.frePadAplYn === 'Y') {
          this.lv_Rctn.frePadAplAmt = pminsrUkepMatrVO.frePadAplAmt.doubleAmount
        } else {
          this.lv_Rctn.frePadAplAmt = '-'
        }
      }
      if ( response.body.pmcontClbyRctmBrkdnListVO !== null) {
        let responseList2 = response.body.pmcontClbyRctmBrkdnListVO
        if (responseList2.length > 0) {
          this.crncyCd = this.$bizUtil.isEmpty(responseList2[0].crncyCd) ? 'KRW' : responseList2[0].crncyCd // 202005 외화보험 통화키
          // let counted = 1
          for (let index = responseList2.length - 1; index >= 0; index--) {
            if (responseList2[index].discTypCdNm.indexOf('선납') !== -1) {
              responseList2[index].discTypCdNm = '선납'
            }
            // if (responseList2[index].padScCdNm.indexOf('자유') === -1) {
            //   responseList2[index].rctmNts = counted
            //   this.totalcounted = counted++
            // } else {
            //   responseList2[index].rctmNts = 0
            // }
            this.lv_Rctn.pmcontClbyRctmBrkdnListVO.push(responseList2[index])
          }
        } else { this.lv_Rctn.init = true }
      } else { this.lv_Rctn.init = true }
      // 외화보험일경우 납입보험료($ 숨김)
      if (this.crncyCd === 'USD') {
        this.isShowUsd = true
      } else {
        this.isShowUsd = false
      }
      console.log(this.lv_Rctn.init);
    },
 },// methods

}//export default


</script>