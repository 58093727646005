]/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM961D
 * 화면 설명: 보험계약기본사항 > 지급확인 > 분할보험금
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.20
 * 작 성 자: 서영화
 */
<template>
 <ur-page-container class="msp" :show-title="false" :topButton="false">
    <!-- 컨텐츠 영역 -->
    <ur-box-container  v-if="!isNoData" direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst mt30 pb0"> 
        <ul class="lst lst--2">
          <li class="fexTy3">
            <span class="tit">분할개시일</span>
            <span class="txt wauto">{{ prtnInsmVO.prtnPymBgnYmd }}</span>
          </li>
           <li class="fexTy3">
            <span class="tit">기지급</span>
            <span class="txt wauto">{{ prtnInsmVO.prcAlPymPrtnInsm }}</span>
          </li>
           <li class="fexTy3">
            <span class="tit">만기일자</span>
            <span class="txt wauto">{{ prtnInsmVO.expYmd }}</span>
          </li>
           <li class="fexTy3">
            <span class="tit">최종처리일자</span>
            <span class="txt wauto">{{ prtnInsmVO.lstPymYmd }}</span>
          </li>
        </ul>        
      </ur-box-container>
      
      <p class="fs18rem mt30 pal1224 fwm">분할보험금 내역</p>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area expand_topline pb-0">
        
        <!-- radio 버튼 영역 -->
        <ur-box-container class="area-bb flex-sc">
          <div class="txt-sum"></div>
          <div class="flex-col-se">
            <div class="wrap-btn">
              <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
                <mo-radio value="1" small>목록</mo-radio>
                <mo-radio value="2" small>표</mo-radio>
              </mo-radio-wrapper>
              <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open">크게보기</mo-button>
            </div>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area pb-0">

          <!-- 표 모드 -->
          <ur-box-container v-if="radioButton==='2'" class="sticky-pd">
            <div class="sticky-area">
              <div class="table-area sticky-t04" ref="tableArea" @scroll="fn_checkScroll(1)">
                <table class="table row-type ws_N">
                  <thead>
                    <tr>
                      <th>발생일자</th>
                      <th>분할급부명</th>
                      <th>선지급가능일</th>
                      <th>분할보험금</th>
                      <th>지연이자</th>
                      <th>선지급할인이자</th>
                      <th>실지급금액</th>
                      <th>지급일자</th>
                      <th>지급여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in prtnInsmVO.bpprtnInsmBasListVO" :key="i">
                      <td> {{item.occuYmd}} </td>
                      <td class="ws_Y aL"> {{item.prtnBenfNm}} </td>
                      <td class="aR"> {{item.prpymPsbYmd}} </td>
                      <td class="aR"> {{item.prcPrtnInsm}} </td>
                      <td class="aR"> {{item.prcDlyInt}} </td>
                      <td class="aR"> {{item.prcPrpymDiscInt}} </td>
                      <td class="aR"> {{item.prcRealPymAmt}} </td>
                      <td class="aR"> {{item.pymYmd}} </td>
                      <td> {{item.pymYn}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
              <button class="icon-down2 pos-fixed" @click="fn_scrollToDown(1)" ref="iconDown" v-show="isShowChevronDown"><i></i></button>
            </div>
          </ur-box-container>

          <!-- 목록 모드 -->
          <ur-box-container v-if="radioButton==='1'" alignV="start" componentid="" direction="column" class="ns-info-list">
            <mo-list :list-data="prtnInsmVO.bpprtnInsmBasListVO">
              <template #list-item="{item}">
                <mo-list-item  expandable prevent-expand>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info pr0">
                      <div class="info_box mt10">
                        <div class="full">
                          <span class="label-title mr8">발생일자</span>
                          <span class="ls--15">{{ item.occuYmd }}</span>
                        </div>
                        <div class="full">
                          <!-- 분할급부명 -->
                          <span class="ls--15">{{ item.prtnBenfNm }}</span>
                          <em></em>
                          <!-- 지급여부 -->
                          <span class="ls--15">{{ item.pymYn }}</span>
                        </div>
                        <div class="full">
                          <span class="label-title mr8">실지급금액</span>
                          <span>{{ item.prcRealPymAmt }}</span>
                        </div>
                        <div class="full">
                          <span class="label-title mr8">지급일자</span>
                          <span>{{ item.pymYmd }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 아코디언 컨텐츠 -->
                  <template v-slot:expand>
                  <div class="list-item-detail">
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">선지급가능일</span>
                      <span class="ml20 crTy-bk1">{{ item.prpymPsbYmd }}</span>
                    </div>
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">분할보험금</span>
                      <span class="ml20 crTy-bk1">{{ item.prcPrtnInsm }}</span>
                    </div>
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">지연이자</span>
                      <span class="ml20 crTy-bk1">{{ item.prcDlyInt }}</span>
                    </div>
                    <div class="contents-row fexTy3">
                      <span class="fs14rem crTy-bk7">선지급할인이자</span>
                      <span class="ml20 crTy-bk1">{{ item.prcPrpymDiscInt }}</span>
                    </div>
                  </div>
                </template>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- 크게보기 모드 -->
      <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
        <mo-top-bar>
          <mo-button class="btn_popup_back"></mo-button>
          <div slot="nav" class="icon-wrapper" @click="fn_close">
            <mo-icon color="white" borderless>drawer</mo-icon>
          </div>
          <div class="ui-gnb-title__main">분할보험금</div>
        </mo-top-bar>
        <!-- content 영역 -->
        <div class="content-area">
          <ur-box-container alignV="start" direction="column" class="ns-list-area">
            <ur-box-container>
              <div class="sticky-area">
                <div class="table-area sticky-t04" ref="tableAreaPop" @scroll="fn_checkScroll(2)">
                  <table class="table row-type ws_N">
                  <thead>
                    <tr>
                      <th>발생일자</th>
                      <th>분할급부명</th>
                      <th>선지급가능일</th>
                      <th>분할보험금</th>
                      <th>지연이자</th>
                      <th>선지급할인이자</th>
                      <th>실지급금액</th>
                      <th>지급일자</th>
                      <th>지급여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in prtnInsmVO.bpprtnInsmBasListVO" :key="i">
                      <td> {{item.occuYmd}} </td>
                      <td class="ws_Y aL"> {{item.prtnBenfNm}} </td>
                      <td class="aR"> {{item.prpymPsbYmd}} </td>
                      <td class="aR"> {{item.prcPrtnInsm}} </td>
                      <td class="aR"> {{item.prcDlyInt}} </td>
                      <td class="aR"> {{item.prcPrpymDiscInt}} </td>
                      <td class="aR"> {{item.prcRealPymAmt}} </td>
                      <td class="aR"> {{item.pymYmd}} </td>
                      <td> {{item.pymYn}} </td>
                    </tr>
                  </tbody>
                </table>
                </div>
                <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRightPop" v-if="isShowChevron2"><i></i></button>
                <button class="icon-down" @click="fn_scrollToDown(2)" ref="iconDownPop" v-if="isShowChevronDown2"><i></i></button>
              </div>
            </ur-box-container>
          </ur-box-container>
        </div>
      </mo-bottom-sheet>
    </ur-box-container>

    <!-- 데이터 없음 -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data"><!-- expand_bottomline -->
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
    <!-- 컨텐츠영역  //-->
 </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM961D', 
  screenId: 'MSPPM961D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '분할보험금',

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},    // 계약메인데이터(부모데이터) 
      contNo: '',           // 계약번호 

      lv_InputParam: {},    // 서비스호출 파라미터

      /**
       * 화면 변수
       */ 
      isNoData: false,      
      radioButton: "2",                 // 라디오버튼 값
      isShowChevron: false,             // 표 모드 affordance 버튼 제어
      isShowChevronDown: false,         // 표 모드 affordance 버튼 제어
      isShowChevron2: false,            // 크게보기 모드 affordance 버튼 제어
      isShowChevronDown2: false,        // 크게보기 모드 affordance 버튼 제어

      /**
       * 조회 변수
       */ 
      prtnInsmVO: {prtnPymBgnYmd: '', prcAlPymPrtnInsm: '', expYmd: '', lstPymYmd: '' , bpprtnInsmBasListVO: []} // 분할보험금 데이터 

    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted


  watch: {},//watch

  computed: {},//computed

  methods: {
        
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
    fn_Submit(){

      // 초기화
      this.prtnInsmVO     = {}

      // 데이터 세팅 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo         = this.contBasMatrVO.contNo

      // 조회조건 세팅  
      this.lv_InputParam.contNo   = this.contNo
      this.lv_InputParam.inqrScCd = '01'

      // 조회 시, affordance 버튼 미출력
      this.isShowChevron = false   
      this.isShowChevronDown = false

      // 서비스 호출 
      this.fn_GetService()

    },
      
    /**
     * @description 분할보험금 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth    = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPO0003'),
        fahrTrnId: 'S1',
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0003VO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0003VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
        
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      let confirm = this.getStore('confirm')

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        confirm.dispatch('ADD', msgcomm.msgDesc)

        // 데이터 없음
        this.isNoData   = true 
        return
      }

      // 데이터 가공 
      let responseVO   = response.body
      let responseList = response.body.bpprtnInsmBasListVO

      responseVO.prtnPymBgnYmd     = this.$commonUtil.dateDashFormat(responseVO.prtnPymBgnYmd) 
      responseVO.prcAlPymPrtnInsm  = (responseVO.alPymPrtnInsm) ? this.$commonUtil.numberWithCommas(responseVO.alPymPrtnInsm.amount) + '원' : '' 
      responseVO.expYmd            = this.$commonUtil.dateDashFormat(responseVO.expYmd)        
      responseVO.lstPymYmd         = this.$commonUtil.dateDashFormat(responseVO.lstPymYmd)    

      // 분할보험금 내역 
      responseList.forEach((item) => {

        item.occuYmd          = this.$commonUtil.dateDashFormat(item.occuYmd)                
        item.prpymPsbYmd      = this.$commonUtil.dateDashFormat(item.prpymPsbYmd)              
        item.prcPrtnInsm      = this.$commonUtil.numberWithCommas(item.prtnInsm.amount)        
        item.prcDlyInt        = this.$commonUtil.numberWithCommas(item.dlyInt.amount)          
        item.prcPrpymDiscInt  = this.$commonUtil.numberWithCommas(item.prpymDiscInt.amount)   
        item.prcRealPymAmt    = this.$commonUtil.numberWithCommas(item.realPymAmt.amount)     
        item.pymYmd           = this.$commonUtil.dateDashFormat(item.pymYmd)                 
        item.pymYn            = (item.pymYn === 'N') ? '미지급' : '지급'  
                             
      })

      // 데이터 저장 
      this.prtnInsmVO = response.body
      
      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.prtnInsmVO.bpprtnInsmBasListVO.length > 0){
          this.isShowChevronDown = true
          this.isShowChevron = true
        } else{
          this.isShowChevronDown = false
          this.isShowChevron = false
        }
      })
    },


   /**
    * @description 크게보기 모드 열기
    */
    fn_open() {
      this.$refs.nsbottomsheet2.open();

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.prtnInsmVO.bpprtnInsmBasListVO.length > 0){
          this.isShowChevronDown2 = true
          this.isShowChevron2 = true
        }else{
          this.isShowChevronDown2 = false
          this.isShowChevron2 = false
        }
      })
    },

   /**
    * @description 크게보기 모드 닫기
    */
    fn_close() {
      this.$refs.nsbottomsheet2.close();
    },

   /**
    * @description 스크롤 이벤트.
    */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {                 // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
      }
    },

   /**
    * @description Right affordance 버튼 이벤트.
    */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea;
      } else {
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        tableArea = this.$refs.tableAreaPop;
      }
      const scrollAmount = 200;
      const scrollDuration = 1000;
      let startingPosition = tableArea.scrollLeft;
      let targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
     },

   /**
    * @description Down affordance 버튼 이벤트.
    */
    fn_scrollToDown(value) {
      if(value === 1) {                 // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        this.$emit('scrollDown')
      } else {                          // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      }
    },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },

    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = this.easeInOutCubic(progress);
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress;
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress;
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
          
        }
      };
      animate(startTime);
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1;
    },

 },// methods

}//export default


</script>