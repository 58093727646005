/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM939P
 * 화면 설명: 보험계약기본사항 > 계약정보 > 유지/유의정보 > 보장금액증액 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.17
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp msp-pm-wrap" type="subpage" :show-title="true" title="보증금액증액" :topButton="true" :action-type="actionSlot"><!-- type-popup -->    
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="pa12024">
        <p class="fs19rem fwb">
          <!-- 상품명 (계약번호) -->
          {{ prdtNm }} ({{ markUpContNo }})
        </p>        
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area expand_topline">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
          <mo-list :list-data="covrAmtIcamtVO">
            <template #list-item="{item}">
              <mo-list-item  expandable prevent-expand>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <!-- 보험명 -->
                    <span class="name txtSkip fs19rem">{{ item.insrNm }}</span>
                  </div>
                  <div class="list-item__contents__info pr0">
                    <div class="info_box mt10">
                      <div class="full">
                        <span class="label-title mr10">보장개시일</span>
                        <span>{{ item.prcAdjStndYmd }}</span>
                        <em></em>
                        <span class="label-title mr10">증액율</span>
                        <span>{{ item.insmIcamtRat }}</span>
                      </div>
                      <div class="full">
                        <span class="label-title mr10">계약금액</span>
                        <span>{{ item.icamtInsm }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row">
                    <span class="dsInline fs14rem crTy-bk7 min80">구분</span><span class="ml20 crTy-bk1">{{ item.trtyScCdNm }}</span>
                  </div>
                  <div class="contents-row">
                    <span class="dsInline fs14rem crTy-bk7 min80">보장구분</span><span class="ml20 crTy-bk1">{{ item.icamtClamRsnCdNm }}</span>
                  </div>
                </div>
              </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <!-- 툴팁 -->
    <ul class="detail-con-li">
      <li class="">
        <span class="fs16rem fwm"></span>
        <span class="ns-tool-tip ml10">
            <mo-tooltip target-id="location_MSPPM939P" class="tool-tip-box">
              <p v-if="detailInfo.text1 != ''">※ {{ detailInfo.text1 }}</p>
              <p v-if="detailInfo.text2 != ''">※ {{ detailInfo.text2 }}</p>
              <p v-if="detailInfo.text3 != ''">※ {{ detailInfo.text3 }}</p>
              <p v-if="detailInfo.text4 != ''">※ {{ detailInfo.text4 }}</p>
              <p v-if="detailInfo.text5 != ''">※ {{ detailInfo.text5 }}</p>
            </mo-tooltip>                  
        </span>  
      </li>
    </ul>
  </ur-page-container>
</template>

<script>

import pmConstants from '@/config/constants/pmConstants'

export default {

  name: 'MSPPM939P', 
  screenId: 'MSPPM939P', 
  components: {},//componets,

  props:{
    pPage: String,          // 부모 페이지명
    pContNo: String,        // 계약번호 
    pMarkUpContNo: String,  // 마스킹된 계약번호
    pPrdtNm: String,        // 상품명
    pContrNm: String,       // 계약자명 
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {
      lv_InputParam: {},              // 서비스호출 파라미터 

      lv_ReturnVal: {},               // 리턴값
      isReturn: false,                // 리턴값 세팅여부

      /**
       *  props 변수 
       */ 
      contNo: '',                     // 계약번호
      markUpContNo: '',               // 마스킹된 계약번호
      prdtNm: '',                     // 상품명
      contrNm: '',                    // 계약자명 
      detailInfo: null,               // 보장금액증액 상세정보(툴팁정보)

      /**
       * 조회 변수
       */  
      covrAmtIcamtVO: [],             // 보장금액증액 데이터

    }
  },//data

  created () {

    // 툴팁 elements
    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper tooltip_ty--top"><mo-icon id="location_MSPPM939P" icon="msp-tool-tip"></mo-icon></div>',
        methods: {
        }
      }
    }

    this.contNo       = this.pContNo
    this.markUpContNo = this.pMarkUpContNo
    this.prdtNm       = this.pPrdtNm
    this.contrNm      = this.pContrNm
    this.detailInfo   = pmConstants.COVRT_AMT_ICAMT_INFO[0]

    this.fn_Submit()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
              
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    * @param       {String} svcId 서비스ID
    */
    fn_Submit(){

      // 조회조건 세팅
      this.lv_InputParam.contNo      = this.contNo
      this.lv_InputParam.inqrScVal   = ''

      // 서비스 호출
      this.fn_GetService() 

    },
            
    /**
     * @description 보장금액증액 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPC0087'),
        fahrTrnId: 'S1',
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0087VO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0087VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)
        
    },
            
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)

        return
      } 
      
      let resultVO     = response.body 
      let responseList = response.body.mccovrAmtIcamtContVO

      if(resultVO){

        responseList.forEach((item) => {

          // 데이터 가공 
          item.prcAdjStndYmd = this.$commonUtil.dateDashFormat(item.adjStndYmd) 
          item.icamtInsm     = item.icamtInsm ? this.$bizUtil.numberWithCommasCurr(item.icamtInsm.amount , item.crncyCd, 'Y', 'Y', 3) : '' 
          item.insmIcamtRat  = item.insmIcamtRat? item.insmIcamtRat + ' %' : '' 

          // 데이터 저장 
          this.covrAmtIcamtVO.push(item)

        })
                       
        let rsnCd    = resultVO.icamtClamRsnCd             // 증액청구사유 코드 
        let stndYm   = this.covrAmtIcamtVO[0].adjStndYmd   // 미청산 기준 일자 
        let info     = pmConstants.COVRT_AMT_ICAMT_INFO    // 보장금액증액 툴팁 정보 object list

        // 데이터에 따른 툴팁정보 설정 
        for(let i = 0; i < info.length; i++){
          let item = info[i]
          if(item.value.includes(rsnCd)){
            if (stndYm === item.dtlYmd) {
              if(item.dtlYmd === '20090828' && stndYm <= '20100416') item.text5 = ''
              this.detailInfo = item
              break
            }else if(item.dtlYmd === ''){
              this.detailInfo = item
            }
          }
        }

      }


    }
 },// methods

}//export default


</script>