/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM932D
 * 화면 설명: 보험계약기본사항 > 계약정보 > 계약정보 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.09
 * 작 성 자: 서영화
 */
<template>
 <!-- <ur-page-container class="msp sales-sys box-shadow__line" :show-title="false" :topButton="false"> -->
  <div class="msp sales-sys box-shadow__line">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap con-area">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst mt30"> 
        
        <div class="full fexTy3 mb10">
          <p class="fs22rem fwb">계약자정보</p>
          <mo-button class="ns-btn-round white sm" @click="fn_OpenMSPPM933P">상세정보</mo-button>
        </div>

        <ul class="lst lst--2">
          <li @click="fn_OpenCustInfoBs">
            <span class="tit">계약자</span>
            <span class="fwb lnkTy1">{{ contBasMatrVO.contrNm }}</span>
          </li>
          <li @click="fn_OpenInsrdCustInfoBs">
            <span class="tit">피보험자</span>
            <span class="fwb lnkTy1">{{ contBasMatrVO.insrdNm }}</span>
          </li>
           <li>
            <span class="tit">계약일자</span>
            <span class="fwb">{{ contInfoVO.contYmd }}</span>
          </li>
           <li>
            <span class="tit">계약기간</span>
            <span class="fwb">{{ contInfoVO.insrPrdNm }}</span>
          </li>
           <li>
            <span class="tit">만기일자</span>
            <span class="fwb">{{ contInfoVO.expYmd }}</span>
          </li>
           <li>
            <span class="tit">제2보험기간</span>
            <span class="fwb">{{ contInfoVO.th2InsrPrdBgnYr }}</span>
          </li>
           <li>
            <span class="tit">모집컨설턴트</span>
            <span class="fwb">{{ contInfoVO.prcClctCnslt }}</span>
          </li>
           <li>
            <span class="tit">위해촉</span>
            <span class="fwb">{{ contInfoVO.rcmsnStatNm }}</span>
          </li>
           <li>
            <span class="tit">모집기관</span>
            <span class="fwb">{{ contInfoVO.prcClctOrg }}</span>
          </li>
        </ul>
        <p class="fs18rem mt30 pt12 pb12 fwm">계약자관계자정보</p>
        <ul class="lst lst--3">
          <li>
            <p class="fs17rem">보험계약자</p>
            <div class="mt10">
              <span class="tit">계 약 자</span>
              <!-- 계약자명, 계약자주민번호 -->
              <span class="txt mr10">{{ contBasMatrVO.contrNm }}</span>
              <span class="txt">({{ contInfoVO.prcContrRrn }})</span>
            </div>
            <div class="mt4">
              <span class="tit">피보험자</span>
              <!-- 피보험자명, 피보험자주민번호 -->
              <span class="txt mr10">{{ contBasMatrVO.insrdNm }}</span>
              <span class="txt">({{ contInfoVO.prcInsrdRrn }})</span>
            </div>
          </li>
          <!-- 종피보험자 1명 이상일 경우, 화면에 노출 -->
          <li v-show="!$bizUtil.isEmpty(contBasMatrVO.aisdNm) || !$bizUtil.isEmpty(contBasMatrVO.chldNm01) || 
                      !$bizUtil.isEmpty(contBasMatrVO.chldNm02) || !$bizUtil.isEmpty(contBasMatrVO.chldNm03)">
            <p class="fs17rem">종피보험자</p>
            <div class="mt10">
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.aisdNm)">
                <span class="tit">종피</span>
                <span class="txt">{{ contBasMatrVO.aisdNm }}</span>
                <em v-show="!$bizUtil.isEmpty(contBasMatrVO.chldNm01)"></em>
              </span>
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.chldNm01)">
                <span class="tit">자녀1</span>
                <span class="txt">{{ contBasMatrVO.chldNm01 }}</span>
              </span>
            </div>
            <div class="mt4">
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.chldNm02)">
                <span class="tit">자녀2</span>
                <span class="txt">{{ contBasMatrVO.chldNm02 }}</span>
                <em v-show="!$bizUtil.isEmpty(contBasMatrVO.chldNm03)"></em>
              </span>
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.chldNm03)">
                <span class="tit">자녀3</span>
                <span class="txt">{{ contBasMatrVO.chldNm03 }}</span>
              </span>
            </div>
          </li>
          <!-- 수익자 1명 이상일 경우, 화면에 노출 -->
          <li v-show="!$bizUtil.isEmpty(contBasMatrVO.expBnfrNm) || !$bizUtil.isEmpty(contBasMatrVO.injryBnfrNm) || 
                      !$bizUtil.isEmpty(contBasMatrVO.dthBnfrNm01) || !$bizUtil.isEmpty(contBasMatrVO.dthBnfrNm02)">
            <p class="fs17rem">수익자</p>
            <div class="mt10">
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.expBnfrNm)">
                <span class="tit">만기</span>
                <span class="txt">{{ contBasMatrVO.expBnfrNm }}</span>
                <em v-show="!$bizUtil.isEmpty(contBasMatrVO.injryBnfrNm)"></em>
              </span>
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.injryBnfrNm)">
                <span class="tit">상해</span>
                <span class="txt">{{ contBasMatrVO.injryBnfrNm }}</span>
              </span>
            </div>
            <div class="mt4">
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.dthBnfrNm01)">
                <span class="tit">사망1</span>
                <span class="txt">{{ contBasMatrVO.dthBnfrNm01 }}</span>
                <em v-show="!$bizUtil.isEmpty(contBasMatrVO.dthBnfrNm02)"></em>
              </span>
              <span v-show="!$bizUtil.isEmpty(contBasMatrVO.dthBnfrNm02)">
                <span class="tit">사망2</span>
                <span class="txt">{{ contBasMatrVO.dthBnfrNm02 }}</span>
              </span>
            </div>
          </li>
        </ul>
      </ur-box-container>
      
    </ur-box-container>
    <!-- 컨텐츠영역  //-->

    <!-- 2024-01-09 선심사 및 영업시스템 고도화 PJT 삭제 -->
    <!-- <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column"  class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPPM933P">계약상세정보</mo-button>
      </div>
    </ur-box-container> -->
  </div>
 <!-- </ur-page-container> -->
</template>

<script>

import MSPPM933P from '@/ui/pm/MSPPM933P.vue' // 계약상세정보 

export default {

  name: 'MSPPM932D', 
  screenId: 'MSPPM932D', 
  components: {},//componets,

  props:{
    pChnlCustId: {
      type: String,
      default: ''
    },
    pCnsltNo: {
      type: String,
      default: ''
    }
  },//props

  data () {
    return {
      title: '계약정보',
      
      /**
       * 부모화면(MSPPM931M)에 넘겨지는 데이터 
       */
      emitData:{
        vainsPrdtInfoIcluYn:'', // 변액정보 여부 
        contDtlStatCdNm:'',     // 계약상태
        contInfoVO: {},         // 계약정보 데이터
        isNoData: false         // 미등록 계약(error) 여부 
      },

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},        // 계약메인데이터(MSPPM931M) 
      contNo: '',               // 계약번호 
      chnlCustId: '',           // 채널ID 
      cnsltNo: '',              // 컨설던트ID

      lv_UserInfo: {},                // 사용자 정보

      lv_InputParam: {},        // 서비스호출 파라미터 

      /**
       * 조회 변수
       */ 
      contInfoVO: {},           // 계약정보 데이터 

      /** 
       * 팝업 연동
       */
      popup933: {},             // MSPPM933P 계약상세정보

    }
  },//data

  created () {
    console.log('■■■■■■■■■■■■■■ MSPPM932D ■■■■■■■■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },

  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  methods: {
       
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
   fn_Submit(){

    // 초기화 
    this.contInfoVO    = {}

    // 데이터 세팅
    this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
    this.contNo        = this.contBasMatrVO.contNo
    this.chnlCustId    = this.pChnlCustId                                    
    this.cnsltNo       = this.pCnsltNo                                      

    // 조회조건 세팅  
    this.lv_InputParam.contNo           = this.contNo  
    this.lv_InputParam.prscpContYn      = 'N'          // 시효계약여부
    this.lv_InputParam.fncInfoPrtcReqYn = ''           // 금융정보보호요청여부

    // 서비스 호출 
    this.fn_GetService() // 계약정보 조회
   },
   
    /**
     * @description 계약정보 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth     = 'S'
      const trnstId  = 'txTSSPM01P2'

      let pParams    = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPC0059'),
        fahrTrnId: 'S0',
        requestSystemCode: '',
        reqSrvcNm: 'ICSelRetContCtlImpl',
        reqMthdNm: 'selListRetContDtl',
        targetSystemCode: 'sli.ic',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0059ReqVO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0059ResVO'
      } 

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
    
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)

        // 데이터 없음
        this.emitData.isNoData = true

        // MSPPM931M으로 emit 
        this.$emit('setChildData', this.emitData)

        return
      }else{this.emitData.isNoData = false}

      let responseVO = response.body

      // 데이터 가공 
      responseVO.rcmsnStatNm   = (responseVO.rcmsnYmd === '99991231') ? '위촉' : '해촉'                                                     
      responseVO.contYmd       = this.$commonUtil.dateDashFormat(responseVO.contYmd)                                                       
      responseVO.insrPrdNm     = responseVO.insrPrdNm ? Number(responseVO.insrPrdNm) + '년' : ''                                         
      responseVO.expYmd        = this.$commonUtil.dateDashFormat(responseVO.expYmd)                                                  
      responseVO.prcContrRrn   = this.fn_SetRrnMask(responseVO.cnsltInfo.contrRrn)                                                   
      responseVO.prcInsrdRrn   = this.fn_SetRrnMask(responseVO.cnsltInfo.insrdRrn)     
      
      responseVO.prcClctCnslt  = responseVO.cnsltInfo.clctCnsltNm + '('+ responseVO.cnsltInfo.clctCnsltNo +')'
      responseVO.prcClctOrg    = responseVO.cnsltInfo.clctDofNm + " " + responseVO.cnsltInfo.clctFofNm

      // 유지/유의정보(MSPPM935D)에 나열될 데이터 
      responseVO.prcPadPrm     = this.$bizUtil.numberWithCommasCurr(responseVO.padPrm.amount, responseVO.cnsltInfo.crncyCd, 'Y', 'Y', 3)   // 납입보험료(외환보험일 경우 $로 표시)
      responseVO.prcContPrm    = this.$bizUtil.numberWithCommasCurr(responseVO.contPrm.amount, responseVO.cnsltInfo.crncyCd, 'Y', 'Y', 3)  // 계약보험료(외환보험일 경우 $로 표시)
      responseVO.mnInsrPadPrd  = (responseVO.mnInsrPadPrd) ? Number(responseVO.mnInsrPadPrd) + '년' : ''                                   
      responseVO.trsfDd        = (responseVO.trsfDd) ? Number(responseVO.trsfDd) + '일' : ''                                               
      responseVO.ukepYm        = this.$commonUtil.dateDashFormat(responseVO.ukepYm)                                                        
      responseVO.ukepYmd       = this.$commonUtil.dateDashFormat(responseVO.ukepYmd)                                                      
      responseVO.lstPadYmd     = this.$commonUtil.dateDashFormat(responseVO.lstPadYmd)     

      // 데이터 저장 
      this.contInfoVO = responseVO

      // emit 데이터 설정 
      this.emitData.vainsPrdtInfoIcluYn = responseVO.vainsPrdtInfoIcluYn   
      this.emitData.contDtlStatCdNm     = responseVO.contDtlStatCdNm       
      this.emitData.contInfoVO          = responseVO

      this.fn_SearchCont() // 보유계약 조회

      // MSPPM931M으로 emit 
      this.$emit('setChildData', this.emitData)

    },

   /**
    * @description 주민번호를 마스킹 처리한다.
    * @param       {String} rrn 주민번호 
    * @return      {String} rrn 마스킹된 주민번호 
    */
    fn_SetRrnMask(rrn){

      if (rrn.length === 13) {
        return this.$commonUtil.convertToMask('RRN', rrn.substr(0, 6) + '-' + rrn.substr(6))
      } else if (rrn.length === 10) {
        return rrn.substr(0, 3) + '-' + rrn.substr(3, 2) + '-' + rrn.substr(5, 5)
      } else {
        return rrn
      }
      
    },
    

    /**
     * @description 계약상세정보 팝업을 제어한다.
     */
    fn_OpenMSPPM933P(){

      this.popup933 = this.$bottomModal.open(MSPPM933P, {
        properties:{
          pPage: this.$options.screenId,
          pContNo: this.contNo,
          pMarkUpContNo: this.contBasMatrVO.markUpContNo,
          pPrdtNm: this.contBasMatrVO.prdtNm,
          pContrNm: this.contBasMatrVO.contrNm
        },

        listeners:{
          closePopup: () => {}
        }
      })

    },
    
    /**
     * @description 계약자명을 클릭할 때 nameUI를 호출한다.
     */
    fn_OpenCustInfoBs(){
      const lv_Vm = this
      let contInfo = this.lv_RetContVO

      let nmUiObject = {}
      
      nmUiObject = {
        cnsltNo : contInfo.clmnyCnsltNo,  // 담당 컨설턴트
        chnlCustId : contInfo.conrtAgtFileId,    //고객채널 ID
        parentId   : 'MSPPM931M', //화면ID+
        contNo : this.contNo, // 계약번호
        contrInsrdSameCd : contInfo.contrInsrdSameCd, // '01': 계피 동일, '02': 계피 상이
        custNm : contInfo.contrCustNm, // 계약자 명
        custId : contInfo.contrCustBpId, // 계약자 CustId
        sxdsCd : contInfo.contrSexCd, // 계약자 성별 코드
        sxdsCdNm : contInfo.contrSexCdNm, // 계약자 성별
        birthDate : contInfo.contrDob, // 계약자 생년월일
        knb: contInfo.contrKnb, // 계약자 Knb
        knbEnc: contInfo.contrKnbEnc, // 계약자 Knb(마스킹)
        isInsrd : false
      }

      this.$pmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (pData) => {
        window.vue.getStore('progress').dispatch('FULL') // post 호출 전 Progress 타입 설정
        if (nmUiObject.contrInsrdSameCd === '01') {
          lv_Vm.fn_SetAsyncChnlCustId('01', pData)
        }
        return
      })

    },
    /**
     * @description 피보험자명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 보유계약 데이터 
     */
    async fn_OpenInsrdCustInfoBs () {
      const lv_Vm = this
      let contInfo = this.lv_RetContVO

      let nmUiObject = {}

      nmUiObject = {
        cnsltNo : contInfo.clmnyCnsltNo,   // 담당 컨설턴트
        chnlCustId : contInfo.insrdAgtFileId, //고객채널 ID
        parentId   : 'MSPPM931M', //화면ID+
        contrInsrdSameCd : contInfo.contrInsrdSameCd, // '01': 계피 동일, '02': 계피 상이
        custNm : contInfo.insrdCustNm, // 피보험자 명
        custId : contInfo.insrdCustBpId,  // 피보험자 CustId
        sxdsCd : contInfo.insrdSexCd,     // 피보험자 성별코드
        sxdsCdNm : contInfo.insrdSexCdNm, // 피보험자 성별
        birthDate : contInfo.insrdDob, // 피보험자 생년월일
        knb: contInfo.insrdKnb, // 피보험자 Knb
        knbEnc: contInfo.insrdKnbEnc, // 피보험자 Knb(마스킹)
        isInsrd : true
      }

      lv_Vm.$pmUtil.fn_OpenCustNmUiCard(lv_Vm, nmUiObject, (pData) => {
        window.vue.getStore('progress').dispatch('FULL') // post 호출 전 Progress 타입 설정
        if (nmUiObject.contrInsrdSameCd === '01') {
          lv_Vm.fn_SetAsyncChnlCustId('02', pData)
        }
        return
      })
    }, 

    /**
     * @description 계피동일 계약건, 채널고객ID 동기화
     * @param       {Object}  custInfo 고객카드 생성된 고객 데이터 
     */
    fn_SetAsyncChnlCustId(custTye, pData) {
      let itemCustId = custTye === '01' ? this.lv_RetContVO.contrCustBpId : this.lv_RetContVO.insrdCustBpId
      if (!_.isEmpty(pData) && !this.$bizUtil.isEmpty(pData.chnlCustId)) itemCustId = pData.chnlCustId

      this.chnlCustId = itemCustId
      this.lv_RetContVO.conrtAgtFileId = itemCustId
      this.lv_RetContVO.insrdAgtFileId = itemCustId
    },

    /**
     * @description 보유계약 조회한다
     */
    fn_SearchCont () {
      const lv_Vm = this

      let pParams = this.fn_SetParams()

      const trnstId = 'txTSSPM50S1' // 신규
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          console.log(res.body)
          if (!_.isEmpty(res.body) && !_.isEmpty(res.body.pMNewRetContVO)) {
            let retContVO = res.body.pMNewRetContVO

            // 피보험자 이름 마스킹으로 인해, 피보험자 생년월일로 필터링으로 여러건 방어코드
            retContVO = retContVO.filter(item => {
                return lv_Vm.contInfoVO.cnsltInfo.insrdRrn.substr(0,7) === item.insrdKnb.substr(0,7)
            })

            retContVO.forEach(item => {
              item.mkContrDob = this.$pmUtil.getMaskingBirth(item.contrDob)
              item.mkInsrdDob = this.$pmUtil.getMaskingBirth(item.insrdDob)
            })

            lv_Vm.lv_RetContVO = retContVO[0]
            this.$emit('setRetContVO', lv_Vm.lv_RetContVO)
          }
        })
        .catch(error => {
          window.vue.error(error)
        })
    },
    /**
     * @description 보유계약 조회 파라미터 셋팅
     */
    fn_SetParams () {
      const lv_Vm = this
      
      let contInfo = this.contInfoVO.cnsltInfo // this.contInfoVO.cnsltInfo
      let contrSexCd = ''
      let insrdSexCd = ''

      contrSexCd = contInfo.contrRrn.substr(6,1)
      insrdSexCd = contInfo.insrdRrn.substr(6,1)

      // 남자코드 2, 여자코드 1
      if (!this.$bizUtil.isEmpty(contrSexCd)) {
        contrSexCd = contrSexCd === '1' || contrSexCd === '3' || contrSexCd === '5' || contrSexCd === '7' ? '2' : '1'
      }
      if (!this.$bizUtil.isEmpty(insrdSexCd)) {
        insrdSexCd = insrdSexCd === '1' || insrdSexCd === '3' || insrdSexCd === '5' || insrdSexCd === '7' ? '2' : '1'
      }

      let iCRetContCmpxVO = {
        cnsltNo: contInfo.clmnyCnsltNo, // 담당 컨설턴트 사번
        orgNo: this.lv_UserInfo.onpstFofOrgNo, // 지점번호

        custNm: contInfo.contrNm, // 계약자명
        contStatCd: '', // 계약상태구분코드
        contScCd: '', // 모집유형
        contCnt: '0', // 계약건수
        ICContDtlCondSrchVO: {}
      }
      let ICContDtlCondSrchVO = {
        contStatCdNm: '', //  계약상태코드명
        padCylCdNm: contInfo.padCylCd, //  납입주기코드명
        contYmd01: contInfo.contYmd, // 계약일자 From
        contYmd02: contInfo.contYmd, // 계약일자 To
        contAmt01: contInfo.contAmt.amount, // '0', //  계약금액01
        contAmt02: contInfo.contAmt.amount, // '999999999999999', //  계약금액02
        smtotPrm01: '0',// contInfo.smtotPrm.amount, // 합계보험료01
        smtotPrm02: '999999999999999',// contInfo.smtotPrm.amount, // 합계보험료02
        SpcAccoTypCd: '', // 변액 구분
        chnlPrdtClsfCdNm: '', //  채널상품분류코드명
        contrSxdsCd: contrSexCd, // 계약자 성별
        insrdNm: this.contInfoVO.mcinsrdVO.custNm, // 피보험자명
        insrdSxdsCd: insrdSexCd, // 피보험자 성별
        atrAplScCdNm: '', // 자동이체일
        ukepNtsScCd: '', // 유지횟수
        prdtNm: this.contBasMatrVO.prdtNm, // 상품명
      }
      iCRetContCmpxVO.ICContDtlCondSrchVO = ICContDtlCondSrchVO

      iCRetContCmpxVO.contrInsrdSameCd = '01' // 보유계약 리스트 조회 코드

      return iCRetContCmpxVO
    },
 },// methods

}//export default


</script>