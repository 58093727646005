/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM943D
 * 화면 설명: 보험계약기본사항 > 변액정보 > 추천포트폴리오
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.23
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <ur-box-container alignV="start" componentid="" direction="row" class="fexTy3-1 full pa12024 align-item-center">
          <div>
            <span class="fs16rem">현재 포트폴리오</span>
            <span class="ns-tool-tip ml5 msp_vertical--tb">
              <mo-icon id="location_MSPPM943D" icon="msp-tool-tip"></mo-icon>
              <mo-tooltip target-id="location_MSPPM943D" class="tool-tip-box">
                <p>해당 포트폴리오는 조회일 기준의 당월 포트폴리오로, 매월 시장상황에 따라 바뀔 수 있으니 반드시 참고바랍니다.</p>
                <p>본 화면은 펀드변경 참고용으로 과거계약의 경우, 펀드추가서비스 특약을 가입하지 않은 고객은 해당 펀드를 선택하지 못할 수 있습니다.</p>
                <p>계약별 현재 선택 가능한 펀드는 펀드별 기간 수익률 화면을 참고하시기 바랍니다.</p>
              </mo-tooltip>                  
            </span>
          </div>
          <mo-switch v-model="isCurPrflo" small class="ns-switch" />
        </ur-box-container>

        <ur-box-container  v-show="isCurPrflo" alignV="start" componentid="" direction="row" class="full mb20">
          <div v-if="!isNoDataCur" class="msp-detail-lst ty--2">
            <ul class="mt10">
              <template v-for="(item, index) in curPrfloVO">
                <li :key="index+'1'">
                  <!-- 펀드명 -->
                  <span class="crTy-bk7">{{ item.zfundTt }}</span>
                  <!-- 실적립액비율 -->
                  <span class="fs16rem">{{ item.zrto }}%</span>
                </li>
              </template>
            </ul>
          </div>
          <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="no-data expand_topline expand_bottomline">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info hauto "> <!-- 영역이 작은곳에서 활용 small-space -->
                  <span class="ns-ftcr-gray"><p class="mt10">데이터가<br/>존재하지 않습니다.</p></span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="msp-detail-lst">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="prfloType" class="chip-ty1">
              <mo-segment-button value="1">모델포트폴리오</mo-segment-button>
              <mo-segment-button value="2">S자산배분형</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        
          <!-- 모델포트폴리오 -->
          <ur-box-container v-if="!isNoDataType1 && prfloType === '1'" alignV="start" componentid="" direction="column" class="mt20 pb150">
            <ul class="lst lst--3">
              <template v-for="(item, index) in mdlPrfloTpVO">
                <li v-if="item.vo.length !== 0" :key="index+'2'">
                  <p class="fs17rem">{{ item.text }}</p>
                  <!-- 펀드종류 1개이면, 텍스트형 노출 -->
                  <template v-if="item.vo.length <=1">
                    <div class="mt10 fexTy3" id="preview">
                      <!-- 펀드명 -->
                      <span class="tit">{{ item.vo[0].fundLongTT }}</span>
                      <!-- 분담비중 -->
                      <span class="txt wauto">{{ item.vo[0].fndRto01 }}%</span>
                    </div>
                  </template>
                  <!-- 펀드종류 2개이상이면, 그래프형 노출 -->
                  <div id="detail_view" class="msp_detail_view--1">
                    
                    <div class="chart-img">
                      <div :id="'mdlChart'+index" style="position: relative; width: 150px; height: 150px; margin: auto; background: #F5F7FA;"></div>
                    </div>

                    <div class="detail-bullet">
                      <template v-for="(item1, index1) in item.vo">
                        <!-- 펀드이름별 그래프 색상 -->
                        <span class="fs12rem crTy-bk9" :class="colorsType[index1].name" :key="index1+'3'">{{ item1.fundLongTT }}</span>
                      </template>
                    </div>
                    <ul class="mt10 lst_in--1">
                      <!-- 펀드이름별 그래프 -->
                      <template v-for="(item2, index2) in item.vo">
                        <li :key="index2+'3'">
                          <span class="fs14rem crTy-bk7">{{ item2.fundLongTT }}</span>
                          <span>{{ item2.fndRto01 }}%</span>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <!-- 그래프 형 끝-->
                </li>
              </template>
            </ul>
          </ur-box-container>
          <ur-box-container v-if="isNoDataType1 && prfloType === '1'" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info"> <!-- 영역이 작은곳에서 활용 small-space -->
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>


          <!-- S자산배분형 -->
          <ur-box-container v-if="!isNoDataType2 && prfloType === '2'" alignV="start" componentid="" direction="column" class=" mt20 pb150">
            <ul class="lst lst--3">
              <template v-for="(item, index) in sAsetDtTpVO">
                <li v-if="item.vo.length !== 0" :key="index">
                  <p class="fs17rem">{{ item.text }}</p>
                  <!-- 펀드종류 1개이면, 텍스트형 노출 -->
                  <template v-if="item.vo.length <=1">
                    <div class="mt10 fexTy3" id="preview">
                      <!-- 펀드명 -->
                      <span class="tit">{{ item.vo[0].fundLongTT }}</span>
                      <!-- 분담비중 -->
                      <span class="txt wauto">{{ item.vo[0].fndRto01 }}%</span>
                    </div>
                  </template>
                  <!-- 펀드종류 2개이상이면, 그래프형 노출 -->
                  <template v-else>
                    <div id="detail_view" class="msp_detail_view--1">
                      <div class="chart-img">
                        <div :id="'asetChart'+index" style="position: relative; width: 150px; height: 150px; margin: auto; background: #F5F7FA;"></div>
                      </div>
                      <div class="detail-bullet">
                        <template v-for="(item1, index1) in item.vo">
                          <!-- 펀드이름별 그래프 색상 -->
                          <span class="fs12rem crTy-bk9" :class="colorsType[index1].name" :key="index1+'1'">{{ item1.fundLongTT }}</span>
                        </template>
                      </div>
                      <ul class="mt10 lst_in--1">
                        <!-- 펀드이름별 그래프 -->
                        <template v-for="(item2, index2) in item.vo">
                          <li :key="index2+'3'">
                            <span class="fs14rem crTy-bk7">{{ item2.fundLongTT }}</span>
                            <span>{{ item2.fndRto01 }}%</span>
                          </li>
                        </template>
                      </ul>
                    </div>
                    <!-- 그래프 형 끝-->
                  </template>
                </li>
              </template>
            </ul>
          </ur-box-container>
          <ur-box-container v-if="isNoDataType2 && prfloType === '2'" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info"> <!-- 영역이 작은곳에서 활용 small-space -->
                  <span>데이터가 없습니다.</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>



        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

require('@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css')
require('@/assets/lib/rMateChartH5/JS/rMateChartH5.js')
require('@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js')
require('@/assets/lib/rMateChartH5/JS/rMateChartVar.js')


export default {

  name: 'MSPPM943D', 
  screenId: 'MSPPM943D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '추천포트폴리오',

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},                // 계약메인데이터(MSPPM931M) 
      contNo: '',                       // 계약번호 

      lv_InputParam: {},                // 서비스호출 파라미터 

      /**
       * 화면변수
       */
      isNoDataType1: false,             // 모델포트폴리오 데이터 여부 
      isNoDataType2: false,             // S자산배분형 데이터 여부 
      isCurPrflo: false,                // 현재 포트폴리오 여부 
      isNoDataCur: false,               // 현재 포트폴리오 데이터 여부 
      prfloType: '1',                   // 포트폴리오 유형 (모델포트폴리오, S자산배분형)

      /**
       * 조회 변수
       */ 
      mdlPrfloTpVO: [],                // 모델포트폴리오 데이터
      sAsetDtTpVO: [],                 // S자산배분형 데이터 
      curPrfloVO: [],                  // 현재포트폴리오 데이터 

      /**
       * 차트 색상 데이터
       */
      colorsType: [{name: 'bullet--blue', color: '#519ef6'}, {name: 'bullet--orange', color: '#ffb60d'}, 
                   {name: 'bullet--cyan', color: '#66c8c9'}, {name: 'bullet--blue2', color: '#416dff'}, 
                   {name: 'bullet--red', color: '#fb7655'}, {name: 'bullet--red2', color: '#e75d48'}, 
                   {name: 'bullet--purple', color: '#7960ff'}, {name: 'bullet--gray', color: '#eee'}],
    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {

    /**
     * 현재포트폴리오 여부를 저장한다.
     */
    isCurPrflo(val){
      this.isCurPrflo = val
    },

    /**
     * 포트폴리오 유형을 저장하고, 차트생성 함수를 호출한다.
     */
    prfloType(val){
      this.prfloType = val

      this.$nextTick(() => {
        this.fn_CreateCharts()
      })

    },

  },//watch

  computed: {},//computed

  methods: {
         
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    *              -> 추천포트폴리오, 현재포트폴리오 서비스
    */
   fn_Submit(){

      // 초기화 
      this.mdlPrfloTpVO.splice(0)
      this.sAsetDtTpVO.splice(0)
      this.curPrfloVO.splice(0)
      this.isNoDataType1 = false             
      this.isNoDataType2 = false             
      this.isCurPrflo    = false 
      this.isNoDataCur   = false               
      this.prfloType     = '1'

      // 데이터 세팅
      this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo        = this.contBasMatrVO.contNo

      // 조회조건 세팅  
      this.lv_InputParam.abdApplnrTT  = this.contNo  

      this.fn_GetService()  // 서비스 호출 
      this.fn_GetCurPrflo() // 현재 포트폴리오 서비스 

    },
      
    /**
     * @description 추천포트폴리오 조회 서비스 호출 후, 콜백 데이터를 처리한다.
     * @callback    function(response)
     */
    fn_GetService() {

      const auth     = 'S'
      const trnstId  = this.$pmUtil.getTrnstId()

      let lv_Vm      = this
      let pParams    = this.lv_InputParam
      let confirm    = this.getStore('confirm')
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPF0017'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0017ResVO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0017ReqVO'
      } 

      return this.post(this, pParams, trnstId, auth, commHeader) 
        .then((response) => {

          // 인터페이스 오류시 confirm 창 띄우고 return
          if (!response.body) {

            let msgcomm = response.msgComm
            let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
            confirm.dispatch('ADD', message)

            // 데이터 없음
            lv_Vm.isNoDataType1 = true
            lv_Vm.isNoDataType2 = true

            return
          }

          let responseList = response.body.fndStrt01

          let mdlPrfloTp30VO = [], mdlPrfloTp50VO = [], mdlPrfloTp70VO = [], sAsetDtTp30VO = [], sAsetDtTp50VO = [], sAsetDtTp70VO = []
          
          if(responseList){
            responseList.forEach((item) => {
              // 데이터 저장 
              switch(item.modelCat){
                case '01': // 모델포트폴리오 주식30형
                  mdlPrfloTp30VO.push(item)
                  break;
                case '02': // 모델포트폴리오 주식50형
                  mdlPrfloTp50VO.push(item)
                  break;
                case '03': // 모델포트폴리오 주식70형
                  mdlPrfloTp70VO.push(item)
                  break;
                case '04': // S자산배분형 주식 30형
                  sAsetDtTp30VO.push(item)
                  break;
                case '05': // S자산배분형 주식 50형
                  sAsetDtTp50VO.push(item)
                  break;
                case '06': // S자산배분형 주식 70형
                  sAsetDtTp70VO.push(item)
                  break;
              }
            })
  
            // 전체 데이터 저장
            lv_Vm.mdlPrfloTpVO = [{text: '주식30형', vo: mdlPrfloTp30VO}, {text: '주식50형', vo: mdlPrfloTp50VO},{text: '주식70형', vo: mdlPrfloTp70VO},]
            lv_Vm.sAsetDtTpVO  = [{text: '주식30형', vo: sAsetDtTp30VO}, {text: '주식50형', vo: sAsetDtTp50VO}, {text: '주식70형', vo: sAsetDtTp70VO},]
  
            // 데이터 없음(모델포트폴리오) 
            if(mdlPrfloTp30VO.length === 0 && mdlPrfloTp50VO.length === 0 && mdlPrfloTp70VO.length === 0){
              lv_Vm.isNoDataType1 = true
            }else{
              lv_Vm.isNoDataType1 = false
            }
  
            // 데이터 없음(S자산배분형) 
            if(sAsetDtTp30VO.length === 0 && sAsetDtTp50VO.length === 0 && sAsetDtTp70VO.length === 0){
              lv_Vm.isNoDataType2 = true
            }else{
              lv_Vm.isNoDataType2 = false
            }

          }else{
            lv_Vm.isNoDataType1 = true
            lv_Vm.isNoDataType2 = true
            return
          }

        })
        .then(()=> {
          // 차트 생성 
          lv_Vm.fn_CreateCharts()
        })
    },

    /**
     * @description 원형(Doughnut) 차트 생성(rMate) 한다.
     */
    fn_CreateCharts () {

      let lv_Vm     = this
      let chartName = (lv_Vm.prfloType === '1') ? 'mdlChart' : 'asetChart'                 // 차트이름 
      let targetVO  = (lv_Vm.prfloType === '1') ? lv_Vm.mdlPrfloTpVO : lv_Vm.sAsetDtTpVO   // 선택된 탭의 데이터 

      // 모델포트폴리오 || S자산배분형 데이터가 존재할 때 
      if(targetVO && targetVO.length != 0){

        targetVO.forEach((target, idx) => {

          // 데이터가 2개이상일 경우만 차트 출력한다.
          if(target.vo.length >= 2 ){

            // 초기화 
            let tempChartVO = []
            let chartColors = ''  

            // 데이터 저장 
            target.vo.forEach((subTarget, idx2) => {

              let data = {title: subTarget.fundLongTT, value: Number(subTarget.fndRto01), label: Number(subTarget.fndRto01) + "%"} // 펀드명, 분담비중, 분담비중%

              // 차트데이터 저장 
              tempChartVO.push(data)

              // 데이터별 색상 설정 
              chartColors += '<SolidColor color="' + lv_Vm.colorsType[idx2].color + '"/>' + '\n'

            })

            // 차트 생성 
            chartData = tempChartVO
            layoutStr = `<rMateChart backgroundColor="#FFFFFF"  borderStyle="none">
                          <Pie2DChart id="chart" innerRadius = "0" showDataTips="true" selectionMode="single">
                              <series>
                                  <Pie2DSeries nameField="title" field="value" labelField="label" startAngle="20" renderDirection="clockwise" labelPosition="inside" color="black">
                                      <fills>
                                          `+ chartColors +`
                                      </fills>
                                      <showDataEffect>
                                          <SeriesZoom duration="1000"/>
                                      </showDataEffect>
                                  </Pie2DSeries>
                              </series>
                              
                          </Pie2DChart>
                      </rMateChart>`

            rMateChartH5.create('MSPPM943D_'+ idx, chartName + idx, 'rMateOnLoadCallFunction=chartReadyHandler', '100%', '100%')
            rMateChartH5.registerTheme(rMateChartH5.themes)

          }
        })
      }

    },

   /**
     * @description 현재포트폴리오 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetCurPrflo(){

      const auth     = 'S'
      const trnstId  = this.$pmUtil.getTrnstId()

      let pParams    = {contNo: this.contNo}
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPF0021'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0021VO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0021VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
      
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)
        this.isNoDataCur = true 

        return
      }

      let responseList = response.body.zsfpf00101

      if(responseList && responseList.length != 0){
        responseList.forEach((item) => {
          // 전체 데이터는 제외하고 출력한다.
          if(!item.zfundTt.includes('전체')){ 

            // 데이터 가공 
            item.zfundTt = item.zfundTt.split(' ')[0]

            // 데이터 저장
            this.curPrfloVO.push(item)

          }
        })
      }else{
        this.isNoDataCur = true 
      }

    }
    
 },// methods

}//export default
 

</script>