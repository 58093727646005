/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM942D
 * 화면 설명: 보험계약기본사항 > 변액정보 > 기간별펀드수익률
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.23
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      
      <!-- 데이터개수 start -->
      <ur-box-container class="area-bb flex-sc pt10">
        <div class="txt-sum">총 <strong>{{ totalCount }}</strong> 건</div>
        <div class="flex-col-se" v-if="!isNoData">
          <div class="wrap-btn">
            <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
              <mo-radio value="1" small>목록</mo-radio>
              <mo-radio value="2" small>표</mo-radio>
            </mo-radio-wrapper>
            <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open"> 크게보기</mo-button>
          </div>
        </div>
      </ur-box-container>
      <!-- 데이터개수 end -->

      <!-- 기간별펀드수익률 start -->
      <ur-box-container v-if="!isNoData" alignV="start" componentid="" direction="column" class="ns-list-area pb-0">
        <!-- 표 모드 -->
        <ur-box-container v-if="radioButton==='2'" class="sticky-pd">
          <div class="sticky-area">
            <div class="table-area sticky-t06" ref="tableArea" @scroll="fn_checkScroll(1)">
              <table class="table row-type ws_N">
                <thead>
                  <tr>
                    <th rowspan="2">펀드명</th>
                    <th rowspan="2">기준가</th>
                    <th rowspan="2">펀드설정일</th>
                    <th colspan="5">기간별 수익률</th>
                    <th rowspan="2">누적수익률(%)</th>
                    <th rowspan="2">연환산수익률(%)</th>
                    <th colspan="5">특별계정운용보수</th>
                  </tr>
                  <tr>
                    <th>최근 1개월</th>
                    <th>최근 3개월</th>
                    <th>최근 6개월</th>
                    <th>최근 1년</th>
                    <th>최근 3년</th>
                    <th>운영보수</th>
                    <th>투자일임보수</th>
                    <th>사무관리보수</th>
                    <th>수탁보수</th>
                    <th>합계</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in slfndClbyPrdEarrtInfoVO" :key="i">
                    <td class="ws_Y aL"> {{item.fndNm}} </td>
                    <td class="aR"> {{item.prcFndFnprc}} </td>
                    <td class="aR"> {{item.avlBgnYmd}} </td>
                    <td class="aR"> {{item.rntDmt1BnfRat}} </td>
                    <td class="aR"> {{item.rntDmt3BnfRat}} </td>
                    <td class="aR"> {{item.rntDmt6BnfRat}} </td>
                    <td class="aR"> {{item.rntOyBnfRat}} </td>
                    <td class="aR"> {{item.rntThyBnfRat}} </td>
                    <td class="aR"> {{item.cumlBnfRat}} </td>
                    <td class="aR"> {{item.ychgUnitBnfRat}} </td>
                    <td class="aR"> {{item.spcAccoAppnFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoIvtDscrnFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoOfcwkMngFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoRctstFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoSmtot.toFixed(3)}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
            <button class="icon-down2 pos-fixed" @click="fn_scrollToDown(1)" ref="iconDown" v-show="isShowChevronDown"><i></i></button>
          </div>
        </ur-box-container>

        <ur-box-container v-if="radioButton === '1'" alignV="start" componentid="" direction="column" class="ns-info-list">
          <mo-list :list-data="slfndClbyPrdEarrtInfoVO">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__title align-item-center">
                    <!-- 펀드명 -->
                    <span @click="fn_OpenEarrtDtl(item)" class="name txtSkip fs19rem lnkTy1">{{ item.fndNm }}</span>
                    <!-- 펀드설명(툴팁) -->
                    <span class="ns-tool-tip ml10">
                      <mo-icon :id="'location_MSPPM942D'+item.idx" icon="msp-tool-tip"></mo-icon>
                      <mo-tooltip :target-id="'location_MSPPM942D'+item.idx" class="tool-tip-box">
                        <!-- 펀드특징 -->
                        <template v-for="(fndDesc, idx) in item.prcFndDesc">
                          <p v-if="!$bizUtil.isEmpty(fndDesc)" :key="'fndDesc_'+item.idx+'_'+idx" >{{ fndDesc }}</p>
                        </template>
                      </mo-tooltip>                  
                    </span>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box mt10">
                      <div class="full">
                        <span class="label-title mr10" >기준가</span>
                        <span>{{ item.prcFndFnprc }}</span>
                      </div>
                      <div class="full">
                      </div>
                        <span class="label-title mr10">펀드설정일</span>
                        <span>{{ item.avlBgnYmd }}</span>
                      <div class="full">
                        <span class="label-title mr10">누적수익률</span>
                        <span class="fwb">{{ item.cumlBnfRat }} %</span>
                      </div>
                      <div class="full">
                        <span class="label-title mr10">연환산수익률</span>
                        <span class="fwb">{{ item.ychgUnitBnfRat }} %</span>
                      </div>
                      <div class="full">
                        <span class="label-title mr10">특별계정운용보수합계</span>
                        <span class="fwb">{{ item.spcAccoSmtot }} %</span>
                      </div>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
      <!-- 기간별펀드수익률 end -->

      <!-- NoData start  -->
      <ur-box-container v-else alignV="start" componentid="ur-box-container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData end  -->

    </ur-box-container>
    
    <!-- BottomSheet start -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
      <template v-slot:title>{{ selectedEarrtInfo.fndNm }} </template>
      <div class="ns-btn-close" @click="fn_CloseEarrtDtl"></div>

      <!-- content 영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="content-area pb10">
        <div class="table-area">
          <table class="table row-type">
          <colgroup>
              <col width="30%" />
              <col width="37%" />
              <col width="33%" />
            </colgroup>
            <tbody>
              <tr>
                <th rowspan="5">기간별<br/>수익률<br/>(%)</th>
                <th class="pl10 txt-left bdRN">최근1개월</th>
                <td class="pr10 txt-right"> {{ selectedEarrtInfo.rntDmt1BnfRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">최근3개월</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.rntDmt3BnfRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">최근6개월</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.rntDmt6BnfRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">최근1년</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.rntOyBnfRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">최근3년</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.rntThyBnfRat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-area mt10">
          <table class="table row-type">
          <colgroup>
              <col width="30%" />
              <col width="37%" />
              <col width="33%" />
            </colgroup>
            <tbody>
              <tr>
                <th rowspan="5">특별계정<br/>운용보수<br/>(%)</th>
                <th class="pl10 txt-left bdRN">운영보수</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.spcAccoAppnFeeRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">투자별일임보수</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.spcAccoIvtDscrnFeeRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">사무관리보수</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.spcAccoOfcwkMngFeeRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">수탁보수</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.spcAccoRctstFeeRat }}</td>
              </tr>
              <tr>
                <th class="pl10 txt-left bdRN">보수합계</th>
                <td class="pr10 txt-right">{{ selectedEarrtInfo.spcAccoSmtot }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>
      <template v-slot:action>
        <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_CloseEarrtDtl" class="ns-btn-round blue ">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- BottomSheet end -->

    <!-- 크게보기 모드 -->
    <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
      <mo-top-bar>
        <mo-button class="btn_popup_back"></mo-button>
        <div slot="nav" class="icon-wrapper" @click="fn_close">
          <mo-icon color="white" borderless>drawer</mo-icon>
        </div>
        <div class="ui-gnb-title__main">기간별펀드수익률</div>
      </mo-top-bar>
      <!-- content 영역 -->
      <div class="content-area">
        <ur-box-container alignV="start" direction="column" class="ns-list-area">
          <ur-box-container>
            <div class="sticky-area">
              <div class="table-area sticky-t06" ref="tableAreaPop" @scroll="fn_checkScroll(2)">
                <table class="table row-type ws_N">
                  <thead>
                  <tr>
                    <th rowspan="2">펀드명</th>
                    <th rowspan="2">기준가</th>
                    <th rowspan="2">펀드설정일</th>
                    <th colspan="5">기간별 수익률</th>
                    <th rowspan="2">누적수익률(%)</th>
                    <th rowspan="2">연환산수익률(%)</th>
                    <th colspan="5">특별계정운용보수</th>
                  </tr>
                  <tr>
                    <th>최근 1개월</th>
                    <th>최근 3개월</th>
                    <th>최근 6개월</th>
                    <th>최근 1년</th>
                    <th>최근 3년</th>
                    <th>운영보수</th>
                    <th>투자일임보수</th>
                    <th>사무관리보수</th>
                    <th>수탁보수</th>
                    <th>합계</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in slfndClbyPrdEarrtInfoVO" :key="i">
                    <td class="ws_Y aL"> {{item.fndNm}} </td>
                    <td class="aR"> {{item.prcFndFnprc}} </td>
                    <td class="aR"> {{item.avlBgnYmd}} </td>
                    <td class="aR"> {{item.rntDmt1BnfRat}} </td>
                    <td class="aR"> {{item.rntDmt3BnfRat}} </td>
                    <td class="aR"> {{item.rntDmt6BnfRat}} </td>
                    <td class="aR"> {{item.rntOyBnfRat}} </td>
                    <td class="aR"> {{item.rntThyBnfRat}} </td>
                    <td class="aR"> {{item.cumlBnfRat}} </td>
                    <td class="aR"> {{item.ychgUnitBnfRat}} </td>
                    <td class="aR"> {{item.spcAccoAppnFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoIvtDscrnFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoOfcwkMngFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoRctstFeeRat}} </td>
                    <td class="aR"> {{item.spcAccoSmtot.toFixed(3)}} </td>
                  </tr>
                </tbody>
                </table>
              </div>
              <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRight" v-show="isShowChevron2"><i></i></button>
              <button class="icon-down" @click="fn_scrollToDown(2)" ref="iconDown" v-show="isShowChevronDown2"><i></i></button>
            </div>
          </ur-box-container>
        </ur-box-container>
      </div>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>

export default {

  name: 'MSPPM942D', 
  screenId: 'MSPPM942D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '기간별펀드수익률',

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},             // 계약메인데이터(MSPPM931M) 
      contNo: '',                    // 계약번호 

      lv_InputParam: {},             // 서비스호출 파라미터 

      /**
       * 화면 변수
       */
      isNoData: false,
      totalCount: 0,                    // 전체 건수
      radioButton: "2",                 // radio 버튼 값 
      isShowChevron: false,             // 표 모드 affordance 버튼 제어
      isShowChevronDown: false,         // 표 모드 affordance 버튼 제어
      isShowChevron2: false,            // 크게보기 모드 affordance 버튼 제어
      isShowChevronDown2: false,        // 크게보기 모드 affordance 버튼 제어

      /**
       * 조회 변수
       */ 
      slfndClbyPrdEarrtInfoVO: [],  // 기간별펀드수익률 데이터
      selectedEarrtInfo: {},        // 선택된 기간별펀드수익률 데이터 
      fundExplnVO: [],              // 펀드특징 데이터        

    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted


  watch: {},//watch

  computed: {},//computed

  methods: {
        
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
   fn_Submit(){

    // 초기화 
    this.slfndClbyPrdEarrtInfoVO.splice(0)
    this.selectedEarrtInfo = {}
    this.isNoData          = false 
    this.totalCount        = 0

    // 데이터 세팅
    this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
    this.contNo        = this.contBasMatrVO.contNo
    
    // 조회조건 세팅  
    this.lv_InputParam.contNo  = this.contNo                 
    this.lv_InputParam.curYmd  = this.$pmUtil.getCurrentDate()   

    // 조회 시, affordance 버튼 미출력
    this.isShowChevron = false   
    this.isShowChevronDown = false
    
    // 서비스 호출 
    this.fn_GetService()

   },
      
    /**
     * @description 기간별펀드수익률 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth     = 'S'
      const trnstId  = this.$pmUtil.getTrnstId()

      let pParams    = this.lv_InputParam
      let commHeader = { // 기존인터페이스 : F1FPF0008
        eaiId: this.$pmUtil.getSVCId('F1FPF0022'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0022VO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0022VO'
      } 

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
        
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)

        // 데이터 없음 
        this.isNoData = true

        return
      }

      let responseList = response.body.slfndClbyPrdEarrtInfoVO

      if(responseList){
        
        responseList.forEach((item, index) => {

          // 데이터 가공 
          item.fndNm                 = item.fndNm.split(' ')[1]                                                                                  
          item.prcFndFnprc           = this.$commonUtil.numberWithCommas(item.fndFnprc.amount.substr(0, item.fndFnprc.amount.indexOf('.') + 3))  
          item.avlBgnYmd             = this.$commonUtil.dateDashFormat(item.avlBgnYmd)                                                         
          item.spcAccoAppnFeeRat     = item.spcAccoAppnFeeRat.substr(0, item.spcAccoAppnFeeRat.indexOf('.') + 4)                            
          item.spcAccoIvtDscrnFeeRat = item.spcAccoIvtDscrnFeeRat.substr(0, item.spcAccoIvtDscrnFeeRat.indexOf('.') + 4)                    
          item.spcAccoOfcwkMngFeeRat = item.spcAccoOfcwkMngFeeRat.substr(0, item.spcAccoOfcwkMngFeeRat.indexOf('.') + 4)                    
          item.spcAccoRctstFeeRat    = item.spcAccoRctstFeeRat.substr(0, item.spcAccoRctstFeeRat.indexOf('.') + 4)                     

          // 특별계정운용보수 합계
          item.spcAccoSmtot          = +(Number(item.spcAccoAppnFeeRat) + Number(item.spcAccoIvtDscrnFeeRat) + Number(item.spcAccoOfcwkMngFeeRat ) + Number(item.spcAccoRctstFeeRat)).toFixed(12)
          
          // 펀드특징 
          item.prcFndDesc            = item.fndDesc.split('ㆍ')
          item.prcFndDesc.forEach((desc, idx) => {
            if(!this.$bizUtil.isEmpty(desc))
              item.prcFndDesc[idx] = 'ㆍ ' + desc 
          })

          // 데이터 추가 
          this.$set(responseList[index], 'idx', index)

          // 데이터 저장 
          this.slfndClbyPrdEarrtInfoVO.push(item)

        })

        // 전체 건수 저장 
        this.totalCount = this.slfndClbyPrdEarrtInfoVO.length

        // 데이터 유무 
        if (this.slfndClbyPrdEarrtInfoVO.length === 0) {
          this.isNoData = true    
        } else {
          this.isNoData = false  
        }

      }else{
        this.isNoData = true 
      }

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.slfndClbyPrdEarrtInfoVO.length > 0){
          this.isShowChevronDown = true
          this.isShowChevron = true
        } else{
          this.isShowChevronDown = false
          this.isShowChevron = false
        }
      })
    },

   /**
    * @description 기간별펀드수익률 상세정보(기간별수익률, 특별계정 운용보수)가 열린다.
    * @param       {Object} item 선택된 기간별펀드수익률 데이터 
    */
    fn_OpenEarrtDtl(item){

      this.selectedEarrtInfo = item
      this.$refs.nsbottomsheet.open()

    },

   /**
    * @description 기간별펀드수익률 상세정보(기간별수익률, 특별계정 운용보수)가 닫힌다.
    */
    fn_CloseEarrtDtl(){

      this.$refs.nsbottomsheet.close()

    },

   /**
    * @description 크게보기 열기.
    */
    fn_open() {
      this.$refs.nsbottomsheet2.open();

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.slfndClbyPrdEarrtInfoVO.length > 0){
          this.isShowChevronDown2 = true
          this.isShowChevron2 = true
        }else{
          this.isShowChevronDown2 = false
          this.isShowChevron2 = false
        }
      })
    },

   /**
    * @description 크게보기 닫기
    */
    fn_close() {
      this.$refs.nsbottomsheet2.close();
    },

   /**
    * @description 표 모드 스크롤 이벤트.
    */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {        // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
      }
    },

   /**
    * @description Right affordance 버튼 이벤트.
    */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea;
      } else {
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        tableArea = this.$refs.tableAreaPop;
      }
      const scrollAmount = 200;
      const scrollDuration = 1000;
      let startingPosition = tableArea.scrollLeft;
      let targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
     },

   /**
    * @description Down affordance 버튼 이벤트.
    */
    fn_scrollToDown(value) {
      if(value === 1) {                 // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        this.$emit('scrollDown')
      } else {                          // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      }
    },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },
    
    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = this.easeInOutCubic(progress);
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress;
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress;
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
          
        }
      };
      animate(startTime);
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1;
    },
   /**
     * @description tab 이동 시, affordance 버튼 출력 이벤트.
     */
    fn_ShowChervon() {
      this.firstChevron =true
    }

 },// methods

}//export default


</script>