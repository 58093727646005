/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM937P
 * 화면 설명: 보험계약기본사항 > 계약정보 >  유지/유의정보 > 부활보험료 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.14
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="부활보험료" :topButton="true"><!-- type-popup -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst pb150">
        <p class="fs19rem mt16 pb20 fwb">
          <!-- 상품명 (계약번호) -->
          {{ prdtNm }} ({{ markUpContNo }})
        </p>
        <!-- 부활보험료 start -->
        <div class="table-area">
          <table class="table col-type">
            <colgroup>
              <col width="50%" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th class="txt-left pl15">부활가능일자</th>
                <td v-if="!isNoData" class="txt-left pl15">
                  {{ rnstPrmVO.rnstPsbMxDt }} 까지
                </td>
              </tr>
              <tr>
                <th class="txt-left pl15">부활횟수</th>
                <td v-if="!isNoData" class="txt-left pl15">
                  {{ rnstPrmVO.rnstMmsVl }} 회
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 부활보험료 end -->

        <!-- 부활보험료 계산내역 start -->
        <div class="gray-info-bar ty--2 mt30">
          <ul class="detail-con-li">
            <li class="mb4">
              <span class="fs16rem fwm">부활보험료 계산내역</span>
              <span class="ns-tool-tip ml10">
                  <mo-icon id="location_MSPPM937P" icon="msp-tool-tip"></mo-icon>
                  <mo-tooltip target-id="location_MSPPM937P" class="tool-tip-box">
                    <p>
                      ※ 연체일수는 실효일자-부활예정일자 전일까지의 경과일수이며, 회차별 누적연체일수는 미납입 각 회차별 연체일수를 누적, 합산한 일수입니다. 
                    </p>
                    <p>
                      ※ 부활예정일자가 미래일자인 경우, 금리연동형보험의 연체이자는 조회일 현재의 이율을 기준으로 계산되며, 갱신특약이 부가된 경우 부활예정일자가 갱신일자 이후인 경우에는 해당 특약의 갱신보험료가 산출되기 이전에는 갱신전 보험료를 기준으로 계산됩니다.<br>
                    </p>
                    <p>
                      ※ 특별계정 상품(연금저축/변액 등)의 경우, 부활예정일자가 계약해당일 전이면 연체보험료에 당월분 보험료는 제외되어 있습니다.
                    </p>
                  </mo-tooltip>                  
              </span>  
            </li>
            <li><span class="fs14rem">부활예정일자</span> <span class="fs16rem ml10">{{ rnstPrmVO.rnstParngDt }}</span></li>
          </ul>
        </div>

        <template v-if="!isNoData">
          <!-- 부활보험료 합계 -->      
          <ul class="lst lst--5 mt10">
            <li>
              <span class="tit">총부활보험료</span>
              <span class="txt txt-right">{{ rnstPrmSmtot.rnstPrmAm }}</span>
            </li>
            <li>
              <span class="tit">총할인금액</span>
              <span class="txt txt-right">{{ rnstPrmSmtot.discAmtAm }}</span>
            </li>
            <li>
              <span class="tit">총연체보험료</span>
              <span class="txt txt-right">{{ rnstPrmSmtot.arrPrmAm }}</span>
            </li>
            <li>
              <span class="tit">총연체이자</span>
              <span class="txt txt-right">{{ rnstPrmSmtot.arrIntAm }}</span>
            </li>
          </ul> 

          <template v-for="(item, index) in rnstPrmCalcBrkdnVO">
            <!-- 보험명 -->
            <span :key="'title_'+index" class="fs18rem fwm mb10 mt30">{{ item.elementprodTt }} </span>
            <ul :key="'content_'+index" class="lst lst--2">
              <li>
                <span class="tit">부활보험료</span>
                <span class="txt txt-right">{{ item.rnstPrmAm }}</span>
              </li>
              <li>
                <span class="tit">할인금액</span>
                <span class="txt txt-right">{{ item.discAmtAm }}</span>
              </li>
              <li>
                <span class="tit">연체보험료</span>
                <span class="txt txt-right">{{ item.arrPrmAm }}</span>
              </li>
              <li>
                <span class="tit">연체이자</span>
                <span class="txt txt-right">{{ item.arrIntAm }}</span>
              </li>
              <!-- <li>
                <span class="tit">보험코드</span>
                <span class="txt txt-right">{{ item.coltrCd }}/{{ item.rmvlInscdCd }}</span>
              </li> --> 
              <li>
                <span class="tit">연체일수</span>
                <span class="txt txt-right">{{ item.arrDdsVl }} 일</span>
              </li>
              <li>
                <span class="tit">보험료</span>
                <span class="txt txt-right">{{ item.mtotPrmAm }}</span>
              </li>
            </ul>
          </template>
        </template>
        <template v-else>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="no-data expand_topline">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info hauto pt20 pb20">
                  <span :isConfirm="isConfirm">{{ serviceMsg }}</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
        </template>
        <!-- 부활보험료 계산내역 end -->

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM937P', 
  screenId: 'MSPPM937P', 
  components: {},//componets,

  props:{
    pPage: String,          // 부모 페이지명
    pContNo: String,        // 계약번호 
    pMarkUpContNo: String,  // 마스킹된 계약번호
    pPrdtNm: String,        // 상품명
    pContrNm: String,       // 계약자명 
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  
  data () {
    return {
      lv_InputParam: {},              // 서비스호출 파라미터 

      lv_ReturnVal: {},               // 리턴값
      isReturn: false,                // 리턴값 세팅여부

      /**
       * 화면 변수 
       */
      isNoData: false,
      serviceMsg:' 데이터가 존재하지 않습니다.', // 서비스 실행 후 출력되는 메시지 
      
      /**
       *  props 변수 
       */
      contNo: '',                     // 계약번호
      markUpContNo: '',               // 마스킹된 계약번호
      prdtNm: '',                     // 상품명
      contrNm: '',                    // 계약자명 

      /**
       * 조회 변수
       */ 
      rnstPrmVO: {},                  // 부활보험료 데이터 (부활가능일자, 부활횟수)
      rnstPrmCalcBrkdnVO: [],         // 부활보험료 데이터 (부활보험료 계산내역)

      // 부활보험료 전체 합계 데이터 (부활보험료 계산내역)
      rnstPrmSmtot: {mtotPrmAm: 0,discAmtAm: 0, arrPrmAm: 0, arrIntAm: 0, rnstPrmAm: 0},               

    }
  },//data

  created () {

    this.contNo       = this.pContNo
    this.markUpContNo = this.pMarkUpContNo
    this.prdtNm       = this.pPrdtNm
    this.contrNm      = this.pContrNm

    this.fn_Submit()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {

    /**
     * 서비스 실행 중 알람 뜨면 확인 버튼 클릭 후, 팝업 닫는다.
     */
    isConfirm(){
      if(!this.isConfirm) this.$emit('closePopup')
    }

  },//watch

  computed: {

    /**
     * 서비스 알림 노출여부를 리턴한다.
     */
    isConfirm(){
      let isShow = this.getStore('confirm').getters.getState.isShow
      return isShow
   }

  },//computed

  methods: {
               
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
   fn_Submit(){

    // 조회조건 세팅
    this.lv_InputParam.applnrTt    = this.contNo
    this.lv_InputParam.calcStndDt  = this.$pmUtil.getCurrentDate()

    // 서비스 호출 
    this.fn_GetService()

   },
         
    /**
     * @description 부활보험료 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth    = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
          eaiId: this.$pmUtil.getSVCId('F1FPC0192'),
          fahrTrnId: 'S',
          requestSystemCode: 'F1341',
          reqSrvcNm: '',
          reqMthdNm: '',
          targetSystemCode: '',
          resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0192VO',
          reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0192VO'
       }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
        
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      if(response.body){
        if (response.body.tmessagesObj) {

          this.serviceMsg = response.body.tmessagesObj[0].message
          this.getStore('confirm').dispatch('ADD', this.serviceMsg)

          // 데이터 없음 
          this.isNoData = true
          
          return
        }

        let responseVO   = response.body.zsfpcre001
        let responseList = response.body.zsfpcre002
        
        // 부활가능일자, 부활횟수
        if (responseVO !== null) {

          // 데이터 가공
          responseVO.rnstPsbMxDt = this.$commonUtil.dateDashFormat(responseVO.rnstPsbMxDt) 
          responseVO.rnstParngDt = this.$pmUtil.getCurrentDate("YYYY-MM-DD")           

          // 데이터 저장 
          this.rnstPrmVO = responseVO

        }

        // 부활보험료 계산내역 
        if (responseList) {
          
          let currencyId = (!this.$bizUtil.isEmpty(responseList[0].currencyId)) ? responseList[0].currencyId : '' // 외화보험 통화키 설정

          // 데이터 가공 
          responseList.forEach((item) => {

            // 부활보험료 합계 계산
            this.rnstPrmSmtot.mtotPrmAm += Number(item.mtotPrmAm)
            this.rnstPrmSmtot.discAmtAm += Number(item.discAmtAm)
            this.rnstPrmSmtot.arrPrmAm  += Number(item.arrPrmAm)
            this.rnstPrmSmtot.arrIntAm  += Number(item.arrIntAm)
            this.rnstPrmSmtot.rnstPrmAm += Number(item.rnstPrmAm)

            // 외환보험일 경우 $로 표시 
            item.rnstPrmAm = this.$bizUtil.numberWithCommasCurr(item.rnstPrmAm, item.currencyId, 'Y', 'Y', 3)  
            item.mtotPrmAm = this.$bizUtil.numberWithCommasCurr(item.mtotPrmAm, item.currencyId, 'Y', 'Y', 3)  
            item.discAmtAm = this.$bizUtil.numberWithCommasCurr(item.discAmtAm, item.currencyId, 'Y', 'Y', 3)  
            item.arrPrmAm  = this.$bizUtil.numberWithCommasCurr(item.arrPrmAm, item.currencyId, 'Y', 'Y', 3)   
            item.arrIntAm  = this.$bizUtil.numberWithCommasCurr(item.arrIntAm, item.currencyId, 'Y', 'Y', 3)  

            // 데이터 저장 
            this.rnstPrmCalcBrkdnVO.push(item)
            
          })

          // 부활보험료 합계 외환보험일 경우 $로 표시 
          this.rnstPrmSmtot.rnstPrmAm = this.$bizUtil.numberWithCommasCurr(this.rnstPrmSmtot.rnstPrmAm, currencyId, 'Y', 'Y', 3)  
          this.rnstPrmSmtot.mtotPrmAm = this.$bizUtil.numberWithCommasCurr(this.rnstPrmSmtot.mtotPrmAm, currencyId, 'Y', 'Y', 3)  
          this.rnstPrmSmtot.discAmtAm = this.$bizUtil.numberWithCommasCurr(this.rnstPrmSmtot.discAmtAm, currencyId, 'Y', 'Y', 3)  
          this.rnstPrmSmtot.arrPrmAm  = this.$bizUtil.numberWithCommasCurr(this.rnstPrmSmtot.arrPrmAm, currencyId, 'Y', 'Y', 3)   
          this.rnstPrmSmtot.arrIntAm  = this.$bizUtil.numberWithCommasCurr(this.rnstPrmSmtot.arrIntAm, currencyId, 'Y', 'Y', 3)  

        }else{
          // 데이터 없음
          this.isNoData = true
        }
      }else{
        // 데이터 없음
        this.isNoData = true
      }
    },

 },// methods

}//export default


</script>