/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM966D
 * 화면 설명: 보험계약기본사항 > 처리이력 >  변경내역
 *           1) 1차개발 그대로 옮김(퍼블은 2차개발로 변경)    
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.21
 * 작 성 자: 서영화
 */
<template>
   <ur-page-container title="변경내역" :show-title="false" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area expand_topline">
        <ur-box-container v-if="!lv_Chg.init" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list">
          <mo-list :list-data="lv_Chg.mccontMatrChgBrkdnVO">
            <template #list-item="{item}">
              <mo-list-item  expandable prevent-expand>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <!-- 변경처리일 -->
                    <span class="fs19rem fwb">{{ $commonUtil.dateDashFormat(item.chgTrtYmd) }}</span>
                  </div>
                  <div class="list-item__contents__info pr0">
                    <div class="info_box mt10">
                      <div class="full">
                        <span class="label-title mr10">변경유형</span>
                        <span>{{ item.contChgCdNm }}</span>
                      </div>
                      <div class="full">
                        <span class="label-title mr10">취소구분</span>
                        <span>{{ item.cancScCdNm }}</span>
                      </div>
                      <div class="full">
                        <!-- 변경신청일 -->
                        <span class="label-title mr10">신청일</span>
                        <span>{{ $commonUtil.dateDashFormat(item.chgAplYmd) }}</span>
                        <em></em>
                        <!-- 변경접수일 -->
                        <span class="label-title mr10">접수일</span>
                        <span>{{ $commonUtil.dateDashFormat(item.acpnYmd) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                <div class="list-item-detail">
                  <div class="contents-row flex">
                    <span class="fs14rem crTy-bk7 min80">변경차액</span>
                    <span class="crTy-bk1">{{ $commonUtil.numberWithCommas(item.chgDfamt.amount) }}</span>
                  </div>
                  <div class="contents-row flex">
                    <span class="fs14rem crTy-bk7 min80">차액방법</span>
                    <span class="crTy-bk1">{{ item.chgDfamtPadMthCdNm }}</span>
                  </div>
                  <div class="contents-row flex">
                    <span class="fs14rem crTy-bk7 min80">접수유형</span>
                    <span class="crTy-bk1">{{ item.custTypCdKraNm }}</span>
                  </div>
                  <div class="contents-row flex">
                    <span class="fs14rem crTy-bk7 min80">신청부서</span>
                    <span class="crTy-bk1">{{ item.aplDeptNm }}</span>
                  </div>
                  <div class="contents-row flex">
                    <!-- 신청자(사번) -->
                    <span class="fs14rem crTy-bk7 min80">신청자</span>
                    <span class="crTy-bk1">{{ item.aplrNm }}({{ item.aplrEno }})</span>
                  </div>
                  <div class="contents-row flex">
                    <span class="fs14rem crTy-bk7 min80">납입사항</span>
                    <span class="crTy-bk1">{{ $commonUtil.dateDashFormat(item.ltpadYm) }}</span>
                  </div>
                  <div class="contents-row flex">
                    <span class="fs14rem crTy-bk7 min80">종납회수</span>
                    <span class="crTy-bk1">{{ item.ltpadNts }}회</span>
                  </div>
                </div>
              </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <!-- 데이터 없음 -->
        <ur-box-container  v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data"><!-- expand_bottomline -->
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>

     </ur-box-container>
   </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM966D', 
  screenId: 'MSPPM966D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '변경내역',

      contBasMatrVO: {},    // 계약메인데이터(부모데이터) 
      contNo: '',           // 계약번호 

      lv_Chg: { // 변경내역
        bind: false,
        init: false,
        mccontMatrChgBrkdnVO: []
      },

      crncyCd: ' ', // 외화보험 통화코드 초기값
      isShowUsd: false
    }
  },//data

  created () {},//created

  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
    /**
     * Function명 : fn_ChgData
     * 설명       : 변경내역 데이터 조회
     * @return {Promise} - fn_OnCLickAccordion에서 finally를 적용하기 위해
     */
    fn_ChgData () {
      if (this.lv_Chg.bind) return Promise.resolve()

      // 초기화 
      this.lv_Chg.mccontMatrChgBrkdnVO.splice(0)
      this.lv_Chg ={ bind: false, init: false, mccontMatrChgBrkdnVO: [] }
      this.crncyCd = ' ' // 외화보험 통화코드 초기값
      this.isShowUsd = false
      // 데이터 세팅 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo         = this.contBasMatrVO.contNo

      // 변경내역
      let inputDataVO = {}
      inputDataVO.contNo = this.contNo // 계약번호

      let trnstId = this.$pmUtil.getTrnstId()
      let auth = 'S'
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPC0089'),
        fahrTrnId: 'S1',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0089VO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0089VO'
      }
      return this.post(this, inputDataVO, trnstId, auth, commHeader)
        .then(this.fn_ChgDataHandler)
    },
    /**
     * Function명 : fn_ChgDataHandler
     * 설명       : 변경내역 데이터를 처리하여 화면에 반영
     * @param {Object} response - 변경내역 데이터 객체
     * @return {none} - 반환없음
     */
    fn_ChgDataHandler (response) {
      this.lv_Chg.bind = true
      if (response.body) {
        if (response.body.mccontMatrChgBrkdnVO !== null) {
          let responseList = response.body.mccontMatrChgBrkdnVO
          if (responseList.length > 0) {
            responseList.forEach((item) => {
              this.lv_Chg.mccontMatrChgBrkdnVO.push(item)
            })
          } else { this.lv_Chg.init = true }
        } else { this.lv_Chg.init = true }
      } else { this.lv_Chg.init = true }
    }
 },// methods

}//export default


</script>