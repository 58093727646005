/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM964D
 * 화면 설명: 보험계약기본사항 > 처리이력 >  월대체내역  
 *           1) 1차개발 그대로 옮김(퍼블은 2차개발로 변경)  
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.21
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container title="월대체내역" :show-title="false">
    <ur-box-container direction="column" alignV="start" class="msp">

      <!-- 상단고정 Tab UI -->
      <!-- <div class="ns-sliding-topbar"> -->
      <ur-box-container v-if="!lv_Mrp.init" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-area pb0 bgcolor-3">
        <template >
          <mo-list :list-data="lv_Mrp.ramntRplcPrmBrkdnListVO" class=" expand_topline">
            <template #list-item="{item}">
              <mo-list-item ref="expItem" class="ns-datail-accodian ty--2 mt0">
                <template class="pal0">
                  <div class="result-contents-box full">
                      <div class="contents-row timeline">
                        <!-- 월대체응당일자 -->
                        <!-- <strong class="timeline-data">{{ $commonUtil.dateDashFormat(item.mntRplcDueYmd) }}</strong> -->
                        <ul class="mt10">
                          <li>
                            <div class="title-cell w130"><strong>월대체 응당일자</strong></div> 
                            <div class="value-cell"><span>{{ $commonUtil.dateDashFormat(item.mntRplcDueYmd) }}</span></div>
                          </li> 
                          <li>
                            <div class="title-cell w130"><strong>월대체 완성일자</strong></div> 
                            <div class="value-cell"><span>{{ $commonUtil.dateDashFormat(item.mntRplcTrtYmd) }}</span></div>
                          </li> 
                          <li>
                            <div class="title-cell w130"><strong>월대체 처리여부</strong></div> 
                            <div class="value-cell">
                              <span class="crTy-blue3" v-if="item.mntRplcTrtYn==='X'">Y</span>
                              <span class="crTy-orange2" v-else>N</span>
                            </div>
                          </li> 
                          <li>
                            <div class="title-cell w130"><strong>월대체 보험료</strong></div> 
                            <div class="value-cell"><span>{{ $commonUtil.numberWithCommas(item.mntRplcPrm.amount) }}</span></div>
                          </li> 
                        </ul>
                      </div>
                    </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </template>
      </ur-box-container>

      <!-- 데이터 없음 -->
      <template v-else>
        <!-- NoData 영역 start -->
        <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 end -->
      </template>
      
      <!-- </div> -->
      <!-- 상단고정 Tab UI //-->
    </ur-box-container>
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM964D', 
  screenId: 'MSPPM964D', 
  components: {    
  },//componets,

  props:{
  },//props

  data () {
    return {
      title: '월대체내역',

      contBasMatrVO: {},    // 계약메인데이터(부모데이터) 
      contNo: '',           // 계약번호 

      lv_Mrp: { // 월대체내역
        bind: false,
        init: false,
        ramntRplcPrmBrkdnListVO: []
      },

      crncyCd: ' ', // 외화보험 통화코드 초기값
      isShowUsd: false
    }
  },//data

  created () {},//created

  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
    /**
     * Function명 : fn_MrpData
     * 설명       : 월대체내역 데이터 조회
     * @return {Promise} - fn_OnCLickAccordion에서 finally를 적용하기 위해
     */
    fn_MrpData () {
      if (this.lv_Mrp.bind) return Promise.resolve()

      // 초기화 
      this.lv_Mrp.ramntRplcPrmBrkdnListVO.splice(0)
      this.lv_Mrp ={ bind: false, init: false, ramntRplcPrmBrkdnListVO: [] }
      this.crncyCd = ' ' // 외화보험 통화코드 초기값
      this.isShowUsd = false
      // 데이터 세팅 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo         = this.contBasMatrVO.contNo

      // 월대체내역
      let inputData = {}
      inputData.contNo = this.contNo // 계약번호
      let trnstId = this.$pmUtil.getTrnstId()
      let auth = 'S'
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPX0013'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: 'sli.co',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPX0013VO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPX0013VO'
      }
      return this.post(this, inputData, trnstId, auth, commHeader)
        .then(this.fn_MrpDataHandler)
    },
    /**
     * Function명 : fn_MrpDataHandler
     * 설명       : 월대체내역 데이터를 처리하여 화면에 반영
     * @param {Object} response - 월대체내역 데이터 객체
     * @return {none} - 반환없음
     */
    fn_MrpDataHandler (response) {
      this.lv_Mrp.bind = true
      if (response.body) {
        if (response.body.ramntRplcPrmBrkdnListVO !== null) {
          let responseList = response.body.ramntRplcPrmBrkdnListVO
          if (responseList.length > 0) {
            responseList.forEach((item) => {
              this.lv_Mrp.ramntRplcPrmBrkdnListVO.push(item)
            })
          } else { this.lv_Mrp.init = true }
        } else { this.lv_Mrp.init = true }
      } else { this.lv_Mrp.init = true }
    },
 },// methods

}//export default


</script>