/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM936P
 * 화면 설명: 보험계약기본사항 > 계약정보 > 유지/유의정보 > 자동이체 세부정보 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.14
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="자동이체 세부정보" :topButton="true"><!-- type-popup -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst">
        <p class="fs19rem mt16 pb30 fwb">
          <!-- 상품명 (계약번호) -->
          {{ prdtNm }} ({{ markUpContNo }})
        </p>
        <!-- 보험료자동이체 start -->
        <span class="fs18rem fwm mb10">보험료자동이체</span>
        <template v-if="!isNoDataType1">
          <ul class="lst lst--2">
            <li>
              <span class="tit">이체계좌정보</span>
              <span class="txt">{{ pmgatrMatrBasVO.bnkNm01 }} {{ pmgatrMatrBasVO.accnNo01 }}</span>
            </li>
            <li>
              <span class="tit">예금주</span>
              <span class="txt">{{ pmgatrMatrBasVO.dpstrNm01 }}</span>
            </li> 
            <li>
              <span class="tit">이체일</span>
              <span class="txt">{{ pmgatrMatrBasVO.trsfHopDdCdNm01 }}</span>
            </li>
          </ul>        
        </template>
        <template v-else>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="no-data expand_topline expand_bottomline">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info hauto pt20 pb20"> <!-- 영역이 작은곳에서 활용 small-space -->
                <span class="ns-ftcr-gray"><p class="mt10">데이터가<br/>존재하지 않습니다.</p></span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        </template>
        <!-- 보험료자동이체 end -->

        <!-- 보험계약대출 자동이체 start -->
        <span class="fs18rem fwm mb10 mt50">보험계약대출 자동이체</span>
        <template v-if="!isNoDataType2">
          <ul class="lst lst--2">
            <li>
              <span class="tit">이체계좌정보</span>
              <span class="txt">{{ pmintrnAtrBndlAplListVO.bnkCd01 }} {{ pmintrnAtrBndlAplListVO.accnNo01 }}</span>
            </li>
            <li>
              <span class="tit">예금주</span>
              <span class="txt">{{ pmintrnAtrBndlAplListVO.dpstrNm }}</span>
            </li> 
            <li>
              <span class="tit">이체일</span>
              <span class="txt">{{ pmintrnAtrBndlAplListVO.trsfHopDdCdNm }}</span>
            </li>
          </ul>        
        </template>
        <template v-else>
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="no-data expand_topline expand_bottomline">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__info hauto pt20 pb20"> <!-- 영역이 작은곳에서 활용 small-space -->
                  <span class="ns-ftcr-gray"><p class="mt10">데이터가<br/>존재하지 않습니다.</p></span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
        </template>
        <!-- 보험계약대출 자동이체 end -->

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM936P', 
  screenId: 'MSPPM936P', 
  components: {},//componets,

  props:{
    pPage: String,          // 부모 페이지명
    pContNo: String,        // 계약번호 
    pMarkUpContNo: String,  // 마스킹된 계약번호
    pPrdtNm: String,        // 상품명
    pContrNm: String,       // 계약자명 
    pContRrn: String        // 계약자 주민번호 
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  
  data () {
    return {
      lv_InputParam: {},              // 서비스호출 파라미터 

      lv_ReturnVal: {},               // 리턴값
      isReturn: false,                // 리턴값 세팅여부 

      /**
       *  props 변수 
       */
      contNo: '',                     // 계약번호
      markUpContNo: '',               // 마스킹된 계약번호
      prdtNm: '',                     // 상품명
      contrNm: '',                    // 계약자명 
      contRrn: '',                    // 계약자 주민번호 

      /**
       * 화면 변수 
       */
      isNoDataType1: false,           // 보험료자동이체 데이터 여부
      isNoDataType2: false,           // 보험계약대출 자동이체 데이터 여부 

      /**
       * 조회 변수
       */ 
      pmgatrMatrBasVO: {},            // 보험료자동이체 데이터 
      pmintrnAtrBndlAplListVO: {},    // 보험계약대출 자동이체 데이터

    }
  },//data

  created () {

    this.contNo       = this.pContNo
    this.markUpContNo = this.pMarkUpContNo
    this.prdtNm       = this.pPrdtNm
    this.contrNm      = this.pContrNm
    this.contRrn      = this.pContRrn

    this.fn_Submit()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
          
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    * @param       {String} svcId 서비스ID
    */
   fn_Submit(svcId){
     
    // 조회조건 세팅
    this.lv_InputParam = {}

    // 보험계약대출 자동이체 
    if(svcId === 'S2'){  
      this.lv_InputParam.trsfObjCd   = '03'
      this.lv_InputParam.contrNm     = this.contrNm
      this.lv_InputParam.contrRrn    = this.contRrn
      this.lv_InputParam.fnctScCd    = 'N1'
      this.lv_InputParam.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: 1}]
    }
    // 보험료자동이체 
    else{ 
      this.lv_InputParam.contNo      = this.contNo
      this.lv_InputParam.inqrScCd    = '01'
    } 

    // 서비스 호출
    this.fn_GetService(svcId) 

   },
         
    /**
     * @description 약대정보 조회 서비스 호출한다.
     * @param       {String} svcId 서비스ID
     * @callback    fn_HandleCallback
     */
    fn_GetService(svcId) {

      const auth = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {}

      // 보험계약대출 자동이체 
      if(svcId === 'S2'){
        commHeader = {
          eaiId: this.$pmUtil.getSVCId('F1CLT0078'),
          fahrTrnId: 'S2',
          requestSystemCode: '',
          reqSrvcNm: '',
          reqMthdNm: '',
          targetSystemCode: '',
          reqVONm: 'sli.in.ea2.vo.a0001cl.EAF1CLT0078VO',
          resVONm: 'sli.in.ea2.vo.a0001cl.EAF1CLT0078VO'
        }
      }
      // 보험료자동이체 
      else{
        commHeader = {
          eaiId: this.$pmUtil.getSVCId('F1FPC0106'),
          fahrTrnId: 'S1',
          requestSystemCode: '',
          reqSrvcNm: '',
          reqMthdNm: '',
          targetSystemCode: '',
          reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0106VO',
          resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0106VO'
        }
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)
        
    },
        
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      let confirm = this.getStore('confirm')

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        confirm.dispatch('ADD', message)

        return
      }

      let svcId = response.trnstComm.commHeaderVO.fahrTrnId  // 해당하는 서비스 아이디
      
      switch(svcId){
        case 'S1': // 보험료자동이체

          // 데이터 저장
          this.pmgatrMatrBasVO = response.body.pmgatrMatrBasVO

          if(this.pmgatrMatrBasVO){
            if(this.pmgatrMatrBasVO.dpstrNm01  !== ' '){  
              this.isNoDataType1 = false
            }else{
              this.isNoDataType1 = true 
            }
          }else{
            this.isNoDataType1 = true 
          }

          // 보험계약대출 자동이체 조회 호출
          this.fn_Submit('S2')

          break
        case 'S2': // 보험계약대출 자동이체

          let responseVO = response.body.pmintrnAtrBndlAplListVO[0]

          if(responseVO && responseVO.bnkCd01){
            this.pmintrnAtrBndlAplListVO = responseVO
            this.isNoDataType2 = false 
          }else{
            this.isNoDataType2 = true 
          }


          break
      }
    }

 },// methods

}//export default


</script>