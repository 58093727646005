/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM934D
 * 화면 설명: 보험계약기본사항 > 계약정보 > 상품상세정보  
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.13
 * 작 성 자: 서영화
 */
<template>
 <!-- <ur-page-container class="msp sales-sys no-gnb" :show-title="false" type="popup" :topButton="false"> -->
  <div class="msp sales-sys no-gnb">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap con-area">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst">
        <div class="full fexTy3 mb10">
          <p class="fs22rem fwb">상품상세정보</p>
        </div>
        <ul class="lst lst--2">
          <li>
            <span class="tit w170px">태아가입가능</span>
            <span class="txt">{{ prdtDtlInfoVO.ubentPsbYnNm }}</span>
          </li>
          <li>
            <span class="tit w170px">미달체인수방법</span>
            <span class="txt">{{ prdtDtlInfoVO.imprdUndtkPsbMthCdNm }}</span>
          </li>
          <li>
            <span class="tit w170px">비과세구분</span>
            <span class="txt">{{ prdtDtlInfoVO.taxexPrdtScNm }}</span>
          </li>
          <li>
            <span class="tit w170px">주피변경구분</span>
            <span class="txt">{{ prdtDtlInfoVO.mnisdChgPsbScCdNm }}</span>
          </li>
          <li>
            <span class="tit w170px">개별전환가능</span>
            <span class="txt">{{ prdtDtlInfoVO.indvCnvrTrtPsbYnNm }}</span>
          </li>
          <li>
            <span class="tit w170px">계약전환유형</span>
            <span class="txt">{{ prdtDtlInfoVO.cnvrTypCdNm }}</span>
          </li>
          <li>
            <span class="tit w170px">감액일시납</span>
            <span class="txt">{{ prdtDtlInfoVO.rdctOnpadTrtPsbYnNm }}</span>
          </li>
          <li>
            <span class="tit w170px">보험료증감가능</span>
            <span class="txt">{{ prdtDtlInfoVO.incndChgPsbScCdNm }}</span>
          </li>
          <li>
            <span class="tit w170px">선납가능횟수</span>
            <span class="txt">{{ prdtDtlInfoVO.prpadPsbNts }}</span>
          </li>
          <li>
            <span class="tit w170px">UL류의무납입개월수</span>
            <span class="txt">{{ prdtDtlInfoVO.oblgPadMms }}</span>
          </li>
          <li>
            <span class="tit w170px">월대체보험료</span>
            <span class="txt">{{ prdtDtlInfoVO.mntRplcPrmOccuYnNm }}</span>
          </li>
          <li>
            <span class="tit w170px">추가납입</span>
            <span class="txt">{{ prdtDtlInfoVO.prmAddmPadPsbYn }}</span>
          </li>
          <li>
            <span class="tit w170px">자동대출가능</span>
            <span class="txt">{{ prdtDtlInfoVO.aplnPadPsbYnNm }}</span>
          </li>
          <li>
            <span class="tit w170px">약대가능구분</span>
            <span class="txt">{{ prdtDtlInfoVO.pllnPsbScCd }}</span>
          </li>
          <li>
            <span class="tit w170px">개인연금구분</span>
            <span class="txt">{{ prdtDtlInfoVO.panutTaxsyRsnabYnNm }}</span>
          </li>
          <li>
            <span class="tit w170px">조기연금신청</span>
            <span class="txt">{{ prdtDtlInfoVO.earlAnutPsbYnNm }}</span>
          </li>
        </ul>
      </ur-box-container>
      
    </ur-box-container>
    <!-- 컨텐츠영역  //-->
  </div>
 <!-- </ur-page-container> -->
</template>

<script>

import pmConstants from '@/config/constants/pmConstants'

export default {

  name: 'MSPPM934D', 
  screenId: 'MSPPM934D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      title: '상품상세정보',

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},        // 계약메인데이터(부모데이터) 
      contNo: '',               // 계약번호 

      lv_InputParam: {},        // 서비스호출 파라미터 

      /**
       * 조회 변수
       */ 
      prdtDtlInfoVO: {},        // 상품상세정보 데이터 

    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
       
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
   fn_Submit(){

    // 초기화 
    this.prdtDtlInfoVO = {}
    // 데이터 세팅
    this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
    this.contNo        = this.contBasMatrVO.contNo

    // 조회조건 세팅  
    this.lv_InputParam.contNo = this.contNo    

    // 서비스 호출
    this.fn_GetService() 
 
   },
      
    /**
     * @description 계약정보 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth    = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams    = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPC0124'),
        fahrTrnId: 'S1',
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0124ReqVO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0124ResVO'
      } 

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
    
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)

        return
      }

      let responseVO   = response.body
      let pllnPsbScCds = pmConstants.PRDT_DTL_INFO_PLLN_PSB_SC_CD_NM    // 약대가능구분코드(보험계약대출가능구분코드) object list 데이터 
      
      // 데이터 가공 
      responseVO.cnvrTypCdNm = (responseVO.cnvrTypCdNm === '#NA') ? '' : responseVO.cnvrTypCdNm   

      pllnPsbScCds.forEach((item) => {  // 약대가능구분
        if(item.value === responseVO.pllnPsbScCd) 
          responseVO.pllnPsbScCd = item.text 
      })                      
      
      // 데이터 저장 
      this.prdtDtlInfoVO = responseVO
    
    }
 },// methods

}//export default


</script>
<style lang="scss">
.mo-bottom-navigation {
  &.no-gnb {
    display: none;
  }
}