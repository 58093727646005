/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM952P
 * 화면 설명: 보험계약기본사항 > 미래예시 > 해약환급금 > 해약환급금 상세조회 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.28
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" title="해약환급금 상세조회"  :topButton="true"><!-- type-popup -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <!-- 이율 start -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst pb0">
        <ur-box-container alignV="start" componentid="" direction="column">
          <!-- <span class="fs18rem fwm mb5 mt30">이율</span> -->
          <div class=" mb5 mt30 fexTy7">
            <span class="fs18rem fwm0">이율</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt6">
           
            <!-- 이율 A type -->
            <mo-segment-wrapper v-if="targetIrt === 'A'" solid primary v-model="irtTypA" class="chip-type-wrap chip-ty1">
                <mo-segment-button v-for="(item, idx) in irtTypAs" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>

            <!-- 이율 B type -->
            <mo-segment-wrapper v-if="targetIrt === 'B'" solid primary v-model="irtTypB" class="chip-type-wrap chip-ty1">
                <mo-segment-button v-for="(item, idx) in irtTypBs" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>

          </ur-box-container>     

           <!-- 이율 B type : 예시이율 선택 시, 노출되는 문구 -->
          <span v-show="targetIrt === 'B' && irtTypB === irtTypBs[0].value"  class="mt10 fs12rem ml10 crTy-bk7">* 예시이율(미래예상수익률)은  {{chkCond.stnrdIrt.substr(0, chkCond.stnrdIrt.indexOf('.') + 3)}}%입니다.</span>    
        
        </ur-box-container>

        <!-- 이율 B type : 직접입력 선택 시, 노출되는 element -->
        <ur-box-container v-show="targetIrt === 'B' && irtTypB === irtTypBs[2].value " alignV="start" componentid="" direction="column" class="mt10">
          <div class="row-text full">
            <mo-text-field  class="form-input-name full input-clear-pr15" v-model="directIrt" min="0" max="100" @keyup="fn_ProcessIrt" clearable underline placeholder="이율을 입력해주세요." />
            <span class="right-text">%</span>
          </div>
        </ur-box-container>

      </ur-box-container>
      <!-- 이율 end -->

      <!-- 추가납입설계 start -->
      <ur-box-container alignV="start" componentid="" direction="column" class="pt30" >
        <div class="fexTy3 full pa12024">
          <span class="fs18rem fwm">추가납입설계</span>
          <mo-button :disabled="chkCond.addPadPsbFg !== 'Y'" class="btn_blueline--28px" @click="fn_CreateElement('addmPadPlan')" >추가</mo-button>
        </div>
        <div class="gray-tb-linebox">
          <template v-for="(item, index) in addmPadPlanForm" >
            <ul class="linebox-inbox" v-if="!item.deleted" :key="index">
              <li>
                <div class="fexTy3 mb10">
                  <span class="crTy-bk7">보험료</span>
                  <mo-icon v-if="index > 0" icon="close" class="fs16rem mb6 crTy-bk7" @click="fn_RemoveElement(item)">close</mo-icon>
                </div>
                <div class="row-text">
                  <mo-text-field :disabled="chkCond.addPadPsbFg !== 'Y'" v-model="item.prcPrdemiumAm" @keyup="fn_ProcessPremium(item, 'addmPadPlan')" clearable
                                  class="form-input-name full input-clear-pr15" underline placeholder="보험료를 입력해주세요." />
                  <span class="right-text">원</span>
                </div>
              </li>
              <li class="mt20">
                <span class="crTy-bk7">시작연월/종료연월</span>
                <div class="calendar-box">
                  <mo-month-picker :disabled="chkCond.addPadPsbFg !== 'Y'" v-model="item.strYm" ref="addmPadPlanStrYm" placeholder="YYYY-MM" />
                  <mo-month-picker :disabled="chkCond.addPadPsbFg !== 'Y'" v-model="item.endYm" ref="addmPadPlanEndYm" placeholder="YYYY-MM" />
                </div>
              </li>
            </ul>
          </template>
        </div>
      </ur-box-container>
      <!-- 추가납입설계 end -->

      <!-- 중도인출설계 start -->
      <ur-box-container alignV="start" componentid="" direction="column" class="pt30 pb80">
        <div class="fexTy3 full pa12024">
          <span class="fs18rem fwm">중도인출설계</span>
          <mo-button :disabled="chkCond.mdrotProdFg !== 'Y'" class="btn_blueline--28px" @click="fn_CreateElement('midDrotPlan')">추가</mo-button>
        </div>
        <div class="gray-tb-linebox">
          <template v-for="(item, index) in midDrotPlanForm" >
            <ul class="linebox-inbox" v-if="!item.deleted" :key="index">
              <li>
                <div class="fexTy3 mb10">
                  <span class="crTy-bk7">보험료</span>
                  <mo-icon v-if="index > 0" icon="close" class="fs16rem mb6 crTy-bk7" @click="fn_RemoveElement(item)">close</mo-icon>
                </div>
                <div class="row-text">
                  <mo-text-field :disabled="chkCond.mdrotProdFg !== 'Y'" v-model="item.prcMdrotAmt" @keyup="fn_ProcessPremium(item, 'midDrotPlan')"  clearable 
                                 class="form-input-name full input-clear-pr15" underline placeholder="보험료를 입력해주세요." />
                  <span class="right-text">원</span>
                </div>
              </li>
              <li class="mt20">
                <span class="crTy-bk7">발생연월</span>
                <div class="calendar-box">
                  <mo-month-picker :disabled="chkCond.mdrotProdFg !== 'Y'" v-model="item.ogtYm" ref="midDrotPlanOgtYm" placeholder="YYYY-MM" class="full"/>
                </div>
              </li>
            </ul>    
          </template>      
        </div>
      </ur-box-container>
      <!-- 중도인출설계 end -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button :disabled="chkCond.prsvAcumCd === '1' && chkCond.mdrotProdFg === 'N' && chkCond.addPadPsbFg === 'N' && chkCond.prtnIlstScCd === ' '"
                      componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickClear">초기화</mo-button>
          <mo-button :disabled="chkCond.prsvAcumCd === '1' && chkCond.mdrotProdFg === 'N' && chkCond.addPadPsbFg === 'N' && chkCond.prtnIlstScCd === ' '" 
                      componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickSearch">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

import pmConstants from '@/config/constants/pmConstants' 


export default {

  name: 'MSPPM952P', 
  screenId: 'MSPPM952P', 
  components: {},//componets,

  props:{
    pPage: String,          // 부모 페이지명
    pChkCond: {             // 상세조회 조건 
      type: Object,
      default: {}
    },
    pIrtTypA: String,       // 이율(Type A) 선택값
    pIrtTypB: String,       // 이율(Type B) 선택값
    pDirectIrt: String,     // 직접입력 입력값
    pAddmPadPlanCond: {     // 추가납입설계 조건
      type: Object,
      default: {}
    },
    pAddmPadPlanForm: {     // 추가납입설계 조건 list
      type: Array,
      default: []
    },
    pMidDrotPlanCond: {     // 중도인출설계 조건
      type: Object,
      default: {}
    }, 
    pMidDrotPlanForm: {     // 중도인출설계 조건 list
      type: Array,
      default: []
    },
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  
  data () {
    return {

      lv_ReturnVal: {},               // 리턴값
      isReturn: false,                // 리턴값 세팅여부

      /**
       * 조회 변수 
       */
      chkCond: {},

      targetIrt: '',                  // 이율 타입

      irtTypAs: [],                   // 이율(Type A)
      irtTypA: '',
      irtTypBs: [],                   // 이율(Type B)
      irtTypB: '',
      directIrt: '',                  // 직접이율 입력값

      addmPadPlanCond: {},             // 추가납입설계 조건 데이터 
      midDrotPlanCond:{},              // 중도인출설계 조건 데이터 
      addmPadPlanForm: [],             // 추가납입설게 [추가] element 리스트
      midDrotPlanForm: [],             // 중도인출설계 [추가] element 리스트

    }
  },//data

  created () {

    // 조회조건 데이터 세팅 
    this.chkCond = this.pChkCond

    // 이율 타입 세팅
    if(this.chkCond.prsvAcumCd === '2' || this.chkCond.prsvAcumCd === '3'){
      this.targetIrt = 'A'
    }else if(this.chkCond.prsvAcumCd === '4' || this.chkCond.prsvAcumCd === '5'){ 
      this.targetIrt = 'B'
    }

    this.irtTypAs  = pmConstants.SRDVL_IRT_TYP_A_CD     // 이율(type A) CODE-LIST
    this.irtTypA   = this.pIrtTypA                      // 선택된 이율(type A) value
    this.irtTypBs  = pmConstants.SRDVL_IRT_TYP_B_CD     // 이율(type B) CODE-LIST
    this.irtTypB   = this.pIrtTypB                      // 선택된 이율(type B) value
    this.directIrt = this.pDirectIrt                    // 직접입력 입력값

    // 추가납입설계 [추가] 기본값(최초값:필수) 설정 
    this.addmPadPlanCond = Object.assign({}, this.pAddmPadPlanCond)
    this.addmPadPlanForm = JSON.parse(JSON.stringify(this.pAddmPadPlanForm)) // deep clone 
    if(this.addmPadPlanForm.length === 0) this.addmPadPlanForm.push(this.addmPadPlanCond)

    // 중도인출설계 [추가] 기본값(최초값:필수) 설정 
    this.midDrotPlanCond = Object.assign({}, this.pMidDrotPlanCond)
    this.midDrotPlanForm = JSON.parse(JSON.stringify(this.pMidDrotPlanForm)) // deep clone 
    if(this.midDrotPlanForm.length === 0) this.midDrotPlanForm.push(this.midDrotPlanCond)


  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {

    /**
     * @description [추가] element 생성한다.
     * @param       {String} type [추가] element 유형 
     */
    fn_CreateElement(type){

      if(type === 'addmPadPlan'){
        let tempObj = {premiumAm: '', prcPrdemiumAm: '', strYm: ['',''], endYm: ['',''], deleted: false}
        this.addmPadPlanForm.push(tempObj)
      }else if(type === 'midDrotPlan'){
        let tempObj = {mdrotAmt: '', prcMdrotAmt: '', ogtYm: ['',''], deleted: false}
        this.midDrotPlanForm.push(tempObj)
      }
      
    },
    
    /**
     * @description [추가] element 제거한다.
     * @param       {Object} item 제거될 [추가] element 데이터 
     */
    fn_RemoveElement(item){
      item.deleted = true 
    },

    /**
     * @description 이율(Type B) '직접입력' 이율 제어한다.
     *              - 0 ~ 100 까지 입력할 수 있다.
     *              - 소수점 3번째자리까지 입력할 수 있다.
     */
    fn_ProcessIrt(){

      let regex = /[^.0-9]/g
      
      this.directIrt = this.directIrt.replace(regex, '').replace(/(..*)/g, '$1')

      // 소수점 3번째까지 입력가능
      this.directIrt = this.directIrt.substr(0, this.directIrt.indexOf('.') + 4)

      // 100 이상이면 마지막 자릿수 제거 
      if(Number(this.directIrt) < 0 || Number(this.directIrt) > 100 ){
        this.directIrt = this.directIrt.substr(0, this.directIrt.length-1)
      }

    },

    /**
     * @description 보험료 제어한다.
     *              - 숫자만 입력 가능하다.
     *              - 천단위 콤마(,) 추가
     * @param       {Object} item 해지환급금 데이터 
     * @param       {String} type  조건 유형 ( 추가납입설계, 중도인출설계 )
     */
    fn_ProcessPremium(item, type){

      let regex = /[^0-9]/g

      if(type === 'addmPadPlan'){

        // 보험료 저장 
        item.premiumAm = item.prcPrdemiumAm.replace(regex, '').replace(/(..*)\./g, '$1')

        // 0만 입력하면 첫번째 값 이후 0은 제거 
        if(item.premiumAm.length > 1 && item.premiumAm.charAt(0) === '0') {
          item.premiumAm     = item.prcPrdemiumAm.substr(0, item.premiumAm.length-1)
          item.prcPrdemiumAm = item.premiumAm
        }else{
          // 천단위 콤마로 변환 
          item.prcPrdemiumAm = this.$bizUtil.numberWithCommasByNumber(new String(item.premiumAm.replace(',','')))
        }

      }else if(type === 'midDrotPlan'){

        // 보험료 저장 
        item.mdrotAmt = item.prcMdrotAmt.replace(regex, '').replace(/(..*)\./g, '$1')

        // 0만 입력하면 첫번째 값 이후 0은 제거 
        if(item.mdrotAmt.length > 1 && item.mdrotAmt.charAt(0) === '0') {
          item.mdrotAmt = item.prcMdrotAmt.substr(0, item.premiumAm.length-1)
          item.prcMdrotAmt = item.mdrotAmt
        }else{
          // 천단위 콤마로 변환 
          item.prcMdrotAmt = this.$bizUtil.numberWithCommasByNumber(new String(item.mdrotAmt.replace(',','')))
        }

      }
  
    },
    
    /**
     * @description 선택 및 입력된 조건값을 초기 조건값으로 초기화 한다.
     */
    fn_ClickClear(){
      
      this.irtTypA   = this.irtTypAs[0].value       
      this.irtTypB   = this.irtTypBs[0].value          
      this.directIrt = ''                             

      // 추가납입설계 초기화 
      this.addmPadPlanForm.splice(0)
      this.addmPadPlanCond = {premiumAm: '', prcPrdemiumAm: '', strYm: ['',''], endYm: ['',''], deleted: false }
      this.$refs['addmPadPlanStrYm'][0].onClickClear()
      this.$refs['addmPadPlanEndYm'][0].onClickClear()
      this.addmPadPlanForm.push(this.addmPadPlanCond)

      // 중도인출설계 초기화
      this.midDrotPlanForm.splice(0)
      this.midDrotPlanCond = { mdrotAmt: '', prcMdrotAmt: '', ogtYm: ['',''], deleted: false}
      this.$refs['midDrotPlanOgtYm'][0].onClickClear()
      this.midDrotPlanForm.push(this.midDrotPlanCond)

    },
            
    /**
     * @description 부모화면에 전달할 리턴 데이터를 세팅해준다.
     */
    fn_SetReturnVal () {
            
      // 초기화 
      this.lv_ReturnVal = {} 
      
      // 리턴값 설정
      this.lv_ReturnVal.irtTypA   = this.irtTypA
      this.lv_ReturnVal.irtTypB   = this.irtTypB
      this.lv_ReturnVal.directIrt = this.directIrt

      this.lv_ReturnVal.addmPadPlanCond = this.addmPadPlanForm[0]
      this.lv_ReturnVal.addmPadPlanForm = this.addmPadPlanForm
      this.lv_ReturnVal.midDrotPlanCond = this.midDrotPlanForm[0]
      this.lv_ReturnVal.midDrotPlanForm = this.midDrotPlanForm

      // 리턴값 세팅여부 
      this.isReturn = true 

    },

    /**
     * @description 데이터 유효성을 검사한다.
     */
    fn_CheckValidation () {

      // 추가납입설계 유효성 검사
      for(let i = 0; i < this.addmPadPlanForm.length; i++){

        let item = this.addmPadPlanForm[i]

        // 보험료 X , 시작연월/종료연월 O
        if((item.strYm[0] !== '' && item.strYm[1] !== '') || (item.endYm[0] !== '' && item.endYm[1] !== '')){
          if(item.premiumAm === ''){
            this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC012) // [추가납입설계]보험료는 필수입력 값 입니다.
            return false
          }
        }

        // 보험료 O, 시작연월/ X
        if(item.premiumAm !== ''){
          if(item.strYm[0] === '' || item.strYm[1] === ''){
            this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC013) // [추가납입설계] 시작연월은 필수입력 값 입니다.
            return false
          }
          if(item.endYm[0] === '' || item.endYm[1] === ''){
            this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC014) // [추가납입설계] 종료연월은 필수입력 값 입니다.
            return false
          }
        }

        // 시작연월/종료연월 체크 
        if((item.strYm[0] !== '' && item.strYm[1] !== '') || (item.endYm[0] !== '' && item.endYm[1] !== '')){
          let tempStrYm = item.strYm[0] + item.strYm[1]
          let tempEndYm = item.endYm[0] + item.endYm[1]
          
          if(Number(item.strYm[1]) < 10) tempStrYm = item.strYm[0] + '0' + item.strYm[1]
          if(Number(item.endYm[1]) < 10) tempEndYm = item.endYm[0] + '0' + item.endYm[1]

          // 시작일자 > 종료일자 
          if(tempStrYm > tempEndYm){
            this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC015) // 종료일자는 시작일자보다 작을 수 없습니다. 다시 입력해주세요.
            return false
          }
        } 

      }

      // 중도인출설계 유효성 검사 
      for(let i = 0; i < this.midDrotPlanForm.length; i++){

        let item = this.midDrotPlanForm[i]

        // 보험료 X , 발생연월 O
        if(item.ogtYm[0] !== '' && item.ogtYm[1] !== ''){
          if(item.mdrotAmt === ''){
            this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC016) // [중도인출설계]보험료는 필수입력 값 입니다.
            return false
          }
        }

        // 보험료 O, 발생연월 X
        if(item.mdrotAmt !== ''){
          if(item.ogtYm[0] == '' || item.ogtYm[1] == ''){
            this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC017) // [중도인출설계]발생연월은 필수입력 값 입니다.
            return false
          }
        }

      }

      // 이율(Type B) 직접입력
      if(this.targetIrt === 'B' && this.irtTypB === this.irtTypBs[2].value){

        let count = 0

        // 직접입력 : 입력하지 않은 경우 
        if(this.directIrt === ''){
          this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC018) // 이율 직접입력 시 예시이율의 2배까지 입력하세요.
          return false
        }

        // 소수점 여부 체크 
        for(let i = 0; i < this.directIrt.length; i++){
          if(this.directIrt[i] === '.') count++
        }

        // 소수점이 2개 이상일 경우
        if(count > 1) {
          this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC018) // 이율 직접입력 시 예시이율의 2배까지 입력하세요.
          return false
        }

        // 예시이율보다 작을 경우 
        if(this.chkCond.stnrdIrt * 2 < this.directIrt){
          this.getStore('confirm').dispatch('ADD', this.$t('pm').EPMC018) // 이율 직접입력 시 예시이율의 2배까지 입력하세요.
          return false
        }

        // 소수점 앞에 0이없으면, 0 붙여주기. ex) '.123' -> '0.123'
        if (this.directIrt.indexOf('.') === 0) {
          this.directIrt = '0' + this.directIrt
        }

      }

      return true 
    },
    
    /**
     * @description 리턴 데이터 세팅 후, 팝업을 닫는다.
     */
    fn_ClickSearch(){

      // 유효성 검사 
      if(!this.fn_CheckValidation()) return

      // 리턴값 세팅
      this.fn_SetReturnVal() 

      if (this.isReturn) {
        this.$emit('searchPopup', { rtnData: this.lv_ReturnVal });
      }
      
    }

 },// methods

}//export default


</script>