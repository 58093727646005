/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM962D
 * 화면 설명: 보험계약기본사항 > 지급확인 > 중도인출금
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.20
 * 작 성 자: 서영화
 */
<template>
 <ur-page-container class="msp" :show-title="false" :topButton="false">
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      
      <template  v-if="isNoContData && isNoAtmData">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </template>
      <template v-else>
        <!-- 계약 정보 start -->
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst mt30 pb0"> 
          <ul class="lst lst--2">
            <li class="">
              <span class="tit">계약상태</span>
              <span class="txt" v-if="!isNoContData">{{ midDrotAmtVO.contStatNm }}</span>
            </li>
            <li class="">
              <span class="tit">납입사항/상태</span>
              <span class="txt" v-if="!isNoContData">{{ midDrotAmtVO.padMatrStat }}</span>
            </li>
            <li class="">
              <span class="tit">계약일자</span>
              <span class="txt" v-if="!isNoContData">{{ midDrotAmtVO.contYmd }}</span>
            </li>
            <li class="">
              <span class="tit">인출주기</span>
              <span class="txt" v-if="!isNoContData">{{ midDrotAmtVO.mdrotCyl }} 개월 {{ midDrotAmtVO.mdrotPsbNts }} 회</span>
            </li>
            <li class="">
              <span class="tit">인출가능일</span>
              <span class="txt" v-if="!isNoContData">{{ midDrotAmtVO.drotPsbYmd }}</span>
            </li>
            <li class="">
              <span class="tit">최소적립액</span>
              <span class="txt" v-if="!isNoAtmData">{{ midDrotAmtVO.minAcumAmt}}</span>
            </li>
            <li class="">
              <span class="tit">인출가능한도</span>
              <span class="txt" v-if="!isNoContData">{{ midDrotAmtVO.mdrotLmtRat }}%</span>
            </li>
            <!-- 일단, 주석처리 -->
            <!-- <li class="">
              <span class="tit">지급예정일</span>
              <span class="txt" v-if="!isNoContData">데어터 모름</span>
            </li> -->
          </ul>        
        </ur-box-container>
        <!-- 계약정보 end -->
      
        <template v-if="!isNoAtmData">
          <!-- 인출가능금액 계산 start -->
          <ur-box-container  alignV="start" componentid="" direction="column" class="msp-detail-lst pb0 mt50">
            <div class="fexTy1 mb10">
              <span class="fs18rem fwm">인출가능금액 계산</span>
              <span class="ns-tool-tip ml10 msp_vertical--tb">
                <mo-icon id="location_MSPPM962D" icon="msp-tool-tip"></mo-icon>
                <mo-tooltip target-id="location_MSPPM962D" class="tool-tip-box">
                  <p>
                    중도인출은 보험계약과 달리 적립액을 영구히 인출하는 것으로 인출 후 취소 또는 상환이 절대 불가합니다. (당일포함)
                  </p>
                  <p>
                    중도인출 후 해지환급금과 사망보험금은 감소하며 인출금액외에 인출수수료가 적립액에서 자동차감되어 지급됩니다.<br>
                    (계약해당일 기준 연4회까지는 수수료 면제)
                  </p>
                  <p>
                    월대체상품의 경우 과도한 인출 시 적립액 부족으로 계약이 조기에 해지될 수 있습니다.
                  </p>
                  <p>
                    변액·조회일자 기준가로 적용된 가능금액이며, 신청일에는 금액이 변동될 수 있습니다.
                  </p>
                </mo-tooltip>                  
              </span>
            </div>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
              <mo-segment-wrapper solid primary v-model="dropPsbAmtCalc" class="chip-ty1">
                <mo-segment-button v-for="(item, idx) in dropPsbAmtCalcs" :value="item.value" :key="idx" @click="fn_Submit(item.value, false)">{{ item.text }}</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </ur-box-container>
          <!-- 인출가능금액 계산 end -->
          
          <!-- radio 버튼 영역 -->
          <ur-box-container class="area-bb flex-sc mt30">
            <div class="flex-col-se f-end">
              <div class="wrap-btn">
                <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
                  <mo-radio value="1" small>목록</mo-radio>
                  <mo-radio value="2" small>표</mo-radio>
                </mo-radio-wrapper>
                <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open">크게보기</mo-button>
              </div>
            </div>
          </ur-box-container>

          <!-- 인출가능금액 계산 합계 start -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area pb-0">

            <!-- 표 모드 -->
            <ur-box-container v-if="radioButton==='2'" class="sticky-pd">
              <div class="sticky-area">
                <div class="table-area sticky-t05" ref="tableArea" @scroll="fn_checkScroll(1)">
                  <table class="table row-type ws_N">
                    <thead>
                      <tr>
                        <th>주계약환급금</th>
                        <th>대출원리금</th>
                        <th>기본적립액</th>
                        <th>추가적립액</th>
                        <th>최소적립액</th>
                        <th>인출가능금액</th>
                        <th>인출수수료</th>
                        <th>최소상환금</th>
                        <th>실지급액</th>
                        <th>인출후환급금</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in midDrotAmtVO.bpmdrotPsbAmtListVO" :key="i">
                        <td class="aR"> {{item.prcMnContRspnPrsv}} </td>
                        <td class="aR"> {{item.prcPllnAmt}} </td>
                        <td class="aR"> {{item.prcMnContBasAcumAmt}} </td>
                        <td class="aR"> {{item.prcMnContAddmAcumAmt}} </td>
                        <td class="aR"> {{item.prcMnContLwstLmtAmt}} </td>
                        <td class="aR"> {{item.prcMnContDrotPsbAmt}} </td>
                        <td class="aR"> {{item.prcDrotComs}} </td>
                        <td class="aR"> {{item.prcMinRpyAmt}} </td>
                        <td class="aR"> {{item.prcRealPymAmt}} </td>
                        <td class="aR"> {{item.prcDrotAftRefnd}} </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2"> 합계 </td>
                        <td colspan="3"></td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totDrotPsbAmt}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totDrotComs}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totMinRpyAmt}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totRealPymAmt}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totDrotAftRefnd}} </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
                <button class="icon-down2 pos-fixed" @click="fn_scrollToDown(1)" ref="iconDown" v-show="isShowChevronDown"><i></i></button>
              </div>
            </ur-box-container>
            
            <!-- 목록 모드 -->
            <ur-box-container v-if="radioButton==='1'" alignV="start" componentid="" direction="column">
              <ur-box-container alignV="start" componentid="" direction="column" class="msp-detail-lst pb0 mt50">
                <div class="fexTy3 full mb10">
                  <span class="fs18rem fwm">인출가능금액 계산합계</span>
                  <span class="fs14rem crTy-bk7">(단위:원)</span>
                </div>
                <div class="table-area">
                  <table class="table row-type">
                  <colgroup>
                      <col width="33.3333%" />
                      <col width="33.3333%" />
                      <col width="33.3333%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>인출가능금액<br/>(인출수수료)</th>
                        <th>실지급액<br/>(최소상환금)</th>
                        <th>인출후환급금</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ dropPsbAmtCalcSmtot.totDrotPsbAmt }}<br>  ({{ dropPsbAmtCalcSmtot.totDrotComs }})</td>
                        <td>{{ dropPsbAmtCalcSmtot.totRealPymAmt }}<br> ({{ dropPsbAmtCalcSmtot.totMinRpyAmt }})</td>
                        <td>{{ dropPsbAmtCalcSmtot.totDrotAftRefnd}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ur-box-container>
              <!-- 인출가능금액 계산 합계 end -->
          
              <!-- 인출가능금액 계산상세 start -->
              <span class="fs18rem mt50 pal1224 fwm">인출가능금액 계산상세</span>
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area expand_topline">
              <!-- topButton 때문에 가려져서 pb150 추가 -->
                <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list pb150">
                  <mo-list :list-data="midDrotAmtVO.bpmdrotPsbAmtListVO">
                    <template #list-item="{item}">
                      <mo-list-item  expandable prevent-expand>
                        <div class="list-item__contents">
                          <div class="list-item__contents__info pr0">
                            <div class="info_box mt10">
                              <div class="full">
                                <span class="label-title mr10">가능금액</span> 
                                <span class="ls--15">{{ item.prcMnContDrotPsbAmt }}</span>
                              </div>
                              <div class="full">
                                <span class="label-title mr10">실지급액</span>
                                <span class="ls--15">{{ item.prcRealPymAmt }}</span>
                              </div>
                              <div class="full">
                                <span class="label-title mr10">인출후환급</span>
                                <span>{{ item.prcDrotAftRefnd }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- 아코디언 컨텐츠 -->
                        <template v-slot:expand>
                        <div class="list-item-detail">
                          <div class="contents-row fexTy3">
                            <span class="fs14rem crTy-bk7">주계약환급금</span>
                            <span class="ml20 crTy-bk1">{{ item.prcMnContRspnPrsv }}</span>
                          </div>
                          <div class="contents-row fexTy3">
                            <span class="fs14rem crTy-bk7">대출원리금</span>
                            <span class="ml20 crTy-bk1">{{ item.prcPllnAmt }}</span>
                          </div>
                          <div class="contents-row fexTy3">
                            <span class="fs14rem crTy-bk7">기본적립액</span>
                            <span class="ml20 crTy-bk1">{{ item.prcMnContBasAcumAmt }}</span>
                          </div>
                          <div class="contents-row fexTy3">
                            <span class="fs14rem crTy-bk7">최소적립액</span>
                            <span class="ml20 crTy-bk1">{{ item.prcMnContLwstLmtAmt }}</span>
                          </div>
                          <div class="contents-row fexTy3">
                            <span class="fs14rem crTy-bk7">인출수수료</span>
                            <span class="ml20 crTy-bk1">{{ item.prcDrotComs }}</span>
                          </div>
                          <div class="contents-row fexTy3">
                            <span class="fs14rem crTy-bk7">최소상환금</span>
                            <span class="ml20 crTy-bk1">{{ item.prcMinRpyAmt }}</span>
                          </div>
                        </div>
                      </template>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </ur-box-container>
              </ur-box-container>
            </ur-box-container>
          </ur-box-container>
          <!-- 인출가능금액 계산상세 end -->
        </template>
      </template>

      <!-- bottomSheet -->
      <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet" :class="isNoContData && isNoAtmData ? '' : 'closebtn'">
        <div class="ns-btn-close" @click="fn_CloseBottomSheet"></div>
        <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment">{{ serviceMsg }}</p>
        </div>
        <template v-slot:action>
          <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_CloseBottomSheet" class="ns-btn-round blue ">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>      
      
      <!-- 크게보기 모드 -->
      <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
        <mo-top-bar>
          <mo-button class="btn_popup_back"></mo-button>
          <div slot="nav" class="icon-wrapper" @click="fn_close">
            <mo-icon color="white" borderless>drawer</mo-icon>
          </div>
          <div class="ui-gnb-title__main">분할보험금</div>
        </mo-top-bar>
        <!-- content 영역 -->
        <div class="content-area">
          <ur-box-container alignV="start" direction="column" class="ns-list-area">
            <ur-box-container>
              <div class="sticky-area">
                <div class="table-area sticky-t05" ref="tableAreaPop" @scroll="fn_checkScroll(2)">
                  <table class="table row-type ws_N">
                    <thead>
                      <tr>
                        <th>주계약환급금</th>
                        <th>대출원리금</th>
                        <th>기본적립액</th>
                        <th>추가적립액</th>
                        <th>최소적립액</th>
                        <th>인출가능금액</th>
                        <th>인출수수료</th>
                        <th>최소상환금</th>
                        <th>실지급액</th>
                        <th>인출후환급금</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in midDrotAmtVO.bpmdrotPsbAmtListVO" :key="i">
                        <td class="aR"> {{item.prcMnContRspnPrsv}} </td>
                        <td class="aR"> {{item.prcPllnAmt}} </td>
                        <td class="aR"> {{item.prcMnContBasAcumAmt}} </td>
                        <td class="aR"> {{item.prcMnContAddmAcumAmt}} </td>
                        <td class="aR"> {{item.prcMnContLwstLmtAmt}} </td>
                        <td class="aR"> {{item.prcMnContDrotPsbAmt}} </td>
                        <td class="aR"> {{item.prcDrotComs}} </td>
                        <td class="aR"> {{item.prcMinRpyAmt}} </td>
                        <td class="aR"> {{item.prcRealPymAmt}} </td>
                        <td class="aR"> {{item.prcDrotAftRefnd}} </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2"> 합계 </td>
                        <td colspan="3"></td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totDrotPsbAmt}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totDrotComs}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totMinRpyAmt}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totRealPymAmt}} </td>
                        <td class="aR"> {{dropPsbAmtCalcSmtot.totDrotAftRefnd}} </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRightPop" v-if="isShowChevron2"><i></i></button>
                <button class="icon-down" @click="fn_scrollToDown(2)" ref="iconDownPop" v-if="isShowChevronDown2"><i></i></button>
              </div>
            </ur-box-container>
          </ur-box-container>
        </div>
      </mo-bottom-sheet>
    </ur-box-container>
    
 </ur-page-container>
</template>

<script>

import pmConstants from '@/config/constants/pmConstants' 

export default {

  name: 'MSPPM962D', 
  screenId: 'MSPPM962D', 
  components: {},//componets,

  props:{},//props
  
  data () {
    return {
      title: '중도인출금',
      
      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},      // 계약메인데이터(부모데이터) 
      contNo: '',             // 계약번호 

      lv_InputParam: {},      // 서비스호출 파라미터

      /**
       * 화면 변수
       */ 
      isNoContData: false,        // 계약정보 데이터 여부    
      isNoAtmData: false,         // 인출가능금액 계산 데이터 여부 
      isSearched: false,
      serviceMsg: '',             // 서비스 실행 후 출력되는 메시지 
      radioButton: '2',           // radio 버튼 값
      isShowChevron: false,       // affordance 가로 버튼 노출여부
      isShowChevronDown: false,   // affordance 세로 버튼 노출여부
      isShowChevron2: false,      // 크게보기 affordance 버튼 노출여부
      isShowChevronDown2: false,  // 크게보기 affordance 버튼 노출여부
      firstChevron:true,
      /**
       * 조회 변수
       */ 
      midDrotAmtVO: {},       // 중도인출금 데이터 
      dropPsbAmtCalcSmtot: {},// 인출가능금액 계산 합계
      dropPsbAmtCalcs: [],    // 인출가능금액 계산 CODE-LIST 데이터 
      dropPsbAmtCalc: '',     // 인출가능금액 계산 데이터 

    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
          
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    * @param       {String}  val    선택된 인출가능금액 계산 데이터 value 
    * @param       {Boolean} isInit true: 최초조회, false: 버튼조회
    */
    fn_Submit(val, isInit){

      // 초기화
      if(isInit){ 
        // 검색이력 없을 때만, 인출가능금액 계산 데이터 호출 
        this.dropPsbAmtCalcs   = pmConstants.PYM_CNF_DROT_PSB_AMT_CALC_CD
        this.dropPsbAmtCalc    = this.dropPsbAmtCalcs[0].value 
      }

      // 중도인출금 데이터 
      this.midDrotAmtVO        = {} 
      // 인출가능금액 계산 합계
      this.dropPsbAmtCalcSmtot = {totDrotPsbAmt: 0, totDrotComs: 0, totMinRpyAmt: 0, totRealPymAmt: 0, totDrotAftRefnd: 0} 
      this.serviceMsg          = ''

      // 데이터 세팅 
      this.contBasMatrVO  = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
      this.contNo         = this.contBasMatrVO.contNo

      // 조회조건 세팅  
      this.lv_InputParam.contNo    = this.contNo                          
      this.lv_InputParam.pymCondCd = (val) ? val : this.dropPsbAmtCalc    // 선택된 인출가능금액 계산

      // 조회 시, affordance 버튼 미출력
      this.isShowChevron = false   
      this.isShowChevronDown = false

      // 서비스 호출 
      this.fn_GetService()

    },
       
    /**
     * @description 중도인출금 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth    = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPO0047'),
        fahrTrnId: 'S1',
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0047VO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPO0047VO'
      }

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
        
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      let confirm = this.getStore('confirm')

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        confirm.dispatch('ADD', msgcomm.msgDesc)
        
        // 데이터 없음
        this.isNoContData   = true 
        this.isNoAtmData    = true 
        this.serviceMsg     = msgcomm.msgDesc
        this.$refs.nsbottomsheet.open()
        return
      }

      // 데이터 가공
      let responseVO   = response.body
      let responseList = response.body.bpmdrotPsbAmtListVO

      responseVO.padMatrStat  = this.$commonUtil.dateDashFormat(responseVO.ltpadYm) + "/" + responseVO.ltpadNts + "회/" + responseVO.padStatCdNm // 납입사항/상태
      responseVO.contYmd      = this.$commonUtil.dateDashFormat(responseVO.contYmd)                                                              // 계약일자                                                          
      responseVO.drotPsbYmd   = this.$commonUtil.dateDashFormat(responseVO.drotPsbYmd)                                                           // 인출가능일자       

      if(responseList && responseList.length > 0){
      
        responseVO.minAcumAmt   = (responseList[0].mnContLwstLmtAmt.doubleAmount) ? 
                                  this.$commonUtil.numberWithCommas(responseList[0].mnContLwstLmtAmt.doubleAmount) : ''                         

          // 인출가능금액 계산 내역  
          responseList.forEach((item) => {
      
            // 인출가능금액 계산합계 계산 
            this.dropPsbAmtCalcSmtot.totDrotPsbAmt   += Number(item.mnContDrotPsbAmt.doubleAmount) // 인출가능금액
            this.dropPsbAmtCalcSmtot.totDrotComs     += Number(item.drotComs.doubleAmount)         // 인출수수료
            this.dropPsbAmtCalcSmtot.totMinRpyAmt    += Number(item.minRpyAmt.doubleAmount)        // 최소상환금
            this.dropPsbAmtCalcSmtot.totRealPymAmt   += Number(item.realPymAmt.doubleAmount)       // 실지급액
            this.dropPsbAmtCalcSmtot.totDrotAftRefnd += Number(item.drotAftRefnd.doubleAmount)     // 인출후환급금
            
            // 인출가능금액 계산상세 가공
            item.prcMnContDrotPsbAmt  = this.$commonUtil.numberWithCommas(item.mnContDrotPsbAmt.doubleAmount) // 기능금액(=인출가능금액)
            item.prcRealPymAmt        = this.$commonUtil.numberWithCommas(item.realPymAmt.doubleAmount)       // 실지급액
            item.prcDrotAftRefnd      = this.$commonUtil.numberWithCommas(item.drotAftRefnd.doubleAmount)     // 인출후환급금
            item.prcMnContRspnPrsv    = this.$commonUtil.numberWithCommas(item.mnContRspnPrsv.doubleAmount)   // 주계약환급금
            item.prcPllnAmt           = this.$commonUtil.numberWithCommas(item.pllnAmt.doubleAmount)          // 대출원리금
            item.prcMnContBasAcumAmt  = this.$commonUtil.numberWithCommas(item.mnContBasAcumAmt.doubleAmount) // 기본적립액
            item.prcMnContLwstLmtAmt  = this.$commonUtil.numberWithCommas(item.mnContLwstLmtAmt.doubleAmount) // 최소적립액
            item.prcDrotComs          = this.$commonUtil.numberWithCommas(item.drotComs.doubleAmount)         // 인출수수료
            item.prcMinRpyAmt         = this.$commonUtil.numberWithCommas(item.minRpyAmt.doubleAmount)        // 최소상환금
            item.prcMnContAddmAcumAmt = this.$commonUtil.numberWithCommas(item.mnContAddmAcumAmt.doubleAmount)// 추가적립액      

          })

          // 인출가능금액 계산합계 가공
          for(let key in this.dropPsbAmtCalcSmtot){

            this.dropPsbAmtCalcSmtot[key] = this.$commonUtil.numberWithCommas(this.dropPsbAmtCalcSmtot[key])

            // 1회가능금액일 경우만 화면에 노출 
            if(key === 'totDrotAftRefnd' && !(this.dropPsbAmtCalc === this.dropPsbAmtCalcs[0].value)) 
              this.dropPsbAmtCalcSmtot[key] = ''

          }

        this.isNoAtmData = false
      }else{
        this.isNoAtmData = true 
      }

      // 데이터 저장
      this.midDrotAmtVO = response.body
      
      this.isSearched   = true 
      this.isNoContData = true 

      // affordance 버튼 제어
      if(this.firstChevron) {
        this.$nextTick(() => {
          if(this.midDrotAmtVO.bpmdrotPsbAmtListVO.length > 0){
            this.isShowChevronDown = true
            this.isShowChevron = true
          } else{
            this.isShowChevronDown = false
            this.isShowChevron = false
          }
        })
        this.firstChevron = false
      }

      // 안내내역 존재시 메시지 보여준다
      if (responseVO.gudnBrkdn.trim() !== '') {

        this.serviceMsg = responseVO.gudnBrkdn
        this.$refs.nsbottomsheet.open()
        
        return
      }

      if (responseList) {
        if (responseList.length > 0 ) {
          if ( Number(responseList[0].realPymAmt) < 0 ) {

            // 계산된 실지급금액이 0 보다 적습니다.
            this.serviceMsg = this.$t('pm').EPMC007
            this.$refs.nsbottomsheet.open()

            return
          }
        }
      }

      if (responseVO.pymCondCd === '1') {
        
        if (responseVO.anutTaxsySptCd === '10') {
          // 종신형연금보험 - 과세계약 전환여부를 \n\n 중도인출 지급화면에서 확인 후 금액 안내바랍니다.
          this.serviceMsg = this.$t('pm').EPMC011
          this.$refs.nsbottomsheet.open()
          return
        }

        if (responseVO.vainsInsrYn === 'Y') { // 변액/신변액 팝업처리
          // 변액-조회일자 기준가로 적용된 가능금액이며,\n\n 신청일에는 금액 변동될 수 있습니다.
          this.serviceMsg = this.$t('pm').EPMC008
          this.$refs.nsbottomsheet.open()
          return
        }
      }

      if (responseVO.vainsInsrYn === 'Y') { // 변액/신변액 팝업처리
        if (responseVO.pymCondCd === '2') {

          if (responseVO.nwVainsYn === 'Y') {
            // 2008/04 이후 판매된 변액상품은 \n\n 1일1회 중도인출 가능합니다.
            this.serviceMsg = this.$t('pm').EPMC009
            this.$refs.nsbottomsheet.open()
            return
          } 

          if ( Number(this.dropPsbAmtCalcSmtot.totMinRpyAmt) > 0 ) {
            // 변액-최초 최소상환발생시까지 조회 및 처리 가능.
            this.serviceMsg = this.$t('pm').EPMC010
            this.$refs.nsbottomsheet.open()
            return
          }

        }
      }
      
      // 계약정보 존재 
      this.isNoContData = false         
    },
    
    /**
     * @description BottomSheet를 닫는다.
     */
    fn_CloseBottomSheet(){
      this.$refs.nsbottomsheet.close()
    },

    /**
    * @description 크게보기 오픈.
    */
    fn_open() { 
      this.$refs.nsbottomsheet2.open()

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.midDrotAmtVO.bpmdrotPsbAmtListVO.length > 0){
          this.isShowChevronDown2 = true
          this.isShowChevron2 = true
        }else{
          this.isShowChevronDown2 = false
          this.isShowChevron2 = false
        }
      })

    },

   /**
    * @description 크게보기 닫기.
    */
    fn_close() { this.$refs.nsbottomsheet2.close() },

   /**
    * @description 표 모드 스크롤 이벤트.
    */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {        // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
      }
    },

   /**
    * @description Right affordance 버튼 이벤트.
    */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea;
      } else {
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        tableArea = this.$refs.tableAreaPop;
      }
      const scrollAmount = 200;
      const scrollDuration = 1000;
      let startingPosition = tableArea.scrollLeft;
      let targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
     },

   /**
    * @description Down affordance 버튼 이벤트.
    */
    fn_scrollToDown(value) {
      if(value === 1) {                 // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        this.$emit('scrollDown')
      } else {                          // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      }
    },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },
    
    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now()
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime
        const progress = Math.min(elapsedTime / duration, 1)
        const easedProgress = this.easeInOutCubic(progress)
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate)
          
        }
      }
      animate(startTime)
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1
    },

    /**
     * @description tab 이동 시, affordance 버튼 출력 이벤트.
     */
    fn_ShowChervon() {
      this.firstChevron =true
    }

 },// methods

}//export default


</script>