/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM941D
 * 화면 설명: 보험계약기본사항 > 변액정보 > 적립액변동내역
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.22
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">

      <ur-box-container class="area-bb pt10">
        <div class="flex-sc">
          <div></div>
          <div class="fexTy5">
            <span class="fs14rem crTy-bk7 fwn mr10">합계만 보기</span>
            <mo-switch v-model="isSmtot" small class="ns-switch"/>
          </div>
        </div>
        <div class="flex-sc">
          <div class="txt-sum">총 <strong>{{ totalCount }}</strong> 건</div>
          <div class="flex-col-se" v-if="!isNoData">
            <div class="wrap-btn">
              <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
                <mo-radio value="1" small>목록</mo-radio>
                <mo-radio value="2" small>표</mo-radio>
              </mo-radio-wrapper>
              <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open">크게보기</mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- topButton 때문에 가려져서 pb150 추가 -->
      <ur-box-container v-if="!isNoData" alignV="start" componentid="" direction="column" class="ns-list-area pb-0">       
        <!-- 표 모드 -->
        <ur-box-container v-if="!isSmtot && radioButton==='2'" class="sticky-pd">
          <div class="sticky-area">
            <div class="table-area sticky-t04" ref="tableArea" @scroll="fn_checkScroll(1)">
              <table class="table row-type ws_N">
                <thead>
                  <tr>
                    <th>기준일자</th>
                    <th>펀드명</th>
                    <th>적립액비율(%)</th>
                    <th>좌수</th>
                    <th>적립액내역</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- 갱신예정일, 납입보험료, 갱신보험료, 보험별갱신보험료 -->
                  <tr v-for="(item, i) in slmntAcumAmtFlctBrkdnInfoVO" :key="i">
                    <td class="ws_Y"> {{item.prcStndYmd}} </td>
                    <td class="ws_Y aL"> {{item.fndNm}} </td>
                    <td class="aR"> {{item.fndEntryRto}} </td>
                    <td class="aR"> {{item.fndShrs}} </td>
                    <td class="aR"> {{item.acumAmtAmount}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
            <button class="icon-down2 pos-fixed" @click="fn_scrollToDown(1)" ref="iconDown" v-show="isShowChevronDown"><i></i></button>
          </div>
        </ur-box-container>

        <!-- 목록 모드 -->
        <ur-box-container v-if="!isSmtot && radioButton==='1'"  alignV="start" componentid="" direction="column" class="msp-detail-lst pt30">
          <div class="table-area">
            <table class="table row-type">
            <colgroup>
                <col width="33.333%" />
                <col width="33.333%" />
                <col width="33.333%" />
              </colgroup>
              <thead>
                <tr>
                  <th rowspan="2">기준일자</th>
                  <th>펀드명</th>
                  <th>적립액 비율(%)</th>
                </tr>
                <tr>
                  <th>좌수</th>
                  <th>적립액</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in slmntAcumAmtFlctBrkdnInfoVO" >
                  <tr :key="index+'1'">
                    <!-- 기준일자 -->
                    <td rowspan="2">{{ item.prcStndYmd }}</td>
                    <!-- 펀드명 -->
                    <td>{{ item.fndNm }}</td>
                    <!-- 적립액 비율 -->
                    <td>{{ item.fndEntryRto }}</td>
                  </tr>
                  <tr :key="index+'2'">
                    <!-- 좌수 -->
                    <td>{{ item.fndShrs }}</td>
                    <!-- 적립액내역 -->
                    <td class="">{{ item.acumAmtAmount }}</td>
                  </tr>
                  <template v-if="item.acumAmtSmtot != ''">
                      <tr :key="index+'3'">
                        <td colspan="2">합계</td>
                        <td class="crTy-blue2 fwb">{{ item.acumAmtSmtot }}</td>
                      </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </ur-box-container>

        <!-- 크게보기 모드 -->
        <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
          <mo-top-bar>
            <mo-button class="btn_popup_back"></mo-button>
            <div slot="nav" class="icon-wrapper" @click="fn_close">
              <mo-icon color="white" borderless>drawer</mo-icon>
            </div>
            <div class="ui-gnb-title__main">적립액변동내역</div>
          </mo-top-bar>
          <!-- content 영역 -->
          <div class="content-area">
            <ur-box-container alignV="start" direction="column" class="ns-list-area">
              <ur-box-container>
                <div class="sticky-area">
                  <div class="table-area sticky-t04" ref="tableAreaPop" @scroll="fn_checkScroll(2)">
                    <table class="table row-type ws_N">
                      <thead>
                      <tr>
                        <th>기준일자</th>
                        <th>펀드명</th>
                        <th>적립액비율(%)</th>
                        <th>좌수</th>
                        <th>적립액내역</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- 갱신예정일, 납입보험료, 갱신보험료, 보험별갱신보험료 -->
                      <tr v-for="(item, i) in slmntAcumAmtFlctBrkdnInfoVO" :key="i">
                        <td class="ws_Y"> {{item.prcStndYmd}} </td>
                        <td class="ws_Y aL"> {{item.fndNm}} </td>
                        <td class="aR"> {{item.fndEntryRto}} </td>
                        <td class="aR"> {{item.fndShrs}} </td>
                        <td class="aR"> {{item.acumAmtAmount}} </td>
                      </tr>
                    </tbody>
                    </table>
                  </div>
                  <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRight" v-show="isShowChevron2"><i></i></button>
                  <button class="icon-down" @click="fn_scrollToDown(2)" ref="iconDown" v-show="isShowChevronDown2"><i></i></button>
                </div>
              </ur-box-container>
            </ur-box-container>
          </div>
        </mo-bottom-sheet>

        <!-- 합계만보기 -->
        <div v-if="isSmtot" class="table-area">
          <table class="table row-type">
          <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th>기준일자</th>
                <th>적립액</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in slmntAcumAmtFlctBrkdnInfoVO" >
                <template v-if="item.acumAmtSmtot != ''">
                  <tr :key="index+'4'">
                  <!-- 기준일자 -->
                    <td>{{ item.prcStndYmd }}</td>
                    <td class="crTy-blue2 fwb">{{ item.acumAmtSmtot }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </ur-box-container>
    
    
  </ur-page-container>
</template>
<script>

export default {

  name: 'MSPPM941D', 
  screenId: 'MSPPM941D', 
  components: {},

  props:{},//props

  data () {
    return {
      title: '적립액변동내역',

      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},                // 계약메인데이터(MSPPM931M) 
      contNo: '',                       // 계약번호 

      lv_InputParam: {},                // 서비스호출 파라미터 

      /**
       * 화면 변수
       */ 
      isNoData: false,
      isSmtot: false,                   // 합계만보기 여부 
      totalCount: 0,                    // 전체 건수 
      radioButton: '2',                 // radio 버튼 값
      isShowChevron: false,             // 표 모드 affordance 버튼 제어
      isShowChevronDown: false,         // 표 모드 affordance 버튼 제어
      isShowChevron2: false,            // 크게보기 모드 affordance 버튼 제어
      isShowChevronDown2: false,        // 크게보기 모드 affordance 버튼 제어

      /**
       * 조회 변수
       */ 
      slmntAcumAmtFlctBrkdnInfoVO: []   // 적립액변동내역 데이터

    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {

    /**
     * 합계만 보기 선택여부를 저장한다.
     */
    isSmtot(val){
      this.isSmtot = val
    }

  },//watch

  computed: {},//computed

  methods: {
          
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
    fn_Submit(){

      // 초기화 
      this.totalCount = 0
      this.isNoData   = false 
      this.isSmtot    = false 
      this.slmntAcumAmtFlctBrkdnInfoVO.splice(0)

      // 데이터 세팅
      this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo // MSPPM931M 보험계약기본사항 데이터 
      this.contNo        = this.contBasMatrVO.contNo                         

      // 조회조건 세팅  
      this.lv_InputParam.contNo  = this.contNo                
      this.lv_InputParam.curYmd  = this.$pmUtil.getCurrentDate()        

      // 조회 시, affordance 버튼 미출력
      this.isShowChevron = false   
      this.isShowChevronDown = false
      
      // 서비스 호출 
      this.fn_GetService()

    },
      
    /**
     * @description 적립액변동내역 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth     = 'S'
      const trnstId  = this.$pmUtil.getTrnstId()

      let pParams    = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPF0009'),
        fahrTrnId: 'S',
        requestSystemCode: 'F1341',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0009VO',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPF0009VO'
      } 

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)

    },
       
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {

        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)

        // 데이터 없음 
        this.isNoData = true

        return
      }
  
      let responseList = response.body.slmntAcumAmtFlctBrkdnInfoVO

      if (responseList) {

        let prevYmd      = responseList[0].stndYmd     // 이전 데이터의 기준일자 
        let acumAmtSmtot = 0                           // 적립액 합계(기존일자별)

        // 기준일자별로 데이터 합계를 구한다.
        responseList.forEach((item, index) => {
          
          this.$set(responseList[index], 'acumAmtSmtot', '') // 데이터 추가 

          let prcAcumAmtAmount =  item.acumAmt.amount.substr(0, item.acumAmt.amount.indexOf('.'))

          if(item.stndYmd != prevYmd){

            // 기준일자별 적립액 최초값 추가 
            let idx = index === 0 ? index : index - 1
            this.$set(responseList[idx], 'acumAmtSmtot', this.$commonUtil.numberWithCommas(Number(acumAmtSmtot))) 

            acumAmtSmtot = Number(prcAcumAmtAmount)
            prevYmd      =  item.stndYmd

          }else{

            // 기준일자별 적립액 더하기
            acumAmtSmtot += Number(prcAcumAmtAmount)

          }

          // 마지막 데이터는 무조건 적립액 합계를 추가한다.
          if(responseList.length === index + 1){
            this.$set(responseList[index], 'acumAmtSmtot', this.$commonUtil.numberWithCommas(Number(acumAmtSmtot))) 
          }

          item.prcStndYmd       = this.$commonUtil.dateDashFormat(item.stndYmd)              
          item.fndShrs          = this.$commonUtil.numberWithCommas(Number(item.fndShrs))     
          item.acumAmtAmount    = this.$commonUtil.numberWithCommas(Number(prcAcumAmtAmount)) 
  
          // 데이터 저장
          this.slmntAcumAmtFlctBrkdnInfoVO.push(item)
  
        })

        // 전체 건수 저장 
        this.totalCount = this.slmntAcumAmtFlctBrkdnInfoVO.length

        // 데이터 유무 
        if (this.slmntAcumAmtFlctBrkdnInfoVO.length === 0) {
          this.isNoData = true    
        } else {
          this.isNoData = false  
        }

      } else { 
        this.isNoData = true 
      }

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.slmntAcumAmtFlctBrkdnInfoVO.length > 0){
            this.isShowChevronDown = true
            this.isShowChevron = true
        } else {
          this.isShowChevronDown = false
          this.isShowChevron = false
        }
      })
    },

    fn_open() {
      this.$refs.nsbottomsheet2.open();

      // affordance 버튼 제어(Y축 스크롤 생길 경우만 down 버튼 노출)
      this.$nextTick(() => {
        if(this.slmntAcumAmtFlctBrkdnInfoVO.length > 0){
          this.isShowChevronDown2 = true
          this.isShowChevron2 = true
        }else{
          this.isShowChevronDown2 = false
          this.isShowChevron2 = false
        }
      })
    },

    fn_close() {
      this.$refs.nsbottomsheet2.close();
    },

   /**
    * @description 스크롤 이벤트.
    */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {                  // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
      }
    
    },

   /**
    * @description Right affordance 버튼 이벤트.
    */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {                     // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea;
      } else {                              // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        tableArea = this.$refs.tableAreaPop
      }
      const scrollAmount = 200;
      const scrollDuration = 1000;
      let startingPosition = tableArea.scrollLeft;
      let targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
     },

   /**
    * @description 표 모드 Down affordance 버튼 이벤트.
    */
    fn_scrollToDown(value) {
      if(value === 1) {                 // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        this.$emit('scrollDown')
      } else {                          // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      }
    },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },

    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = this.easeInOutCubic(progress);
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress;
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress;
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
          
        }
      };
      animate(startTime);
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1;
    },
 },// methods

}//export default


</script>