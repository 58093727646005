/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM935D
 * 화면 설명: 보험계약기본사항 > 계약정보 > 유지/유의정보  
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.09
 * 작 성 자: 서영화
 */
<template>
 <!-- <ur-page-container class="msp sales-sys" :show-title="false" :topButton="false"> -->
   <div class="msp sales-sys">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap con-area">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst pb50">
        <p class="fs22rem pb12 fwb">유지정보</p>
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="row" class="insert-area jjh-ty1 pal0 mb10">
          <mo-button class="ns-btn-round white" @click="fn_OpenMSPPM909M">약대정보</mo-button>
          <mo-button class="ns-btn-round white" @click="fn_OpenMSPPM936P">자동이체정보</mo-button>
          <mo-button class="ns-btn-round white" @click="fn_OpenMSPPM937P">부활보험료</mo-button>
        </ur-box-container>
        <ul class="lst lst--2">
          <li>
            <span class="tit w140px">납입보험료(원)</span>
            <span class="txt">{{ contInfoVO.prcPadPrm }}</span>
          </li>
          <li>
            <span class="tit w140px">계약보험료(원)</span>
            <span class="txt">{{ contInfoVO.prcContPrm }}</span>
          </li>
          <li>
            <span class="tit w140px">주보험료납입기간</span>
            <span class="txt">{{ contInfoVO.mnInsrPadPrd }}</span>
          </li>
          <li>
            <span class="tit w140px">납입주기</span>
            <span class="txt">{{ contInfoVO.padCylCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">납입상태</span>
            <span class="txt">{{ contInfoVO.padStatCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">납입방법</span>
            <span class="txt">{{ contInfoVO.padMthCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">이체일</span>
            <span class="txt">{{ contInfoVO.trsfDd }}</span>
          </li>
          <li>
            <span class="tit w140px">유지사항</span>
            <span class="txt">{{ contInfoVO.ukepYm }} / {{ contInfoVO.ukepNts }} </span>
          </li>
          <li>
            <span class="tit w140px">유지일자</span>
            <span class="txt">{{ contInfoVO.ukepYmd }}</span>
          </li>
          <li>
            <span class="tit w140px">최종납입일자</span>
            <span class="txt">{{ contInfoVO.lstPadYmd }}</span>
          </li>
          <li>
            <span class="tit w140px">이체계좌정보</span>
            <span class="txt">{{ contInfoVO.bnkNm }}</span>
          </li>
        </ul>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst">
        <p class="fs18rem pb12 fwm">유의정보</p>
        <ul class="lst lst--2">
          <li>
            <span class="tit w140px">조건부계약</span>
            <span class="txt" :class="contInfoVO.cndtlContYn == 'N' ? 'crTy-orange2' : 'crTy-blue3'">{{ contInfoVO.cndtlContYn }} <a class="link fwn ml10" v-show="contInfoVO.cndtlContYn === 'Y'" @click="fn_OpenMSPPM938P">상세내역</a></span>
          </li>
          <li>
            <span class="tit w140px">보장금액증액</span>
            <span class="txt" :class="contInfoVO.icamtYn == 'N' ? 'crTy-orange2' : 'crTy-blue3'">{{ contInfoVO.icamtYn }} <a class="link fwn ml10" v-show="contInfoVO.icamtYn === 'Y'" @click="fn_OpenMSPPM939P">상세내역</a></span>
          </li>
          <li>
            <span class="tit w140px">지급중지계약</span>
            <span class="txt" :class="contInfoVO.pymStopYn == 'N' ? 'crTy-orange2' : 'crTy-blue3'">{{ contInfoVO.pymStopYn }}</span>
          </li>
        </ul>
      </ur-box-container>
      
    </ur-box-container>
    <!-- 컨텐츠영역  //-->
    <!-- 2024-01-09 선심사 및 영업시스템 고도화 PJT 삭제 -->
    <!-- <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column"  class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue">계약상세정보</mo-button>
      </div>
    </ur-box-container> -->
   </div>
 <!-- </ur-page-container> -->
</template>

<script>

import MSPPM909M from '@/ui/pm/MSPPM909M.vue'     // 약대정보
import MSPPM936P from '@/ui/pm/MSPPM936P.vue'     // 자동이체 세부정보
import MSPPM937P from '@/ui/pm/MSPPM937P.vue'     // 부활보험료
import MSPPM938P from '@/ui/pm/MSPPM938P.vue'     // 조건부계약
import MSPPM939P from '@/ui/pm/MSPPM939P.vue'     // 보장금액증액


export default {

  name: 'MSPPM935D', 
  screenId: 'MSPPM935D', 
  components: {},//componets,

  props:{
    pMSPPM932DData:{
      type: Object,
      default: {}
    },
  },//props

  data () {
    return {
      title: '유지/유지정보',
      
      /**
       * pmStore 및 props 변수 
       */
      contBasMatrVO: {},    // 계약메인데이터(MSPPM931M) 
      contNo: '',           // 계약번호 
      contInfoVO: {},       // 계약정보 > 계약정보 데이터(MSPPM932D) 

      /**
       * 팝업연동 
       */
      popup909: {},         // MSPPM909M 약대정보
      popup936: {},         // MSPPM936P 자동이체 세부정보 
      popup937: {},         // MSPPM937P 부활보험료  
      popup938: {},         // MSPPM938P 조건부계약  
      popup939: {},         // MSPPM939P 보장금액증액  

    }
  },//data

  created () {},//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {

    /**
     * MSPPM932D 계약정보 데이터를 저장한다.
     */
    pMSPPM932DData(){
      const lv_Vm = this
      lv_Vm.contInfoVO = lv_Vm.pMSPPM932DData
    }

  },//watch

  computed: {},//computed

  methods: {
       
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
    fn_Submit(){
      // 데이터 세팅
      this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo // MSPPM931M 보험계약기본사항 데이터 
      this.contInfoVO    = this.pMSPPM932DData                                // MSPPM932D 계약정보 데이터 
      this.contNo        = this.contBasMatrVO.contNo                      
    },
        

    /**
     * @description 약대정보 팝업을 제어한다.
     */
    fn_OpenMSPPM909M(){

      this.popup909 = this.$bottomModal.open(MSPPM909M, {

        properties:{
          pPage: this.$options.screenId,
          pContNo: this.contNo,
          pMarkUpContNo: this.contBasMatrVO.markUpContNo,
          pPrdtNm: this.contBasMatrVO.prdtNm,
          pContrNm: this.contBasMatrVO.contrNm,
          pLnPsbAmt: this.contBasMatrVO.lnPsbAmt.amount,
        },

        listeners:{
          closePopup: () => {}
        }
      })

    },
    
    /**
     * @description 자동이체 세부정보 팝업을 제어한다.
     */
    fn_OpenMSPPM936P(){

      this.popup936 = this.$bottomModal.open(MSPPM936P, {

        properties:{
          pPage: this.$options.screenId,
          pContNo: this.contNo,
          pMarkUpContNo: this.contBasMatrVO.markUpContNo,
          pPrdtNm: this.contBasMatrVO.prdtNm,
          pContrNm: this.contBasMatrVO.contrNm,
          pContRrn: this.contInfoVO.mccontrVO.rrn
        },

        listeners:{
          closePopup: () => {}
        }
      })

    },
        
    /**
     * @description 부활보험료 팝업을 제어한다.
     */
    fn_OpenMSPPM937P(){

      this.popup937 = this.$bottomModal.open(MSPPM937P, {

        properties:{
          pPage: this.$options.screenId,
          pContNo: this.contNo,
          pMarkUpContNo: this.contBasMatrVO.markUpContNo,
          pPrdtNm: this.contBasMatrVO.prdtNm,
          pContrNm: this.contBasMatrVO.contrNm,
        },

        listeners:{
          closePopup: () => {
            this.$bottomModal.close(this.popup937)
          }
        }
      })

    },
            
    /**
     * @description 조건부계약 팝업을 제어한다.
     */
    fn_OpenMSPPM938P(){

      this.popup938 = this.$bottomModal.open(MSPPM938P, {

        properties:{
          pPage: this.$options.screenId,
          pContNo: this.contNo,
          pMarkUpContNo: this.contBasMatrVO.markUpContNo,
          pPrdtNm: this.contBasMatrVO.prdtNm,
          pContrNm: this.contBasMatrVO.contrNm,
        },
        listeners:{
          closePopup: () => {}
        }
      })

    },
                
    /**
     * @description 보장금액증액 팝업을 제어한다.
     */
    fn_OpenMSPPM939P(){

      this.popup939 = this.$bottomModal.open(MSPPM939P, {
        
        properties:{
          pPage: this.$options.screenId,
          pContNo: this.contNo,
          pMarkUpContNo: this.contBasMatrVO.markUpContNo,
          pPrdtNm: this.contBasMatrVO.prdtNm,
          pContrNm: this.contBasMatrVO.contrNm,
        },

        listeners:{
          closePopup: () => {}
        }
      })
      
    },


 },// methods

}//export default


</script>