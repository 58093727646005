/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM933P
 * 화면 설명: 보험계약기본사항 > 계약정보 > 계약정보 > 계약상세정보
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.02.13
 * 작 성 자: 서영화
 */
<template>
<ur-page-container class="msp" type="subpage" :show-title="true" title="계약상세정보" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst">
        <p class="fs19rem mt16 pb20 fwb">
          <!-- 상품명(계약번호) -->
         {{ prdtNm }} ({{ markUpContNo }})
        </p>
        <ul class="lst lst--2" v-show="!isNoData">
          <li>
            <span class="tit w140px">가입채널</span>
            <span class="txt">{{ contDtlInfoVO.contChnlScCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">초년도성적</span>
            <span class="txt">{{ contDtlInfoVO.prcTfyeaPrfm }}</span>
          </li>
          <li>
            <span class="tit w140px">공동모집여부</span>
            <span class="txt">{{ contDtlInfoVO.coprClctYn }}</span>
          </li>
          <li>
            <span class="tit w140px">단체할인여부</span>
            <span class="txt">{{ contDtlInfoVO.gHndlCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">가입인원</span>
            <span class="txt">{{ contDtlInfoVO.entObjScCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">특별계약유형</span>
            <span class="txt">{{ contDtlInfoVO.spcContTypCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">계약전환여부</span>
            <span class="txt">{{ contDtlInfoVO.contCnvrScCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">최저사망보증상태</span>
            <span class="txt">{{ contDtlInfoVO.lwstDthGuaStatCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">사망선지급유형</span>
            <span class="txt">{{ contDtlInfoVO.dthPrpymTypCdNm }}</span>
          </li>
          <!-- 23.05.12 잘못된 데이터 리턴으로 일단 주석처리. -->
          <!-- <li>
            <span class="tit w140px">자유납입신청유무</span>
            <span class="txt">{{ contDtlInfoVO.frePadAplYn }}</span>
          </li>
          <li>
            <span class="tit w140px">자유납입보험료</span>
            <span class="txt">{{ contDtlInfoVO.prcFrePadPrm }}</span>
          </li> -->
          <li>
            <span class="tit w140px">자동대출선택</span>
            <span class="txt">{{ contDtlInfoVO.aplnPadScCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">보험계약대출여부</span>
            <span class="txt">{{ contDtlInfoVO.pllnYn }}</span>
          </li>
          <li>
            <span class="tit w140px">분할지급구분</span>
            <span class="txt">{{ contDtlInfoVO.prtnPymScCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">융자대출계약</span>
            <span class="txt">{{ contDtlInfoVO.fnLnRlateContCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">연금신청여부</span>
            <span class="txt">{{ contDtlInfoVO.anutAplCdNm }}</span>
          </li>
          <li>
            <span class="tit w140px">연금보증기간</span>
            <span class="txt">{{ contDtlInfoVO.anutGuaPrd }}년</span>
          </li>
        </ul>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

export default {

  name: 'MSPPM933P', 
  screenId: 'MSPPM933P', 
  components: {    
  },//componets,

  props:{
    pPage: String,          // 부모 페이지명
    pContNo: String,        // 계약번호 
    pMarkUpContNo: String,  // 마스킹된 계약번호
    pPrdtNm: String,        // 상품명
    pContrNm: String        // 계약자명 

  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  
  data () {
    return {
      lv_InputParam: {},       // 서비스호출 파라미터 

      lv_ReturnVal: {},        // 리턴값
      isReturn: false,         // 리턴값 세팅여부 

      /**
       * 화면 변수
       */
      isNoData: false,
      
      /**
       * props 변수
       */ 
      contNo: '',              // 계약번호
      markUpContNo: '',        // 마스킹된 계약번호
      prdtNm: '',              // 상품명
      contrNm: '',             // 계약자명 

      /**
       * 조회 변수
       */  
      contDtlInfoVO: {},       // 계약상세정보 데이터 

    }
  },//data

  created () {
    
    this.contNo       = this.pContNo
    this.markUpContNo = this.pMarkUpContNo
    this.prdtNm       = this.pPrdtNm
    this.contrNm      = this.pContrNm

    this.fn_Submit()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
           
   /**
    * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
    */
   fn_Submit(){

    // 조회조건 세팅 
    this.lv_InputParam.contNo = this.contNo

    // 서비스 호출 
    this.fn_GetService()

   },
      
    /**
     * @description 계약상세정보 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const auth    = 'S'
      const trnstId = this.$pmUtil.getTrnstId()

      let pParams = this.lv_InputParam
      let commHeader = {
        eaiId: this.$pmUtil.getSVCId('F1FPC0085'),
        fahrTrnId: 'S1',
        requestSystemCode: '',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        reqVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0085VO',
        resVONm: 'sli.in.ea2.vo.a0002fp.EAF1FPC0085VO'
      } 

      this.$pmUtil.eaiInvoke(pParams, trnstId, auth, commHeader, this.fn_HandleCallback)
        
    },
        
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      // 인터페이스 오류시 confirm 창 띄우고 return
      if (!response.body) {
        
        let msgcomm = response.msgComm
        let message = '[' + msgcomm.msgCd + '] ' + msgcomm.msgDesc
        this.getStore('confirm').dispatch('ADD', message)

        // 데이터 없음  
        this.isNoData  = true 

        return
      }else{this.isNoData  = false}

      let responseVO = response.body

      // 데이터 가공
      if (responseVO.frePadAplYn === '무') responseVO.frePadPrm.amount = '-' 

      responseVO.prcTfyeaPrfm = this.$bizUtil.numberWithCommasCurr(responseVO.frstYrCnvlPrfmAmt.doubleAmount, responseVO.crncyCd, 'Y', 'Y', 3)                                    // 초년도 성적(외환보험일 경우 $로 표시 )
      responseVO.prcFrePadPrm = (responseVO.frePadPrm.amount === '-') ? responseVO.frePadPrm.amount : this.$commonUtil.numberWithCommas(responseVO.frePadPrm.doubleAmount) + "원" // 자유납입보험료(외환보험일 경우 $로 표시)

      // 데이터 저장 
      this.contDtlInfoVO = responseVO
      this.isNoData      = false 

    }

 },// methods

}//export default


</script>